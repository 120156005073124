import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
const RentalTerms = () => {
  const [data, setData] = useState(
    `${process.env.PUBLIC_URL}/assets/rental-terms.pdf`
  );

  const termsStructure = [
    {
      content: "<strong><u>1. Acceptance of Terms.</u></strong>",
      subItems: [
        {
          content:
            "1.1. Please carefully review these Rental Terms, as they set forth the terms and conditions pursuant to which the rental of Equipment by a Renter from an Equipment Owner through the Platform operated by Dizel. These Rental Terms incorporate by reference Dizel’s <a href='https://godizel.com/terms-and-conditions' target='_blank'><span class='lliinnkk'>Terms and Conditions of Use</span></a> and <a href='https://godizel.com/privacy-policy' target='_blank'><span class='lliinnkk'>Privacy Policy</span></a> (collectively, the “<strong>Agreement<strong>”) and by renting Equipment through the Platform, Renter agrees to be bound by the terms and conditions of the Agreement. Capitalized terms used herein shall have the meaning ascribed to them in Section 2 below or as set forth in the body of these Rental Terms, or if not defined herein, such capitalized term shall have the meaning set forth in Dizel’s Terms and Conditions of Use.",
        },
      ],
    },
    {
      content: "<strong><u>2. Definitions.</u></strong>",
      subItems: [
        {
          content:
            "2.1. “<strong>Dizel</strong>” means Dizel Inc., a Delaware corporation.",
        },
        {
          content:
            "2.2. “<strong>Equipment</strong>” means any machinery, equipment, vehicle, tools, hardware, materials, or other good offered for rental on the Platform.",
        },
        {
          content:
            "2.3. “<strong>Equipment Owner</strong>” means the third-party owner of the Equipment.",
        },
        {
          content:
            "2.4. “<strong>Managed Services</strong>” means those services performed by Dizel, including, without limitation, providing Users access to the Platform, and services related to the Equipment and attachments, cleaning services, fuel provision, and maintenance and transportation services.",
        },
        {
          content:
            "2.5. “<strong>Platform</strong>” means the software platform operated by Dizel that is accessed by Users.",
        },
        {
          content:
            "2.6. “<strong>Privacy Policy</strong>” means the Dizel privacy policy located at <a href='https://godizel.com/privacy-policy' target='_blank'>https://godizel.com/privacy-policy</a>.",
        },
        {
          content:
            "2.7. “<strong>Rental Agreement</strong>” means the terms and conditions set forth herein.",
        },
        {
          content:
            "2.8. “<strong>Rental Period</strong>” means the period of time during which Renter is permitted to rent and utilize the Equipment, as determined by the Equipment Owner and set forth on the Platform.",
        },
        {
          content:
            "2.9. “<strong>Renter</strong>” means any person or entity that utilizes the Platform to rent Equipment from an Equipment Owner.",
        },
        {
          content:
            "2.10. “<strong>Terms and Conditions of Use</strong>” means the Dizel terms and conditions of use located at <a href='https://godizel.com/terms-and-conditions' target='_blank'>https://godizel.com/terms-and-conditions</a>.",
        },
        {
          content:
            "2.11. “<strong>User</strong>” means any person that creates an account on the Platform and is issued a User ID Number by Dizel.",
        },
        {
          content:
            "2.12. “<strong>User ID Number</strong>” means the unique [__] digit number issued by Dizel to each User.",
        },
      ],
    },
    {
      content: "<strong><u>3. Renter's Obligations</u></strong>",
      subItems: [
        {
          content:
            "3.1.	Renter shall (i) provide a qualified and trained operator for each rented unit of Equipment, (ii) upon request from Dizel or an Equipment Owner, furnish evidence of current, valid liability insurance in amounts significant to cover any Equipment rented by the Renter, and (iii) comply with all applicable laws, regulations (including, without limitation, obtaining all necessary permits, licenses, or certifications), and comply with the terms of this Agreement. Renter shall adhere to all safe operation and use policies, including, without limitation, to requirements for suggestions related to daily inspections, set forth in any manual provided by the Equipment Owner or Dizel. Dizel is not responsible or liable in any way for the actions of any person that operates Equipment on Renter’s behalf. Dizel makes no representations or warranties with regard to any person that Renter retains or instructs to operate the Equipment.",
        },
        {
          content:
            "3.2.	Renter agrees that Renter shall not, and shall cause any person or entity under Renter’s control to not:",

          subItems: [
            { content: "3.2.1 Abuse the possession of the Equipment;" },
            {
              content:
                "3.2.2.	Use the Equipment to harm, injury, damage, or destroy any person or the properties of a third-party;",
            },
            {
              content:
                "3.2.3.	Use the Equipment in any other dangerous or irresponsible way;",
            },
            {
              content:
                "3.2.4.	Breach, violate, and/or circumvent any local, state, provincial/territorial, regional, or national law or other law or regulation, or any order of a court, including, without limitation, airport regulations and tax regulations, licensing or registration requirements, or third party rights; or",
            },
            {
              content:
                "3.2.5.	Fail to timely deliver, make available, or return any Equipment based on the Rental Period, unless an extension has been granted in writing by Dizel and the Equipment Owner.",
            },
            {
              content:
                "3.2.6.	In the event that Equipment Owner fails to retrieve the Equipment at the scheduled pick-up date as stipulated in the Rental Period, neither Dizel nor Renter shall bear any liability, responsibility, or obligation for the safekeeping, maintenance, or preservation of said Equipment. Furthermore, any and all costs, expenses, damages, or losses of any nature whatsoever that may arise from or be associated with the Equipment subsequent to the scheduled pick-up date shall not be the responsibility of either Dizel or Renter. This disclaimer of liability shall extend to, but is not limited to, theft, damage, loss, additional usage or any other adverse occurrences affecting the Equipment or People.This provision shall be construed in accordance with the laws of the jurisdiction governing the rental agreement.",
            },
          ],
        },
      ],
    },
    {
      content: "<strong><u>4. Insurance</u></strong>",
      subItems: [
        {
          content:
            "4.1.	Renter expressly acknowledges and agrees to that Renter must maintain insurance as set forth in Section 3.1(ii), above. Notwithstanding the forgoing, Dizel maintains insurance coverages in amounts reasonably commensurate with the value of the Equipment rented on the Platform. Dizel may request verification of Renter’s insurance at any time. The failure of Renter to produce proof of insurance shall contemplate a material breach of this Agreement. Renter agrees to take all such actions as are necessary or desirable for Dizel to communicate with Renter’s insurance representatives, including, without limitation, any communications related to Equipment damage claims. ",
        },
        {
          content:
            "4.2.	Renter acknowledges that the fees charged by Dizel will vary dependent upon the premium amounts charged by Dizel’s insurance carriers to obtain coverage adequate to cover the replacement value of the Equipment. Notwithstanding the foregoing, Dizel makes no representations or warranties that its insurance will be in amounts sufficient to coverage the actual or replacement value of the Equipment.",
        },
      ],
    },
    {
      content: "<strong><u>5. Damage Coverage</u></strong>",
      subItems: [
        {
          content:
            "5.1. To take advantage of Dizel’s insurance coverages, Renters are encouraged to sign up for or select Dizel’s offers a comprehensive Equipment damage waiver (“<strong>Damage Waiver</strong>”) that encompasses a broad spectrum of accidental damage scenarios. The Damage Waiver includes:",
          subItems: [
            {
              content:
                "5.1.1.	No direct expenses shall be incurred by the Renter for damages in excess of $2,500;",
            },
            {
              content:
                "5.1.2.	Furnishes primary damage coverage throughout the duration of the Rental Term.",
            },
          ],
        },
        {
          content:
            "5.2.	In the event that damages surpass the threshold of $2,500, a deductible amounting to $2,500 will be assessed for each affected unit of Equipment. The Renter assumes responsibility for any damages falling below the $2,500 threshold (even if the Renter has obtained a Damage Waiver), and such liabilities shall be paid to Dizel upon request or may be deducted from any User account balance or charged to any User account balance to facilitate the necessary repair coverage.",
          subItems: [
            {
              content:
                "5.2.1.	Submission of evidence for general liability insurance coverage is a mandatory prerequisite;",
            },
            {
              content:
                "5.2.2.	In the event of damage occurs to the Equipment during the Rental Term, Renter must promptly notify Dizel in writing of such damage. The failure of Renter to notify Dizel within one (1) business day of damage to any Equipment may void any Damage Waiver and may result in Renter incurring additional charges and being responsible for the full value of the damage repair. ",
            },
          ],
        },
        {
          content:
            "5.3.	The coverage offered under the Damage Waiver covers most accidental damages, unless explicitly excluded or resulting from misuse. Illustrative instances of covered damages comprise accidental damage, collisions, earthquake, falling objects, fire, flood, hail, ingestions, overturns, theft, tornado, vandalism, and various other perils not expressly excluded. The coverage offered by the Damage Waiver is subject to change without advance notice to Renter. ",
        },
        {
          content:
            "5.4.	The Damage Waiver explicitly excludes the following categories of damage and loss, and the Renter is solely responsible for all associated costs resulting from these exclusions:",
          subItems: [
            {
              content:
                "5.4.1. Deliberate misuse or apparent negligence in adhering to the safe operational protocols of the Equipment;",
            },
            {
              content:
                "5.4.2. Governmental authority actions, encompassing the acts of seizure, confiscation, destruction, or quarantine of property, are expressly stated within the legal framework;",
            },
            {
              content:
                "5.4.3. Contamination or deterioration, inclusive of but not limited to corrosion, decay, fungal growth, mildew, mold, rust, and similar manifestations;",
            },
            {
              content:
                "5.4.4. Criminal, fraudulent, dishonest, or illegal acts: utilization and impairment during the commission of an unlawful act;",
            },
            {
              content:
                "5.4.5. Loss of use, including, without limitation, any interruption or deprivation of market opportunities;",
            },
            {
              content:
                "5.4.6. Utilization of Equipment beyond the parameters stipulated by the manufacturer’s specifications, including but not limited to instances of overloading, inappropriate fluid usage, and similar deviations, is expressly prohibited;",
            },
            {
              content:
                "5.4.7. Mechanical breakdown, malfunction of the mechanical structure, electrical components, or any related elements of the Equipment;",
            },
            {
              content:
                "5.4.8. Missing property, including, without limitation, the unexplained or mysterious disappearance thereof, such as a shortage of property discovered during inventory, shall be construed in accordance with the terms of this Agreement;",
            },
            {
              content:
                "5.4.9. Environmental hazards, including, without limitation, the release of any hazardous materials, nuclear waste, gasoline, or any violation of any applicable environmental laws or regulations;",
            },
            {
              content:
                "5.4.10. Pollutants, such as, the release, discharge, seepage, migration, dispersal, or escape of substances or agents causing contamination or harm to the environment, as governed by applicable laws and regulations;",
            },
            {
              content:
                "5.4.11. Temperature or humidity-related conditions, encompassing dryness, dampness, humidity, or fluctuations in, or extremes of, temperature;",
            },
            {
              content:
                "5.4.12. Damage during transit and delivery or return of the Equipment;",
            },
            { content: "5.4.13. Voluntary parting with title;" },
            {
              content:
                "5.4.14. War and military action refer to hostilities, armed conflict, or warlike engagements conducted by a military force, including insurrection and revolution;",
            },
            { content: "5.4.15. Wear and tear;" },
            { content: "5.4.16. Weight of load." },
          ],
        },
      ],
    },
    {
      content: "<strong><u>6. Prohibition on Transportation</u></strong>",
      subItems: [
        {
          content:
            "6.1.	The Renter is expressly prohibited from transporting any Equipment, either in its entirety or partially, across any international border or departing from the continental United States. The Renter is obligated to immediately (but in no event later than three (3) days prior to relocation of the Equipment) notify Dizel in writing of any contemplated movement across state borders (within the United States). Furthermore, the Renter is prohibited from operating any Equipment in an environment where, due to the condition of the surroundings, the Equipment poses harm to the operator or results in extreme oxidation or degradation of the Equipment. This prohibition includes, without limitation, usage in or around nuclear facilities, superfund sites, or any underground capacity. All Equipment must strictly adhere to the guidelines set forth by the original manufacturer of such Equipment (“<strong>OEM</strong>”) to ensure compliance with an acceptable work environment.",
        },
      ],
    },

    {
      content: "<strong><u>7. RENTER GENERAL INDEMNIFICATION.</u></strong>",
      subItems: [
        {
          content:
            "7.1.	<strong>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE RENTER HEREBY AGREES TO RELEASE, DEFEND, INDEMNIFY, AND HOLD DIZEL, THE EQUIPMENT OWNER, AND THEIR RESPECTIVE SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS, MEMBERS, MANAGERS, AND AGENTS (COLLECTIVELY, THE “INDEMNIFIED PARTIES”) HARMLESS FROM AND AGAINST ANY AND ALL CLAIMS, DEMANDS, SUITS, JUDGMENTS, LIABILITIES, DAMAGES (INCLUDING COMPENSATORY, DIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, PROPERTY, PERSONAL INJURY, BODILY INJURY, THEFT, OR OTHER DAMAGE), LOSSES, AND EXPENSES, INCLUDING, WITHOUT LIMITATION, REASONABLE ACCOUNTING FEES, EXPERT FEES, ATTORNEY’S FEES, COSTS, AND EXPENSES, ARISING OUT OF OR IN ANY WAY RELATED TO THE ACTS OR OMISSIONS OF THE RENTER, ITS EMPLOYEES, AFFILIATES, SUBSIDIARIES, OR AGENTS (“RENTER PARTIES”), SPECIFICALLY RELATED TO (1) ACCESS TO OR USE OF THE EQUIPMENT, THE PLATFORM, OR ANY OTHER SERVICES OFFERED BY THE INDEMNIFIED PARTIES; (2) VIOLATION OR BREACH OF THE TERMS OF THIS AGREEMENT BY RENTER PARTIES; (3) RENTER USER CONTENT OR RENTER PARTIES’ USE OF THE PLATFORM; (4) INTERACTION WITH ANY USER; (5) BOOKING AND RENTING OF A PIECE OF EQUIPMENT; (6) THE USE OR CONDITION OF THE EQUIPMENT; (7) VIOLATION OF APPLICABLE LAWS; (8) EQUIPMENT DAMAGE, BODILY INJURY, OR DEATH OF ANY PERSON, OR PROPERTY DAMAGE, INCLUDING LOSS OF USE OF PROPERTY; (9) ANY WILLFUL MISCONDUCT OF RENTER PARTIES OR ANYONE ELSE FOR WHOM RENTER MAY BE LEGALLY RESPONSIBLE; OR (10) ACTS OR OMISSIONS WHICH CAUSE THE INDEMNIFIED PARTIES DAMAGES OF ANY KIND ARISING IN CONNECTION WITH OR AS A RESULT OF BOOKING, RENTING, OR USE OF THE EQUIPMENT.</strong>",
        },
        {
          content:
            "7.2.	To the extent that the Renter is under an obligation to indemnify, defend, and hold the Indemnified Parties harmless, the Renter shall promptly, upon receipt of notice from the Indemnified Party, engage legal counsel acceptable to the Indemnified Party. The indemnity obligations of the Renter, as set forth herein, are autonomous and shall not be constrained by any insurance obligations stipulated in this Agreement. These obligations shall persist beyond the termination of this Agreement until all claims against the indemnified parties are conclusively barred by applicable law. All provisions herein shall be subject to the prevailing laws that influence their validity and enforceability. Such provisions shall be construed to the minimum extent necessary for conformity with applicable law, and any modifications to achieve such conformity shall not diminish the force and effect of the provision. In claims against any person or entity indemnified under this Section by an employee of the Renter Parties, any individual directly or indirectly employed by them, or any party for whose actions they may be accountable, the indemnification obligation under this Section shall not be circumscribed by limitations on the amount or type of damages, compensation, or benefits payable by or for the Renter under workers' compensation acts, disability benefit acts, or other employee benefit acts.",
        },
        {
          content:
            "7.3.	DIZEL SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY THE EQUIPMENT, OR AS RELATED TO THE PLATFORM, A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT RENTER’S COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO RENTER’S USE OF THE PLATFORM OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM.",
        },
        {
          content:
            "7.4.	RENTER’S USE OF THE PLATFORM, ITS CONTENT, THE EQUIPMENT, AND ANY SERVICES OBTAINED THROUGH THE PLATFORM IS AT RENTER’S SOLE RISK. THE PLATFORM, ITS CONTENT, THE EQUIPMENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER DIZEL NOR ANY PERSON ASSOCIATED WITH DIZEL MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE PLATFORM OR ANY EQUIPMENT OFFERED THROUGH THE PLATFORM. WITHOUT LIMITING THE FOREGOING, NEITHER DIZEL NOR ANYONE ASSOCIATED WITH DIZEL REPRESENTS OR WARRANTS THAT THE PLATFORM, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, FREE FROM DEFECTS, THAT DEFECTS WILL BE CORRECTED, THAT THE PLATFORM OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT PLATFORM OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET RENTER’S NEEDS OR EXPECTATIONS.",
        },
        {
          content:
            "7.5.	DIZEL HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.",
        },
        {
          content:
            "7.6.	In no event shall Dizel be liable or responsible to Renter or any other party for: (i) any loss, damage or injury caused by, resulting from or in any way connected with the Equipment, its operation or its use, or the Managed Services; (ii) the Equipment Owner’s or Dizel’s failure to deliver the Equipment as contemplated hereunder or Dizel’s failure to repair or replace non-working Equipment; (iii) any incidental, consequential, punitive or special damages, including damages resulting from the delay to any ongoing projects, in connection with this Agreement or its subject matter under any legal or equitable theory, including breach of contract, tort (including negligence), strict liability, or product liability, even if so advised of the possibility of such damages, if such damages were otherwise foreseeable, and notwithstanding the failure of any agreed or other remedy of its essential purpose; or (iv) any liquidated damages. Renter acknowledges and assumes all risks inherent in the operation, use and possession of the Equipment and will take all necessary precautions to protect all persons and property from injury or damage from the Equipment.",
        },
      ],
    },
    {
      content: "<strong><u>8. Rental Rates.</u></strong>",
      subItems: [
        {
          content:
            "8.1.	The determination and adjustment of rental rates is at the sole discretion of the Equipment Owner, who possesses the authority to establish or modify such rates at their discretion, subject to any fees charged by Dizel, including, without limitation, the fees charged in connection with Dizel’s provision of the Managed Services. The Equipment Owner shall be furnished with recommended market rental rates corresponding to their geographical region and specific equipment categories.",
        },
        {
          content:
            "8.2.	For each Equipment rental, Dizel shall charge a fee equal to 6.5% of the aggregate monthly Equipment rental charge, as consideration for Dizel’s performance of the Managed Services. ",
        },
      ],
    },
    {
      content: "<strong><u>9. Taxes</u></strong>",
      subItems: [
        {
          content:
            "9.1.	The Platform is configured to diligently adhere to statutory requirements governing sales and use tax computations across various states, regions, and municipalities. The Platform incorporates direct API integration with the third-party cloud-based sales and use tax calculation system. Such API and third-party services are chosen at Dizel’s sole discretion. ",
        },
        {
          content:
            "9.2.	Sales tax and use tax are automatically assessed against the Renter and subsequently remitted by Dizel to the appropriate tax authority. The computation of sales tax considers both the origin and destination of the Equipment to provide precise calculations across various tax jurisdictions, including county or state locales. Notwithstanding the foregoing, Renter shall not rely on Dizel as it relates to any taxes charged or calculated in connection with the Rental of the Equipment. No information provided in this Agreement, on the Platform, or by any Dizel representative shall be construed or considered tax advice. Renter shall retain its own accountants to provide any and all tax advice related to Renter’s transactions through the Platform. ",
        },
        {
          content:
            "9.3.	Equipment property taxes shall be the exclusive obligation of the Equipment Owner. In certain jurisdictions, unique ownership levies, such as the Colorado Special Mobile Machinery (SMM) tax, may be applicable. Such taxes may be incorporated within the calculation of rental rates charged by the Equipment Owner. Notwithstanding the foregoing, Renter shall be responsible for its compliance with all property tax laws, and for prompt remittance of any property taxes later charged by an Equipment Owner or Dizel on behalf of such Equipment Owner, if not otherwise charged through the Platform.",
        },
      ],
    },
    {
      content: "<strong><u>10. Post Rental Expenses. </u></strong>",
      subItems: [
        {
          content:
            "10.1.	Any subsequent activities that occur outside of the Rental Term (“<strong>Post-Rental Activities</strong>”) shall be invoiced upon the conclusion of the Rental Term or upon completion of the Post-Rental Activities and shall be delineated in one or more invoices. For purposes of clarity, Post-Rental Activities encompass, but are not limited to:",
          subItems: [
            { content: "10.1.1.	Hourly usage overages; and" },
            { content: "10.1.2.	Any other service costs" },
          ],
        },
      ],
    },

    {
      content: "<strong><u>11.	Usage Limit.</u></strong>",
      subItems: [
        {
          content:
            "11.1.	The permissible utilization of the Equipment shall be restricted to 224 hours per four week period for the entire duration of the Rental Term. The aggregate usage shall be computed cumulatively over the entirety of the Rental Term, without individual monthly computations for the Equipment. Illustratively, in a three-month rental scenario, the cumulative usage for all three months may amount to 672 hours yet remain consistent with the stipulated 224-hour monthly limit.",
        },
      ],
    },
    {
      content: "<strong><u>12.	Overages</u></strong>",
      subItems: [
        {
          content:
            "12.1.	In the event that the cumulative usage of any unit of Equipment exceeds the specified limit of 224 hours throughout the Rental Term, additional charges shall be incurred at the conclusion of the Rental Term, delineated in one or more invoices. The overage rate per hour shall be determined based on the equivalent daily rental rate applicable to the utilized unit of Equipment. The computation of overage charges shall be executed through the formula: [hourly rental rate] x [cumulative overage hours across the rental period] = [overage charges]. For illustrative purposes, if a monthly rental fee of $9,000 was assessed for a usage period of 300 hours within a month, resulting in an excess of 76 hours beyond the stipulated 224-hour monthly limit, the overage charge would be calculated accordingly: ",
          subItems: [
            { content: "12.1.1.	$9000/224=$40.17  $40.17 X 76 = $3,053.57." },
          ],
        },
      ],
    },
    {
      content: "<strong><u>13.	Payment</u></strong>",
      subItems: [
        {
          content:
            "13.1.	The Renter’s obligation to remit the rental fees, along with all associated freight, taxes, and any other amounts stipulated in this Agreement when due, is unequivocal and unconditional, without allowance for deductions, set-off rights, abatements, withholdings, or claims for compensation of any nature. The Renter hereby grants explicit authorization to Dizel for the imposition and collection of all fees due hereunder, inclusive of taxes and any additional amounts owed to Dizel pursuant to the terms of this Agreement.",
        },
      ],
    },
    {
      content:
        "<strong><u>14.	Early Returns, Extensions, Cancellations </u></strong>",
      subItems: [
        {
          content:
            "14.1.	The return of Equipment prior to the anticipated conclusion of the Rental Term is permissible. However, the minimum duration for which the Equipment may be rented is stipulated at 1 day.",
        },
        {
          content:
            "14.2.	A Renter may request an extension to the Rental Term by submitting such request to Dizel and the Equipment Owner at least seventy-two (72) hours in advance of the expiration of the Rental Term; failure to adhere to this timeframe may result in the reclamation of the Equipment.",
        },
        {
          content:
            "14.3.	In order to be eligible for a complete reimbursement, cancellations must be effectuated no less than twenty-four (24) hours prior to the scheduled pick-up of the Equipment. Incurred charges may be applicable for cancellations made subsequent to the aforementioned cancellation period.",
        },
      ],
    },
    {
      content: "<strong><u>15.	Transportation </u></strong>",
      subItems: [
        {
          content:
            "15.1.	The Renter shall be furnished with a quotation for the transportation of each machine promptly upon placement of such order through the Platform. The ultimate transportation expenses shall be itemized and reflected on one or more invoices submitted to Renter.",
        },
        {
          content:
            "15.2.	Renter understands that initial calculation of transportation expenses are only estimates and that unforeseen circumstances may result in the final costs deviating from the initially quoted transportation fees. Dizel and the Equipment Owner shall use best efforts to notify Renter in advance of any deviations.",
        },
        {
          content:
            "15.3.	Renter shall be subject to electronic notifications, disseminated via email and text messages, at various intervals during the transportation process of each unit of Equipment.",
        },
        {
          content:
            "15.4.	In the event Equipment has not been delivered within a reasonable period of time after the anticipated delivery time, Dizel shall take such efforts as it deems necessary to resolve any issues with delivery or transportation of the Equipment. Dizel reserves the right to charge for any costs associated with such actions.",
        },
      ],
    },
    {
      content: "<strong><u>16.	Machine Downtime Swaps.</u></strong>",
      subItems: [
        {
          content:
            "16.1.	For machines Equipment that is incapacitated in the field and deemed irreparable despite diligent attempts to remedy or repair, Dizel will use best efforts to replace or substitute such Equipment within four (4) days from the occurrence of the breakdown.",
        },
        {
          content:
            "16.2.	If the breakdown is the Equipment Owner’s responsibility or fault:",
          subItems: [
            {
              content:
                "16.2.1.	The responsibility for the replacement of similar equipment shall be the responsibility of the Equipment Owner;",
            },
            {
              content:
                "16.2.2.	If the Equipment Owner is unable to procure a similar unit of equipment, a designated Dizel account manager shall diligently endeavor to locate a commensurate replacement unit of Equipment within the territory of the Renter;",
            },
            {
              content:
                "16.2.3.	The Equipment Owner shall bear the responsibility for the transportation of the inoperable Equipment back to the Equipment Owner’s premises;",
            },
            {
              content:
                "16.2.4.	The Renter shall receive due credit for the duration necessitated to replace the inoperable Equipment;",
            },
          ],
        },
        {
          content:
            "16.3.	In the event that the breakdown is the Renter’s responsibility or fault:",
          subItems: [
            {
              content:
                "16.3.1.	The rental obligation for the Equipment shall persist until such time as a substitute unit of Equipment has been delivered to the Renter’s designated site and is deemed operational for productive use;",
            },
            {
              content:
                "16.3.2.	The Equipment Owner shall possess the preferential right to refuse the provision of a similar Equipment, with any associated costs being the responsibility of the Renter;",
            },
            {
              content:
                "16.3.3.	Renter will be responsible for the cost of transporting the inoperable Equipment to the Owner’s requested service provider or back to the Owner’s premises, at Owner’s discretion.",
            },
          ],
        },
      ],
    },
    {
      content: "<strong><u>17.	Preventative Maintenance. </u></strong>",
      subItems: [
        {
          content:
            "17.1.	Dizel ensures the provision of adequately maintained machinery through obligatory and continuous preventative maintenance during the entirety of each Rental Term. The intervals for preventative maintenance are determined based on Equipment usage tracking and are proactively scheduled during each Rental Term at every 250-hour increment, specifically at the 250-hour and 500-hour periods. Maintenance services at the 1,000-hour and 2,000-hour intervals are the obligation of the Equipment Owner, though Dizel retains the option to perform such services for a remuneration, which shall be debited from the Equipment Owner’s rental proceeds. The execution of maintenance procedures is undertaken by Equipment Owner, ensuring the correct and proficient performance of maintenance activities.",
        },
        {
          content: "17.2.	Preventative maintenance service includes:",
          subItems: [
            {
              content:
                "17.2.1. 50-point inspection and services record review;",
            },
            { content: "17.2.2. Fuel, oil, and coolant checks;" },
            {
              content:
                "17.2.3. Hydraulic filter replacement (500 and 1,500-hour intervals only);",
            },
            { content: "17.2.4. Oil and filter replacement using OEM parts;" },
            { content: "17.2.5. Diagnostic and condition monitoring;" },
            {
              content:
                "17.2.6. Free non-critical and/or suggested future repairs report.",
            },
          ],
        },
        {
          content:
            "17.3. Renters are obligated to ensure that all operators perform a daily machine walk-around inspection and greasing. Additionally, Renters must ensure that radiators and air filters are meticulously cleaned (blown out) in accordance with the guidelines specified in the OEM’s operation manual, which is typically located in the glove box or behind the set of each machine.",
        },
        {
          content:
            "17.4. The Renter shall be solely responsible for the diligent execution of daily walkarounds and greasing of all Equipment. The Renter shall be solely liable and responsible for any damage incurred to the Equipment attributable to the non-performance of these daily actions. Such damages shall be automatically billed to the Renter.",
        },
        {
          content:
            "17.5. Renters shall be solely responsible for cleaning and preparing all Equipment before the scheduled transportation pick-up at the conclusion of the Rental Term. The preparation must be conducted to restore the Equipment to its original condition, commensurate with the level at which it was initially received, encompassing, but not limited to, the following areas:",
          subItems: [
            {
              content: "17.5.1. Cab must be cleaned.",
            },
            {
              content: "17.5.2. Undercarriage areas must be cleaned.",
            },
            {
              content: "17.5.3. Tracks and/or tires must be cleaned.",
            },
            {
              content: "17.5.4. Machine must be returned with DEF fluid full.",
            },
            {
              content: "17.5.5. Air filters must be cleaned/blown out.",
            },
            {
              content: "17.5.6. Radiators must be cleaned/blown out.",
            },
            {
              content: "17.5.7. For attachments:",
              subItems: [
                {
                  content: "17.5.7.1. Hammers must be properly greased.",
                },
                {
                  content: "17.5.7.2. Hydraulics must be bled.",
                },
                {
                  content:
                    "17.5.7.3. Wearables (e.g., cutting edges, bucket teeth, hammer bits) must be replaced if showing greater than 75% wear.",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      content: "<strong><u>18.	Jobsite Service Access.</u></strong>",
      subItems: [
        {
          content:
            "18.1.	The Renter hereby undertakes to furnish, or procure, formal written consent from jobsite management, granting authorized Dizel service providers unrestricted access to perform maintenance and servicing activities on any Equipment rented from Dizel at the designated jobsite as may be deemed necessary in Dizel’s sole discretion. Additionally, the Renter expressly commits to supplying Dizel with any requisite site-specific safety training and personal protective equipment (PPE) mandated for the operation of the machinery.",
        },
        {
          content:
            "18.2.	In the event of a requisite job site maintenance or repair visit, Dizel shall undertake to afford the Renter as much notice as feasibly possible to facilitate the making of requisite arrangements.",
        },
        {
          content:
            "18.3.	Dizel additionally requires that jobsite management ensure the availability of the Equipment beyond regular business hours, encompassing mornings, nights, weekends, or as circumstances permit.",
        },
        {
          content:
            "18.4.	Dizel shall undertake to ensure that the designated jobsite contact receives continuous communication pertaining to all maintenance and service activities, including, but not limited to:",
          subItems: [
            {
              content:
                "18.4.1. The name of the individual performing services.",
            },
            {
              content:
                "18.4.2. The entity’s qualifications and certifications.",
            },
            {
              content: "18.4.3. What services are being performed.",
            },
            {
              content: "18.4.4. Arrival/activity/departure estimates.",
            },
          ],
        },
      ],
    },
    {
      content: "<strong><u>19.	Credit, Billing, & Payments. </u></strong>",
      subItems: [
        {
          content:
            "19.1. Credit approvals are automatically conducted in the course of each checkout procedure to ascertain a secure and reliable rental experience as per Dizel’s discretion. This credit approval process can occur on an annual basis, and it is obligatory for annual credit checks to be executed for the purpose of renting.",
        },
        {
          content:
            "19.2. An invoice or purchase order (PO) number may be entered for each rental to facilitate the capacity of an accounts payable department in associating Equipment rental expenditures with a particular job or project. Such invoice or purchase order shall at all times be associated with a Renter’s User ID Number.",
        },
        {
          content:
            "19.3. Payment instructions shall be transmitted via email to the designated payment contact provided by Renter during the checkout process. The establishment of payment, involving the setup of an Automated Clearing House (ACH) transfer from a bank account and/or credit card, is deemed a mandatory, one-time prerequisite and will, upon completion continue to be associated with a Renter’s User ID Number, until such time as the Renter updates such payment information, which may only be completed upon written request to Dizel.",
        },
        {
          content:
            "19.4. It is imperative that an Automated Clearing House (ACH) account or credit card be established within a period of 72 hours subsequent to the confirmation of the rental order.",
        },
        {
          content:
            "19.5. Renter shall be subject to automatic charges at 28-day intervals following the initial delivery of the initial machine to the designated job site.",
        },
        {
          content:
            "19.6. Renter will be charged on the following industry schedule:",
          subItems: [
            {
              content: "19.6.1. 3 days rolls up to one week.",
            },
            {
              content: "19.6.2. 2.5 weeks rolls up to one month.",
              subItems: [
                {
                  content:
                    "19.6.2.1. Weekly Example: A 10 day rental equates to one week and 3 days. The additional 3 days rolls up to 1 week. The total charge for this rental will be 2 weeks.",
                },
                {
                  content:
                    "19.6.2.2. Monthly Example: A 17 day rental equates to 2 weeks and 3 days. The additional 3 days rolls up to 1 week. This brings the total weeks to 3. The total charge for this rental will be 1 month.",
                },
              ],
            },
          ],
        },
        {
          content:
            "19.7. For rental durations exceeding a billing cycle of one month, charges shall be assessed on a pro-rata basis, utilizing a 28-day month as the basis for calculation. The daily pro-rata rate shall be determined by the division of the Monthly Rental Rate by 28 days, as per the following formula: Monthly Rental Rate / 28 days = Daily Pro-Rata Rate.",
        },
        {
          content:
            "19.8. Renter may contemporaneously monitor rental activity by accessing the billing statements electronically through the user account associated with Dizel.",
        },
        {
          content:
            "19.9. The failure to uphold current and accurate Automated Clearing House (ACH) bank account or credit card information shall constitute a default under this Agreement. Dizel reserves the right to exercise any and all remedies delineated in Section 22, in addition to those remedies available to Dizel under pertinent law and/or equity.",
        },
      ],
    },
    {
      content:
        "<strong><u>20.	Default: </u></strong> A default, as defined within the terms of this Agreement (“Default”), shall occur in the event of any of the following circumstances:",
      subItems: [
        {
          content:
            "20.1. In the event that the Renter fails to remit any rental payment or any other amount stipulated under the terms of this Agreement, inclusive of expenses associated with the servicing and maintenance of the Equipment by the Renter or the Renter’s designated representative, upon the specified due date.",
        },
        {
          content:
            "20.2. In the event of Renter’s non-performance of any obligations stipulated within this Agreement or any other rental, instrument, or agreement existing between Renter and Dizel, and such default persists without remedy for a period of ten (10) days.",
        },
        {
          content:
            "20.3. Should the Renter or any guarantor of the Renter become a party to a bankruptcy, insolvency, receivership, or akin proceeding under the Bankruptcy and Insolvency Act, Companies’ Creditors Arrangement Act, Winding Up and Restructuring Act, or analogous legislation, whether by voluntary or involuntary means, or in the event of an encumbrance taking possession of any of the Equipment or a significant portion of the Renter's property.",
        },
        {
          content:
            "20.4. In the event that the Renter suspends business operations, engages in abandonment, attempts to effectuate a transfer, or relinquishes possession of any Equipment, or all or a significant portion of the Renter’s property, such actions shall constitute a default under the terms of this Agreement.",
        },
        {
          content:
            "20.5. Except for damages arising from ordinary wear and tear attributable to appropriate utilization within the regular course of the Renter’s business operations (conducted on the date of the Renter’s agreement to this Agreement), strictly following the operational guidelines provided by the Equipment Owner, any occurrence or imminent event that the Equipment Owner or Dizel reasonably deems likely to substantially diminish the Equipment’s value or the Equipment Owner’s stake in it, or escalate the risk of such devaluation, shall be considered.",
        },
        {
          content:
            "20.6. Any representation or warranty made by the Renter in connection with the execution of this Agreement is found to be false, inaccurate, or deceptive.",
        },
        {
          content:
            "20.7. In the event that the Renter suspends its business operations, engages in abandonment, or endeavors to effectuate the transfer of a substantial portion of its assets, such actions shall constitute a material breach of this agreement.",
        },
      ],
    },
    {
      content:
        "<strong><u>21. Remedies</u></strong> In the event of a Default or continued and uncured Default, Dizel reserves the right, without affording prior notice to the Renter, to unilaterally terminate this Agreement and/or, in conjunction with any other legal rights available, may undertake any or all of the ensuing actions, either individually or collectively, and in any sequence or amalgamation thereof:",
      subItems: [
        {
          content:
            "21.1. Dizel reserves the right to enter any location where the Equipment is situated (or reasonably believed to be situated) and undertake repossession and removal, including the disconnection from any other property if deemed necessary. In the event of such entry and/or repossession, the Renter expressly waives any claims for damages to property or other liabilities arising therefrom. The Renter shall indemnify and release Dizel and the Equipment Owner from any and all claims, causes of action, or losses arising as a consequence of Dizel’s repossession of any Equipment in accordance with the provisions of this Section.",
        },
        {
          content:
            "21.2. In the event of arrears of payment of the rental fee or any other amounts due by the Renter under this Agreement, Dizel reserves the right to initiate legal proceedings to seek recovery and other remedy, including, without limitation, injunctive relief.",
        },
        {
          content:
            "21.3. In the event of termination of this Agreement and/or default by the Renter under this Agreement, Dizel reserves the right to demand, and the Renter shall promptly pay to Dizel and/or the Equipment Owner, damages arising from such termination or default. These damages shall encompass, but not be confined to, reasonable legal fees and expenses reasonably incurred by Dizel, its assignees, and/or agents in the enforcement of the terms and conditions set forth in this Agreement.",
        },
        {
          content:
            "21.4. Renter shall have the right to seek reimbursement from Renter for the reasonable legal fees and expenses expended by Dizel in enforcing the provisions of this Agreement. In any legal proceedings wherein emerges as the prevailing party, it shall be entitled to the recovery of reasonable legal fees and costs from Renter.",
        },
        {
          content:
            "21.5. All matters relating to this Agreement, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to any choice or conflict of law provision or rule (whether of the State of Delaware or any other jurisdiction).",
        },
      ],
    },
  ];

  const NestedOrderedList = ({ items }) => {
    return (
      <ul style={{ listStyleType: "none", paddingLeft: "1.5rem" }}>
        {items.map((item, index) => (
          <li key={index}>
            {/* Render the content, including the explicit numbering */}
            <div
              style={{ marginBottom: ".75rem" }}
              dangerouslySetInnerHTML={{ __html: item.content }}
            ></div>
            {item.subItems && item.subItems.length > 0 && (
              <NestedOrderedList items={item.subItems} />
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Container
      maxWidth="md"
      sx={{ textAlign: "justify", marginBottom: "5rem" }}
    >
      <Box data-aos="fade-in" data-aos-duration={500}>
        <Typography variant="h3" textAlign={"center"} my={5} fontWeight={700}>
          DIZEL INC. RENTAL TERMS
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          margin={"1rem 0"}
          gutterBottom
          paddingLeft={"1.5rem"}
        >
          Last modified: Jan 01, 2025
        </Typography>
        <NestedOrderedList items={termsStructure} />
      </Box>
    </Container>
  );
};

export default RentalTerms;
