import { useState, useEffect } from "react";
import { Container, CssBaseline, Grid } from "@mui/material";
import "./App.css";
import Header from "./components/Header/Header";
import HeroSection from "./components/HeroSection/HeroSection";
import { ThemeProvider } from "@mui/material/styles";
import lightTheme from "./components/Theme/LightTheme";
import darkTheme from "./components/Theme/DarkTheme";
import {
  Routes,
  Route,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import Shop from "./pages/Shop/Shop";
import Footer from "./components/Footer/Footer";
import LogoutHome from "./pages/LogoutHome/LogoutHome";
import Product from "./components/Product/Product";
import Checkout from "./components/Checkout/Checkout";
import LoginHome from "./pages/LoginHome/LoginHome";
import Login from "./pages/Login";
import { useDispatch, useSelector } from "react-redux";
import {
  getuserInfo,
  reset,
  resetStatus,
} from "./components/StateSlices/getuserSlice";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "./components/Spinner/Spinner";
import Signup from "./pages/Signup/Signup";
import MyProducts from "./pages/My Products/MyProducts";
import SetupProfile from "./components/SetupProfile/SetupProfile";
import Confirmation from "./pages/Confirmation/Confirmation";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import Verify from "./pages/Verify";
import DynamicTitleUpdater from "./DynamicTitleUpdater";
import Header2 from "./components/Header2/Header2";
import FAQ from "./pages/FAQ/FAQ";
import ScrollToTop from "./ScrollToTop";
import CheckoutStepper from "./components/Checkout/CheckoutStepper";
import DocuSignSucces from "./components/DocusignSuccess/DocuSignSucces";
import SearchResults from "./components/SearchResults/SearchResults";
import Transactions from "./components/Transactions/Transactions";
import Notifications from "./components/Notifications/Notifications";
import Favorites from "./components/Favorites/Favorites";
import Error404 from "./pages/404/Error404";
import Aos from "aos";
import About from "./pages/About/About";
import { addtoCart } from "./components/StateSlices/addtocartSlice";
import TabsLayout from "./pages/TabsLayout/TabsLayout";
import OrderConfirmation from "./pages/OrderConfirmation/OrderConfirmation";
import Categories from "./components/Categories/Categories";
import IndividualCategory from "./components/Categories/IndividualCategory";
import OrderDetails from "./pages/OrderDetails/OrderDetails";
import AddNewItemStepper from "./pages/AddNewItem/AddNewItemStepper";
import EditItemStepper from "./pages/EditItem/EditItemStepper";
import axios from "axios";
import AdminPanel from "./pages/Admin/AdminPanel";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RentalTerms from "./pages/RentalTerms";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Article1 from "./pages/Article1";
import Article2 from "./pages/Article2";
import Article3 from "./pages/Article3";
import Article4 from "./pages/Article4";
import Article5 from "./pages/Article5";
import Article6 from "./pages/Article6";
import Article7 from "./pages/Article7";
import Article8 from "./pages/Article8";
import Talkjs from "./components/TalkJs/Talkjs";
import TermsPopup from "./components/TermsPopup/TermsPopup";
import ChatEngineCustom from "./components/ChatSupport/ChatEngineCustom";

function PrivateRoute({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo, status } = useSelector((state) => state.user);

  // Check if the user is logged in
  useEffect(() => {
    // Check if the user is logged in
    if (!userInfo) {
      let token = localStorage.getItem("inktoken");
      if (!token) {
        navigate("/signup", { replace: true });
      } else {
        dispatch(getuserInfo({ token: token }));
      }
      // If not logged in, redirect to the login page
    }

    if (
      userInfo &&
      userInfo.userDetailsId &&
      userInfo.userDetailsId.verified === false
    ) {
      navigate("/verify-email?error=AccountNotVerified", {
        replace: true,
        state: userInfo.userDetailsId,
      });
      return;
    }

    if (
      userInfo &&
      userInfo.userDetailsId &&
      userInfo.userDetailsId.profileStatus === "Incomplete"
    ) {
      navigate("/setup-profile", { replace: true });
    }
  }, [navigate, userInfo]);

  // If logged in, render the protected route
  return status == "loading" ? <Spinner /> : userInfo && children;
}

function App() {
  const location = useLocation();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const pathSegments = location.pathname.split("/");
  const currentPath = pathSegments[pathSegments.length - 2];
  const currentPath2 = pathSegments[pathSegments.length - 3];
  const [cart, setCart] = useState(
    JSON.parse(sessionStorage.getItem("closetcart"))
      ? JSON.parse(sessionStorage.getItem("closetcart"))
      : []
  );
  const [isPopupVisible, setIsPopupVisible] = useState(() => {
    return !localStorage.getItem("DizelTermsAccepted");
  });

  useEffect(() => {
    axios.get("/api/users/invoke");
  }, []);

  const [addedtoCart, setAddedtoCart] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    Aos.init({
      duration: 1000, // Adjust the duration as needed
      once: true, // Whether animation should happen only once
    });
    dispatch(resetStatus());
    let token = localStorage.getItem("inktoken");
    if (token) {
      dispatch(getuserInfo({ token: token }));
    }
  }, []);

  useEffect(() => {
    // debugger;
    // Recheck localStorage on route change
    if(window.location.pathname !== "/terms-and-conditions"){
      const hasAccepted = localStorage.getItem("DizelTermsAccepted");
      setIsPopupVisible(!hasAccepted);
    }else {
      setIsPopupVisible(false);
    }

  }, [location]);

  const handleAccept = () => {
    localStorage.setItem("DizelTermsAccepted", "true");
    setIsPopupVisible(false);
  };

  const handleClose = () => {
    setIsPopupVisible(false);
  };

  function RequireUserAuth({ children }) {
    const { status, userInfo, error, profile } = useSelector(
      (state) => state.user
    );

    return status === "loading" ? (
      <Spinner />
    ) : userInfo ? (
      children
    ) : (
      <Signup />
    );
  }

  function RequireAdminAuth({ children }) {
    const { status, userInfo, error, profile } = useSelector(
      (state) => state.user
    );

    return status === "loading" ? (
      <Spinner />
    ) : userInfo && userInfo.role === "admin" ? (
      children
    ) : (
      <Error404 />
    );
  }
  

  // useEffect(() => {

  // },[userInfo])

  return (
    <>
      <DynamicTitleUpdater />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        <main>
          <ScrollToTop />
          {/* <div
              style={{ position: "sticky", top: 0, zIndex: "999" }}
              data-aos="fade-down"
              data-aos-duration="1200"
            > */}
          {(currentPath && currentPath === "admin") ||
          (currentPath2 && currentPath2 === "admin") ? (
            ""
          ) : (
            <Header
              cartVal={cart.length}
              toggleTheme={toggleTheme}
              isDarkMode={isDarkMode}
              addedtoCart={addedtoCart}
              setAddedtoCart={setAddedtoCart}
            />
          )}
          {/* <Header2 /> */}
          {/* </div> */}
          {/* <Container maxWidth="xl" disableGutters>
            <Grid container minHeight={"100vh"} spacing={1}> */}
            {isPopupVisible && (
            <TermsPopup
              handleAccept={handleAccept}
              handleClose={handleClose}
              isPopupVisible={isPopupVisible}
              setIsPopupVisible={setIsPopupVisible}
            />
          )}
          <Routes>
            <Route
              path="/"
              exact
              element={
                status !== "succeeded" &&
                status !== "idle" &&
                status !== "failed" ? (
                  <Spinner />
                ) : userInfo ? (
                  <PrivateRoute>
                    <LoginHome
                      cartVal={cart}
                      setCartVal={setCart}
                      setAddedtoCart={setAddedtoCart}
                    />
                  </PrivateRoute>
                ) : (
                  <LogoutHome />
                )
              }
            ></Route>
            <Route
              path="/shop"
              exact
              element={
                <Shop
                  cartVal={cart}
                  setCartVal={setCart}
                  setAddedtoCart={setAddedtoCart}
                />
              }
            ></Route>
            <Route path="/newsletter" exact element={<Shop />}></Route>
            <Route
              path="/product/:id"
              exact
              element={
                <Product
                  cartVal={cart}
                  setCartVal={setCart}
                  setAddedtoCart={setAddedtoCart}
                />
              }
            ></Route>
            <Route
              path="/editproduct/:id"
              exact
              element={<EditItemStepper />}
            ></Route>
            <Route
              path="/checkout"
              exact
              element={<CheckoutStepper setCart={setCart} />}
            ></Route>
            <Route path="/login" exact element={<Login />}></Route>
            <Route path="/signup" exact element={<Signup />}></Route>
            <Route
              path="/confirmation"
              exact
              element={<Confirmation />}
            ></Route>

            <Route
              path="/add-new-item"
              exact
              element={
                <PrivateRoute>
                  <AddNewItemStepper />
                </PrivateRoute>
              }
            ></Route>
            {/* <Route
                  path="/profile"
                  exact
                  element={
                    <PrivateRoute>
                      <MyProducts />
                    </PrivateRoute>
                  }
                ></Route> */}
            <Route
              path="/setup-profile"
              exact
              element={<SetupProfile />}
            ></Route>
            <Route path="/verify-email" exact element={<VerifyEmail />}></Route>
            <Route path="/verify/:id" exact element={<Verify />}></Route>
            <Route path="/coming-soon" element={<ComingSoon />} exact></Route>
            <Route path="/faqs" element={<FAQ />} exact></Route>
            <Route path="/successDS" element={<DocuSignSucces />}></Route>
            <Route path="/search/:query" element={<SearchResults />}></Route>
            <Route path="/transactions" element={<Transactions />}></Route>
            <Route path="/notifications" element={<Notifications />}></Route>
            <Route path="/favorites" element={<Favorites />}></Route>
            <Route path="/404" element={<Error404 />}></Route>
            <Route path="/about-dizel" element={<About />}></Route>
            <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
            <Route path="/resetpassword/:id" element={<ResetPassword />}></Route>
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            ></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route path="/rental-terms" element={<RentalTerms />}></Route>
            <Route path="/article/Ever-Thought-About-the-Cost-of-Unused-Construction-Equipment" element={<Article1 />}></Route>
            <Route path="/article/The-Importance-of-Efficiency-in-Construction" element={<Article2 />}></Route>
            <Route path="/article/Top-5-ways-to-put-idle-construction-machinery-back-to-work-effectively" element={<Article3 />}></Route>
            <Route path="/article/How-to-Calculate-the-True-Cost-of-Your-Machinery-Not-Being-Rented" element={<Article4 />}></Route>
            <Route path="/article/Dont-Let-Your-Equipment-Gather-Dust-Learn-How-to-Optimize-and-Redistribute-Machinery" element={<Article5 />}></Route>
            <Route path="/article/Idle-Machines-Draining-Your-Bottom-Line" element={<Article6 />}></Route>
            <Route path="/article/Here-how-Dizel-is-transforming-how-we-use-construction-equipment" element={<Article7 />}></Route>
            <Route path="/article/Is-Your-Fleet-Sitting-Idle-During-Off-Seasons-These-Strategies-Could-Turn-Downtime-Into-Profit" element={<Article8 />}></Route>
            <Route
              path="/order-confirmation"
              element={<OrderConfirmation />}
            ></Route>
            <Route path="/categories" element={<Categories />}></Route>
            <Route
              path="/category/:categoryId"
              element={<IndividualCategory />}
            ></Route>
             <Route
              path="chat-support"
              exact={true}
              element={<ChatEngineCustom />}
            />

            <Route
              path="/user/*"
              element={
                // <RequireUserAuth>
                <TabsLayout />
                // </RequireUserAuth>
              }
            ></Route>
            <Route
              path="/order-details/:orderId/:productId"
              element={<OrderDetails />}
            ></Route>
            <Route
              path="/admin/*"
              element={
                <RequireAdminAuth>
                  <AdminPanel />
                </RequireAdminAuth>
              }
            ></Route>

            <Route path="*" element={<Error404 />} />
          </Routes>
          {/* </Grid>
          </Container> */}
          {(currentPath && currentPath === "admin") ||
          (currentPath2 && currentPath2 === "admin") ? (
            ""
          ) : (
            <Footer />
          )}

          {/* <div data-aos-duration="1200" data-aos="fade-up"> */}
          {/* </div> */}
        </main>
      </ThemeProvider>
      {/* Comment */}
    </>
  );
}

export default App;
