import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import Modal from "react-modal";
import swal from "sweetalert";
import * as Yup from "yup";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import AnalyticsHeader from "../AnalyticsHeader/AnalyticsHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPen, FaRedo, FaTrash } from "react-icons/fa";
import { Form, Formik } from "formik";
import Spinner from "../../Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  addPromoCode,
  deletePromoCode,
  getPromoCodes,
  resetPromos,
  updatePromoCode,
} from "../../StateSlices/adminSlice";

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

const TableContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "400px", // Set a fixed height for the table
  [theme.breakpoints.down("sm")]: {
    height: "300px",
  },
}));

const PromoCode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allPromoStatus, allPromoInfo, allPromoError } = useSelector(
    (state) => state.admin
  );

  const location = useLocation();
  const [rowData, setRowData] = useState(null);

  const getDate = (params) => {
    const date = new Date(params.value);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "-" +
      date.getFullYear()
    );
  };

  const [columnDefs] = useState([
    { field: "promo", headerName: "Promo Code", flex: 1 },
    {
      field: "userIds",
      headerName: "Users Count",
      flex: 1,
      cellRenderer: (params) => params.value.length,
    },
    { field: "percentageOff", headerName: "Percentage Off", flex: 1 },
    {
      field: "expiration",
      headerName: "Expiration Date",
      flex: 1,
      valueFormatter: (params) => getDate(params),
    },
    {
      field: "isActive",
      headerName: "Status",
      flex: 1,
      cellRenderer: (params) => (params.value ? "Active" : "Inactive"),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      cellRenderer: (params) => (
        <>
          <FaPen
            style={{ color: "var(--secondary-color)", cursor: "pointer" }}
            onClick={() => handleEdit(params.data)}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          {params.data.isActive ? (
            <FaTrash
              style={{ color: "var(--secondary-color)", cursor: "pointer" }}
              onClick={() => handleDelete(params.data)}
            />
          ) : (
            <FaRedo
              style={{ color: "var(--secondary-color)", cursor: "pointer" }}
              onClick={() => handleRestore(params.data)}
            />
          )}
        </>
      ),
    },
  ]);

  const [selectedPromo, setSelectedPromo] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [createPromoPopup, setCreatePromoPopup] = useState(false);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("inktoken");
    if (token) {
      setSpinner(true);
      dispatch(getPromoCodes({ token }));
    }
  }, []);

  useEffect(() => {
    if (allPromoStatus == "succeeded") {
      setSpinner(false);
      setRowData(allPromoInfo);
      dispatch(resetPromos());
    }
  }, [allPromoInfo]);

  const handleEdit = (promo) => {
    setSelectedPromo(promo);
    setDialogOpen(true);
  };

  const handleSave = () => {
    // setRowData((prevData) =>
    //   prevData.map((promo) =>
    //     promo.id === selectedPromo.id ? selectedPromo : promo
    //   )
    // );
    setSpinner(true);
    const token = localStorage.getItem("inktoken");
    if (token) {
      dispatch(
        updatePromoCode({
          token,
          payload: selectedPromo,
          id: selectedPromo._id,
        })
      );
      dispatch(resetPromos());
    }

    setDialogOpen(false);
  };

  const handleDelete = (promo) => {
    swal({
      title: "Delete Promocode",
      text: "Are you sure you want to make this promocode inactive?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive !== null) {
        setSpinner(true);
        let token = localStorage.getItem("inktoken");
        if (token) {
          dispatch(deletePromoCode({ token, id: promo._id }));
          dispatch(resetPromos());
        }
      }
    });
  };

  const handleRestore = (promo) => {
    swal({
      title: "Restore Promocode",
      text: "Are you sure you want to make this promocode active again?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive !== null) {
        setSpinner(true);
        let token = localStorage.getItem("inktoken");
        if (token) {
          dispatch(deletePromoCode({ token, id: promo._id, restore: true }));
          dispatch(resetPromos());
        }
      }
    });
  };

  const initialValues = {
    promocode: "",
    percentageOff: "",
    expirationDate: "",
  };

  const validationSchema = Yup.object().shape({
    promocode: Yup.string().required("Promo code is required"),
    percentageOff: Yup.string().required("Percentage off is required"),
    expirationDate: Yup.date()
      .required("Expiration date is required")
      .min(new Date(), "Expiration date must be in the future"),
  });

  return (
    <Container>
      <>
        <AnalyticsHeader
          placeholder="Promo Code"
          label={"promo codes"}
          searchQuery={location?.state?.searchQuery || ""}
        />
        {spinner ? (
          <Spinner />
        ) : (
          <>
            <Stack direction={"row"} justifyContent={"flex-start"}>
              <Button
                color="secondary"
                size="small"
                sx={{ marginBottom: "1rem" }}
                variant="contained"
                onClick={() => setCreatePromoPopup(true)}
              >
                Create New
              </Button>
            </Stack>
            {rowData && (
              <TableContainer className="ag-theme-material">
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={{
                    sortable: true,
                    filter: true,
                    resizable: true,
                  }}
                  animateRows={true}
                  domLayout="autoHeight"
                />
              </TableContainer>
            )}
          </>
        )}
        <Modal
          ariaHideApp={false}
          isOpen={createPromoPopup}
          onRequestClose={() => setCreatePromoPopup(false)}
          style={{
            overlay: {
              background: "rgba(0,0,0,0.5)",
            },
            content: {
              maxWidth: "600px",
              maxHeight: "380px",
              margin: "auto",
              padding: "20px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
            },
          }}
          contentLabel="Create Promo Code Modal"
        >
          <Typography variant="h3" fontWeight={700}>
            Create New Promo Code
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              const payload = {
                userIds: [],
                promo: values.promocode,
                percentageOff: values.percentageOff,
                expiration: values.expirationDate,
              };
              setSpinner(true);
              const token = localStorage.getItem("inktoken");
              if (token) {
                dispatch(addPromoCode({ token: token, payload: payload }));
              } else {
                navigate("/login");
              }
              //   onSubmit(values);
              //   resetForm();
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  margin={"2rem 0"}
                  marginTop={"1rem"}
                >
                  {/* Promo Code Field */}
                  <TextField
                    name="promocode"
                    label="Promo Code"
                    value={values.promocode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.promocode && Boolean(errors.promocode)}
                    helperText={touched.promocode && errors.promocode}
                    fullWidth
                  />

                  {/* Percentage Off Field */}
                  <TextField
                    name="percentageOff"
                    label="Percentage Off"
                    value={values.percentageOff}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.percentageOff && Boolean(errors.percentageOff)
                    }
                    helperText={touched.percentageOff && errors.percentageOff}
                    fullWidth
                  />

                  {/* Expiration Date Field */}
                  <TextField
                    name="expirationDate"
                    label="Expiration Date"
                    type="date"
                    value={values.expirationDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.expirationDate && Boolean(errors.expirationDate)
                    }
                    helperText={touched.expirationDate && errors.expirationDate}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />

                  {/* Submit Button */}
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                  >
                    Create Promo Code
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Modal>
        <Modal
          ariaHideApp={false}
          isOpen={isDialogOpen}
          onRequestClose={() => setDialogOpen(false)}
          style={{
            overlay: {
              background: "rgba(0,0,0,0.5)",
            },
            content: {
              maxWidth: "600px",
              maxHeight: "380px",
              margin: "auto",
              padding: "20px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
            },
          }}
          contentLabel="Edit Promo Code Modal"
        >
          <Typography variant="h4" marginBottom={"1rem"} fontWeight={700}>
            Promo Code Edit
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Promo Code"
              value={selectedPromo?.promo || ""}
              onChange={(e) =>
                setSelectedPromo({
                  ...selectedPromo,
                  promo: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              label="Percentage Off"
              value={selectedPromo?.percentageOff || ""}
              onChange={(e) =>
                setSelectedPromo({
                  ...selectedPromo,
                  percentageOff: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              label="Expiration Date"
              type="date"
              value={
                selectedPromo?.expiration
                  ? new Date(selectedPromo.expiration)
                      .toISOString()
                      .split("T")[0]
                  : ""
              }
              onChange={(e) =>
                setSelectedPromo({
                  ...selectedPromo,
                  expiration: e.target.value,
                })
              }
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={() => setDialogOpen(false)}
              color="secondary"
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          </div>
        </Modal>
      </>
    </Container>
  );
};

export default PromoCode;
