import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./ChatEngineCustom.css";
import {
  useMultiChatLogic,
  MultiChatSocket,
  MultiChatWindow,
  ChatCard,
  ChatHeader,
  ChatForm,
  MessageList,
  MessageForm,
} from "react-chat-engine-advanced";
import { getuserInfo } from "../StateSlices/getuserSlice";
import Spinner from "../Spinner/Spinner";

const ChatEngineCustom = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { userInfo } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [activeeChat, setActiveeChat] = useState(null);

  const [sellerEmailFromUrl, setSellerEmailFromUrl] = useState(null);
  const [isTyping, setIsTyping] = useState(false); // Track typing status
  const typingTimeoutRef = React.useRef(null); // Reference for typing timeout

  useEffect(() => {
    const token = localStorage.getItem("inktoken");
    if (token) {
      dispatch(getuserInfo({ token }));
      window.scrollTo(0, 0);
    } else {
      navigate("/login", { replace: true });
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    let emailParamVal = queryParams.get("email");
    if (emailParamVal) {
      // const emailPattern = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
      // const match = emailParamVal.match(emailPattern);
      // if (match && match[0]) {
      //   setSellerEmailFromUrl(match[0]);
      // }
      setSellerEmailFromUrl(decodeURIComponent(emailParamVal));
    }
  }, []);

  useEffect(() => {
    if (userInfo && (location.state?.sellerEmail || sellerEmailFromUrl)) {
      setLoading(true);
      getOrCreateChat(setActiveChat);
    }
  }, [userInfo, location.state?.sellerEmail, sellerEmailFromUrl]);

  useEffect(() => {
    if (userInfo?.userChatHistoryId?.chatUnreadCount > 0) {
      const findChatId = userInfo.userChatHistoryId.unseenChat.some(
        (chat) => chat.chatId === activeeChat
      );
    }
  }, [activeeChat, dispatch, userInfo]);

  const setActiveChat = (response) => {
    chatProps.setMessages(response);
  };

  const getOrCreateChat = async (callback) => {
    try {
      const response = await axios.put(
        "https://chatengine.eastus.cloudapp.azure.com/chats/",
        {
          usernames: [
            location?.state?.sellerEmail || sellerEmailFromUrl,
            userInfo?.userDetailsId?.userEmail,
          ],
          is_direct_chat: true,
        },
        {
          headers: {
            "Project-ID": process.env.REACT_APP_CE_PROJECT_ID,
            "User-Name": userInfo?.userDetailsId?.userEmail,
            "User-Secret": userInfo?.userDetailsId?.userEmail,
          },
        }
      );
      if (response) {
        setActiveeChat(response.data.id);
        getMessages(response.data, callback);
      }
    } catch (error) {
      console.error("Error creating or getting chat:", error);
    }
  };

  const getMessages = async (data, callback) => {
    try {
      const response = await axios.get(
        `https://chatengine.eastus.cloudapp.azure.com/chats/${data.id}/messages/`,
        {
          headers: {
            "Project-ID": process.env.REACT_APP_CE_PROJECT_ID,
            "User-Name": userInfo?.userDetailsId?.userEmail,
            "User-Secret": userInfo?.userDetailsId?.userEmail,
          },
        }
      );
      callback(response.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const chatProps = useMultiChatLogic(
    process.env.REACT_APP_CE_PROJECT_ID,
    userInfo?.userDetailsId?.userEmail,
    userInfo?.userDetailsId?.userEmail,
    "https://chatengine.eastus.cloudapp.azure.com"
  );

  useEffect(() => {
    if (chatProps) {
      const sellerEmail = location?.state?.sellerEmail || sellerEmailFromUrl;

      if (chatProps.activeChatId === activeeChat) {
        setLoading(false);
      } else if (activeeChat === -1 && !sellerEmail) {
        setActiveeChat(chatProps.activeChatId);
      } else if (chatProps.activeChatId !== undefined && activeeChat !== -1) {
        if (chatProps.activeChatId !== activeeChat) {
          chatProps.setActiveChatId(activeeChat);
        }
      }
    }
  }, [
    chatProps,
    activeeChat,
    location?.state?.sellerEmail,
    sellerEmailFromUrl,
  ]);

  const handleIsTyping = (data) => {
    console.log("Typing Event:", data);

    if (data?.is_typing) {
      setIsTyping(true); // Show the typing indicator

      // Clear any existing timeout and set a new one
      clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = setTimeout(() => {
        setIsTyping(false); // Hide the typing indicator after inactivity
      }, 3000); // 3 seconds of inactivity
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(typingTimeoutRef.current); // Cleanup on unmount
    };
  }, []);

  return (
    <>
      <div className="chat-engine-wrap" style={{ position: "relative" }}>
        {!userInfo ? (
          <Spinner />
        ) : (
          userInfo && (
            <>
              <MultiChatWindow
                {...chatProps}
                isChatFeedLoading={chatProps.isChatFeedLoading}
                isChatListLoading={chatProps.isChatListLoading}
                // onChatLoaderShow={(data) => /*console.log(data)*/}
                style={{
                  height: "90vh",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
                  borderRadius: ".5px solid #ddd",
                }}
                activeChatId={
                  activeeChat ? activeeChat : chatProps.activeChatId
                }
                renderChatList={(chats) => {
                  // Handle setting the active chat ID initially
                  if (chats.activeChatId !== activeeChat) {
                    setActiveeChat(chats.activeChatId);
                  }

                  // Count the number of otherPerson entries
                  const otherPersonCount =
                    chats?.chats?.filter((chat) =>
                      chat.people.some(
                        (person) =>
                          person.person.username !==
                          userInfo.userDetailsId.userEmail
                      )
                    ).length || 0;

                  // Loading state for the chat list
                  if (chatProps.isChatListLoading) {
                    return (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          overflowY: "scroll",
                        }}
                      >
                        <ChatForm
                          title="My Chats"
                          titleStyle={{ fontFamily: "Helvetica" }}
                          style={{
                            background: "var(--primary-color)",
                            color: "var(--white-color)",
                          }}
                          buttonStyle={{
                            display: "none",
                          }}
                        />
                        {Array.from({ length: otherPersonCount || 11 }).map(
                          (_, index) => (
                            <ChatCard
                              key={index}
                              isLoading={true}
                              style={{
                                fontFamily: "inherit",
                                borderRadius: 0,
                                height: "80px",
                                background: "#f0f0f0",
                              }}
                            />
                          )
                        )}
                      </div>
                    );
                  }

                  // Empty state for the chat list
                  if (!chats || !chats.chats || chats.chats.length === 0) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "80%",
                        }}
                      >
                        <div className="h3 bold-text text-center dark-grey-text">
                          No Chats to Display
                        </div>
                      </div>
                    );
                  }

                  // Render the chat list
                  return (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        overflowY: "scroll",
                      }}
                    >
                      <ChatForm
                        title="My Chats"
                        titleStyle={{ fontFamily: "Helvetica" }}
                        style={{
                          background: "var(--primary-color)",
                          color: "var(--white-color)",
                        }}
                        buttonStyle={{
                          display: "none",
                        }}
                      />

                      {chats.chats.map((chat) => {
                        const otherPerson = chat.people.find(
                          (person) =>
                            person.person.username !==
                            userInfo.userDetailsId.userEmail
                        );

                        // Render ChatCard for each chat
                        return (
                          otherPerson && (
                            <ChatCard
                              key={chat.id}
                              avatarUsername={
                                otherPerson.person.first_name[0] +
                                otherPerson.person.last_name[0]
                              }
                              onClick={() => {
                                if (activeeChat !== chat.id) {
                                  chats.onChatCardClick(chat.id);
                                  setActiveeChat(chat.id); // Set new active chat ID
                                }
                              }}
                              isActive={chats.activeChatId === chat.id}
                              description={
                                chat.last_message.text.length > 0
                                  ? chat.last_message.text
                                  : "Say Hello!"
                              }
                              hasNotification={chat.hasNotification}
                              style={{
                                fontFamily: "inherit",
                                borderRadius: 0,
                              }}
                              timeStamp={chat.timeStamp}
                              title={
                                otherPerson.person.first_name +
                                " " +
                                otherPerson.person.last_name
                              }
                              activeStyle={{
                                background: "#fff0de",
                                border: "1px solid var(--primary-color)",
                                borderRadius: 0,
                              }}
                              avatarStyle={{
                                border: "2px solid white",
                                fontFamily: "Helvetica",
                              }}
                              hoveredStyle={{
                                background: "#f0f0f0",
                                borderRadius: 0,
                              }}
                              notificationStyle={{
                                backgroundColor: "#f26654",
                              }}
                              titleStyle={{
                                fontFamily: "Helvetica",
                              }}
                              subtitleStyle={{
                                fontFamily: "Helvetica",
                                textAlign: "left",
                              }}
                              timeStampStyle={{
                                fontFamily: "Helvetica",
                              }}
                            />
                          )
                        );
                      })}
                    </div>
                  );
                }}
                renderChatFeed={(feeds) => {
                  if (!feeds || !feeds.chat) return null; // Early return for invalid feeds

                  const isActiveChat = feeds.chat.id === activeeChat;
                  const hasMessages = feeds.messages?.length > 0;
                  const lastMessageMatches =
                    hasMessages &&
                    feeds.chat.last_message.text ===
                      feeds.messages[feeds.messages.length - 1]?.text;
                  const noMessagesYet =
                    !feeds.chat.last_message.created && !hasMessages;

                  // Handle distinct loading state for UI while fetching chat data
                  if (chatProps.isChatFeedLoading) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <div className="large-text grey-text text-center">
                          <Spinner />
                        </div>
                      </div>
                    );
                  }

                  // Trigger getOrCreateChat if necessary
                  if (
                    isActiveChat &&
                    !(lastMessageMatches || noMessagesYet) &&
                    location?.state?.sellerEmail &&
                    !loading
                  ) {
                    setLoading(true);
                    getOrCreateChat(setActiveChat);
                    return null; // Exit rendering while fetching chat
                  }

                  // Handle reset of the loading state and render active chat feed
                  if (isActiveChat && (lastMessageMatches || noMessagesYet)) {
                    setLoading(false);

                    // Find the other person's details
                    const otherPerson = feeds.chat.people.find(
                      (person) =>
                        person.person.username !==
                        userInfo.userDetailsId.userEmail
                    );

                    // Render chat feed UI
                    return (
                      <>
                        <ChatHeader
                          description={otherPerson?.person.username || ""}
                          id={feeds.chat.id}
                          title={`${otherPerson?.person.first_name || ""} ${
                            otherPerson?.person.last_name || ""
                          }`}
                          titleStyle={{
                            fontFamily: "Helvetica",
                            margin: ".5rem auto",
                            marginBottom: 0,
                            color: "var(--primary-color)",
                            paddingBottom: ".5rem",
                          }}
                          subtitleStyle={{
                            fontFamily: "Helvetica",
                            color: "var(--grey-color)",
                            fontSize: "16px",
                          }}
                          style={{
                            borderBottom: "2px solid var(--primary-color)",
                          }}
                          mobileOptionStyle={{
                            top: "28px",
                            color: "var(--primary-color)",
                            border: "none",
                            fontSize: "20px",
                          }}
                        />
                        <MessageList
                          {...feeds}
                          onBottomMessageHide={() => {}}
                          onBottomMessageShow={() => {}}
                          onMessageLoaderHide={() => {}}
                          onMessageLoaderShow={() => {}}
                          style={{
                            fontFamily: "Helvetica",
                            minHeight: "73vh",
                            height: "73vh",
                            overflowX: "hidden",
                            overflowY: "auto",
                          }}
                          messageStyle={{
                            fontFamily: "Helvetica",
                          }}
                          dateTimeStyle={{
                            fontFamily: "Helvetica",
                          }}
                        />
                        <MessageForm
                          {...feeds}
                          onChange={function noRefCheck() {}}
                          onSubmit={(message) => {
                            // setLoading(true);
                            feeds.onMessageFormSubmit(message);
                            // addToUnseenChat(
                            //   chatProps.activeChatId,
                            //   otherPerson.person.username
                            // );
                          }}
                          inputStyle={{
                            border: "1px solid var(--grey-color)",
                            borderRadius: "5px",
                            marginLeft: "6px",
                            padding: ".7rem .4rem",
                            fontFamily: "Helvetica",
                          }}
                          sendButtonStyle={{
                            fontFamily: "Helvetica",
                            marginLeft: "-.5rem",
                            padding: ".5rem .8rem",
                            top: "2px",
                            width: "75px",
                            textAlign: "center",
                            marginTop: "-.75rem",
                            background: "var(--primary-color)",
                          }}
                          attachmentInputIconStyle={{ display: "none" }}
                        />
                      </>
                    );
                  }

                  // Render fallback UI for when no chats are available
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <div className="large-text grey-text text-center">
                        <Spinner />
                      </div>
                    </div>
                  );
                }}
              />
              <MultiChatSocket
                {...chatProps}
                wsUrl="wss://chatengine.eastus.cloudapp.azure.com:9001"
                onNewMessage={(data) => {
                  debugger
                  if (
                    chatProps.activeChatId == data &&
                    chatProps.chat.last_message.sender_username !==
                      userInfo.userDetailsId.userEmail
                  ) {
                    let token = localStorage.getItem("inktoken");
                    // dispatch(
                    //   deleteChatHistory({
                    //     token,
                    //     values: { chatId: activeeChat },
                    //   })
                    // );
                    if (
                      chatProps.messages[chatProps.messages.length - 1]
                        .created != chatProps.chat.last_message.created
                    ) {
                      let messages = [
                        ...chatProps.messages,
                        chatProps.chat.last_message,
                      ];
                      chatProps.setMessages(messages);
                      chatProps.setIsChatFeedAtBottom(true);
                    }
                  } else if (
                    chatProps.chat.last_message.sender_username !==
                    userInfo.userDetailsId.userEmail
                  ) {
                    let token = localStorage.getItem("inktoken");
                    dispatch(getuserInfo({ token: token }));
                  }
                }}
                onNewChat={(data) => {
                  window.location.reload()
                  chatProps.setActiveChatId(data.id);
                }}
              />
            </>
          )
        )}
      </div>
    </>
  );
};

export default ChatEngineCustom;
