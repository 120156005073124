import {
  Autocomplete as Mautocomplete,
  Box,
  Divider,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Container,
  Stack,
  StepConnector,
  StepLabel,
  stepConnectorClasses,
  styled,
  FormLabel,
  RadioGroup,
  Radio,
  Stepper,
  Step,
  MenuItem,
  CircularProgress,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Paper,
  createFilterOptions,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import "../AddNewItem/AddNewItem.css";
import {
  AddAPhoto,
  Camera,
  CameraFrontTwoTone,
  Clear,
  Close,
  InfoOutlined,
  Save,
} from "@mui/icons-material";
import {
  Formik,
  useFormik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
  useFormikContext,
  useField,
} from "formik";
import { DatePicker, DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import {
  categoies,
  subCategories,
  brands,
  conditions,
  years,
  itemGoalsNested,
  goalOptions,
  thirdPartyAccountEntity,
  earthManipulationAttachments,
  liftingAttachments,
  environmentalFeeOptions,
} from "../AddNewItem/data";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import axios from "axios";
import { getuserInfo } from "../../components/StateSlices/getuserSlice";
import {
  getProduct,
  reset,
} from "../../components/StateSlices/getproductSlice";
import {
  updateItem,
  resetItem,
  delistItem,
} from "../../components/StateSlices/updateitemSlice";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import moment from "moment/moment";
import {
  FaCalendarCheck,
  FaCircleDollarToSlot,
  FaCircleInfo,
  FaStopwatch,
} from "react-icons/fa6";
import swal from "sweetalert";
import { saveItem } from "../../components/StateSlices/additemSlice";
import { components } from "react-select";
import { isBefore } from "rsuite/esm/utils/dateUtils";
import {
  getProductFields,
  resetFields,
} from "../../components/StateSlices/getProductFields";
import { getMakeFieldOptions } from "../../components/StateSlices/getMakeFieldOptions";
import { DarkToolTip } from "../../utils/utils";
import { LoadingButton } from "@mui/lab";

const FormikTextField = ({ label, vallue, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <TextField
      // style={{ marginBottom: "1rem" }}
      // defaultValue={vallue}
      {...field}
      required
      {...props}
      label={label}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      fullWidth
    />
  );
};

const FormikSelect = ({ label, options, vallue, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <TextField
      // style={{ marginBottom: "1rem" }}
      // defaultValue={{ label: vallue, value: vallue }}
      select
      required
      {...field}
      {...props}
      label={label}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      fullWidth
    >
      {/* <MenuItem value="">
        <em>None</em>
      </MenuItem> */}
      {options.map((option, index) => (
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

const filterForCreateOptions = createFilterOptions();
const FormikAutoCompleteWithCreateOption = ({
  label,
  name,
  setFieldValue,
  formRef,
  ...props
}) => {
  const [value, setValue] = React.useState(formRef.current.values[name] || "");
  const [optionss, setOptionss] = useState([]);
  const [field, meta] = useField(props);
  const dispatchh = useDispatch();
  const { makeoptions } = useSelector((state) => state.getmakefieldoptions);
  useEffect(() => {
    const token = localStorage.getItem("inktoken");
    if (token) dispatchh(getMakeFieldOptions({ token }));
  }, [dispatchh]);

  useEffect(() => {
    if (makeoptions && makeoptions.length > 0) {
      // console.log("Options set:", makeoptions);
      setOptionss(makeoptions);
    } else {
      console.log("No Options Available");
    }
  }, [makeoptions]);

  return (
    optionss && (
      <Mautocomplete
        fullWidth
        // loading={optionss.length === 0}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setFieldValue(name, newValue);
            setValue({
              title: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              title: newValue.inputValue,
            });
            setFieldValue(name, newValue.inputValue);
          } else {
            setValue(newValue);
            setFieldValue(name, newValue?.title);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filterForCreateOptions(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.title
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={optionss}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              {option.title}
            </li>
          );
        }}
        freeSolo
        renderInput={(params) => (
          <TextField
            name={name}
            label={label}
            {...params}
            required
            // error={meta.touched && Boolean(meta.error)}
            // helperText={meta.touched && meta.error}
          />
        )}
      />
    )
  );
};

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const SingleValue = ({ children, ...props }) => {
  console.log("YYYY", props.data);
  return (
    <components.SingleValue {...props}>
      {props.data.value.structured_formatting.main_text}
    </components.SingleValue>
  );
};
const Input = (props) => <components.Input {...props} isHidden={false} />;

const EditItemStepper = () => {
  // STEPPER CORE
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 27,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,var(--black-color) 0%,var(--black-color),var(--black-color) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,var(--black-color) 0%,var(--black-color),var(--black-color) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[500]
          : "var(--black-color)",
      borderRadius: 1,
    },
  }));
  const ColorlibStepIconRoot = React.memo(
    styled("div")(({ theme, ownerState }) => ({
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[700]
          : "var(--secondary-color)",
      zIndex: 1,
      color: "var(--white-color)",
      padding: "1rem",
      display: "flex",
      borderRadius: !ownerState.active ? "50%" : "30px",
      justifyContent: "center",
      alignItems: "center",
      ...(ownerState.active && {
        background: "var(--secondary-color)",
        // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      }),
      ...(ownerState.completed && {
        backgroundImage:
          "linear-gradient( 95deg,var(--secondary-color) 0%,var(--secondary-color),var(--secondary-color) 100%)",
      }),
      cursor: "pointer",
      // animation: ownerState.active ? `${fadeIn} 0.3s ease-in-out` : "none",
      height: "60px",
      minWidth: "60px",
    }))
  );

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    let icons = null;

    icons = {
      1: <FaCircleInfo />,
      2: <FaCircleDollarToSlot />,
      3: <FaCalendarCheck />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
        {active && (
          <Typography marginLeft={".5rem"}>{steps[props.icon - 1]}</Typography>
        )}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
    index: PropTypes.any,
  };
  // END STEPPER CORE

  const isThirdPartyEntity = () => {
    if (
      userInfo &&
      userInfo.userDetailsId &&
      userInfo.userDetailsId.userEmail
    ) {
      return thirdPartyAccountEntity.includes(userInfo.userDetailsId.userEmail);
    }
  };

  const [steps, setSteps] = useState([
    "Information",
    "Pricing",
    "Availability",
  ]);
  const [activeStep, setActiveStep] = React.useState(2);
  const [completed, setCompleted] = React.useState({});

  const location = useLocation();
  const [eimages, setEImages] = useState([]);
  const [images, setImages] = useState([]);
  const [feature, setFeature] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [value, setValue] = useState();
  const [country, setCountry] = useState("us");
  const [productDetail, setProductDetail] = useState(null);
  const formRef = useRef();
  const [dateError, setDateError] = useState("");
  const [itemLeaseFields, setItemLeaseFields] = useState([
    {
      initVal: { from: "", to: "" },
    },
  ]);
  const [fullLocation, setFullLocation] = useState(null);
  const [isDraft, setIsDraft] = useState(false);

  const [leaseCounts, setLeaseCounts] = useState(1);
  const [langLong, setLangLong] = useState({});

  const [inputValue, setInputValue] = useState("");

  const [dynamicCategoryOptions, setDynamicCategoryOptions] = useState([]);
  const [dynamicSubCategoryOptions, setDynamicSubCategoryOptions] = useState(
    []
  );
  const [selectedBrand, setSelectedBrand] = useState([]);

  const [platformFees, setPlatformFees] = useState(0);
  const [selectedEnvironmentalFee, setSelectedEnvironmentalFee] = useState(null);

  const selectRef = useRef();

  const onFocus = () =>
    formRef.current.values && selectRef.current.inputRef.select();

  const onInputChange = (inputValue, { action }) => {
    // onBlur => setInputValue to last selected value
    // if (action === "input-blur") {
    //   setInputValue(value ? value.label : "");
    // }
    console.log(action);
    // onInputChange => update inputValue
    if (action === "input-change") {
      setInputValue(inputValue);
    }
  };

  const handleAddField = () => {
    setLeaseCounts(leaseCounts + 1);
    // Add a new empty field
    setItemLeaseFields([...itemLeaseFields, { initVal: { from: "", to: "" } }]);
    // console.log("Updated Fields after add ", itemLeaseFields);
  };

  const handleDateRangeSelectt = (index, newValue) => {
    const updatedFields = [...itemLeaseFields];
    updatedFields[index].initVal = newValue;
    setItemLeaseFields(updatedFields);
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...itemLeaseFields];
    updatedFields.splice(index, 1);
    setItemLeaseFields(updatedFields);
    // console.log("Updated Fields after remove ", updatedFields);
  };

  const today = dayjs();
  const { beforeToday } = DateRangePicker;

  const { itemStatus, itemInfo, itemError, itemDelisted } = useSelector(
    (state) => state.update
  );
  const { status, userInfo } = useSelector((state) => state.user);
  const { pstatus, productInfo, closetInfo, error } = useSelector(
    (state) => state.getproduct
  );

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const removeImg = (id) => {
    if (feature === id) setFeature(0);
    let updatedImg = eimages.filter((img, index) => index !== id);
    setEImages([...updatedImg]);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setEImages([...e.dataTransfer.files]);
    }
  };

  if (itemInfo) {
    toast.success("Equipment Updated Successfully", toastOption);
    // let hang = itemInfo.itemInfo.productHanger;
    // let productStock = itemInfo.itemInfo.productStock
    // toast.success(`+${productStock * hang} Hanger Added Successfully`, toastOption);
    dispatch(resetItem());
    navigate(`/product/${itemInfo._id}`, {
      state: { id: location.state.id },
    });
    // setTimeout(() => {
    //   navigate(0);
    // }, 5000);
  }

  const extractLabelAndValues = (type, selectedLabel) => {
    if (type === "category") {
      const filteredGoal = itemGoalsNested[selectedLabel];
      if (filteredGoal) {
        setDynamicCategoryOptions(
          Object.keys(filteredGoal).map((key) => ({
            label: key,
            value: key.toLowerCase().replace(/ /g, "_"),
          }))
        );
        if (selectedCategory) {
          setSelectedCategory([]);
        }
        if (selectedSubCategory) {
          setSelectedSubCategory([]);
        }
      }
    } else if (type === "subcategory") {
      const filteredCategory =
        itemGoalsNested[selectedGoal?.label]?.[selectedLabel];
      console.log("here it came", filteredCategory);
      if (filteredCategory) {
        setDynamicSubCategoryOptions(
          filteredCategory.map((item) => ({
            label: item,
            value: item.toLowerCase().replace(/ /g, "_"),
          }))
        );
      }
      if (selectedSubCategory) {
        setSelectedSubCategory([]);
      }
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("inktoken");
    if (token && !userInfo) {
      dispatch(getuserInfo({ token }));
    } else if (!token) {
      navigate("/signup", { replace: true });
    } else {
      if (
        userInfo.userProducts.findIndex(
          (prod) => prod._id === location.state.id
        ) > -1 ||
        userInfo.role === "admin"
      ) {
        dispatch(getProduct(location.state.id));

        // if (location.state && location.state.from) {
        //   setFrom("admin");
        // }
        // dispatch(
        //   addBrand({ token, values: { brandName }, requestType: "get" })
        // );
      } else {
        navigate("/user/equipment", { replace: true });
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (productInfo && pstatus == "succeeded") {
      // if (
      //   productInfo.itemName === "" ||
      //   productInfo.itemDescription === "" ||
      //   productInfo.itemCategory === "" ||
      //   productInfo.itemSubCategory === "" ||
      //   // productInfo.itemCondition === "" ||
      //   productInfo.itemBrand === ""
      //   // productInfo.itemModel === "" ||
      //   // productInfo.itemYearManufacture === "" ||
      //   // productInfo.itemHoursUsed === ""
      // ) {
      //   setActiveStep(0);
      // } else if (
      //   (productInfo.itemForRent === false ||
      //     (!productInfo.itemMonthlyPrice &&
      //       !productInfo.itemWeeklyPrice &&
      //       !productInfo.itemDailyPrice)) &&
      //   (productInfo.itemToSell === false || !productInfo.itemAmount)
      // ) {
      //   setActiveStep(1);
      // } else {
      //   setActiveStep(2);
      // }

      console.log("user info", userInfo);
      setProductDetail(productInfo);
      console.log(productInfo);
      formRef.current.setFieldValue("itemName", productInfo.itemName);

      formRef.current.setFieldValue(
        "itemDescription",
        productInfo.itemDescription
      );
      formRef.current.setFieldValue(
        "itemCondition",
        productInfo.itemCondition.charAt(0).toUpperCase() +
          productInfo.itemCondition.slice(1)
      );
      formRef.current.setFieldValue("itemBrand", productInfo.itemBrand);
      const brnd = brands.filter((brd) => brd._id === productInfo.itemBrand);
      setSelectedBrand(brnd && brnd[0]);

      // formRef.current.setFieldValue("itemModel", productInfo.itemModel);
      formRef.current.setFieldValue(
        "itemYearManufacture",
        productInfo.itemYearManufacture
      );
      formRef.current.setFieldValue(
        "itemAmount",
        productInfo.itemAmount ? productInfo.itemAmount : 0
      );
      if (productInfo.itemLocation) {
        formRef.current.setFieldValue(
          "itemLocation",
          productInfo.itemLocation || ""
        );
        setFullLocation({
          label: productInfo.itemLocation,
          value: { description: productInfo.itemLocation },
        });
        // setInputValue(
        //   [productInfo?.itemCity, productInfo?.itemState]
        //     .filter(Boolean)
        //     .join(", ")
        // );
        setInputValue(
          [
            productInfo.itemLocation,
            productInfo?.itemCity,
            productInfo?.itemState,
          ]
            .filter(Boolean)
            .join(", ")
        );
        formRef.current.setFieldValue("itemCity", productInfo.userCity);
        formRef.current.setFieldValue("itemState", productInfo.userState);
        formRef.current.setFieldValue("itemZipCode", productInfo.userZipCode);
      } else {
        populateLocation();
      }

      // console.log([new Date(productInfo.itemLeaseStart),new Date(productInfo.itemLeaseEnd)])
      // setValue([
      //   new Date(productInfo.itemLeaseStart),
      //   new Date(productInfo.itemLeaseEnd),
      // ]);
      // formRef.current.setFieldValue(
      //   "itemLeaseStart",
      //   new Date(productInfo.itemLeaseStart)
      // );
      // formRef.current.setFieldValue(
      //   "itemLeaseEnd",
      //   new Date(productInfo.itemLeaseEnd)
      // );
      formRef.current.setFieldValue(
        "itemMonthlyPrice",
        productInfo.itemMonthlyPrice !== null
          ? productInfo.itemMonthlyPrice
          : ""
      );
      console.log("ITEMMONTHLY", productInfo.itemMonthlyPrice);
      formRef.current.setFieldValue(
        "itemWeeklyPrice",
        productInfo.itemWeeklyPrice !== null ? productInfo.itemWeeklyPrice : ""
      );
      formRef.current.setFieldValue(
        "itemDailyPrice",
        productInfo.itemDailyPrice !== null ? productInfo.itemDailyPrice : ""
      );

      formRef.current.setFieldValue(
        "itemCleaningPrice",
        productInfo.itemCleaningPrice !== null
          ? productInfo.itemCleaningPrice
          : ""
      );

      formRef.current.setFieldValue(
        "itemFuelPrice",
        productInfo.itemFuelPrice !== null ? productInfo.itemFuelPrice : ""
      );

      formRef.current.setFieldValue(
        "itemMaintenancePrice",
        productInfo.itemMaintenancePrice !== null
          ? productInfo.itemMaintenancePrice
          : ""
      );

      formRef.current.setFieldValue(
        "itemForRent",
        JSON.parse(productInfo.itemForRent)
      );
      formRef.current.setFieldValue("itemToSell", productInfo.itemToSell);
      let updatedFields = productInfo.itemLeaseFields.map((dateObject) => {
        const fromDate = moment(dateObject.from).toDate();
        const toDate = moment(dateObject.to).toDate();
        return {
          initVal: [fromDate, toDate],
        };
      });
      setItemLeaseFields(updatedFields);
      formRef.current.setFieldValue("itemCategory", productInfo.itemCategory);
      formRef.current.setFieldValue(
        "itemSubCategory",
        productInfo.itemSubCategory
      );
      formRef.current.setFieldValue("itemToSell", productInfo.itemToSell);
      formRef.current.setFieldValue("itemForRent", productInfo.itemForRent);

      formRef.current.setFieldValue(
        "itemMonthlyPrice",
        productInfo.itemMonthlyPrice ? productInfo.itemMonthlyPrice : ""
      );
      console.log("ITEMMONTHLY", productInfo.itemMonthlyPrice);
      formRef.current.setFieldValue(
        "itemWeeklyPrice",
        productInfo.itemWeeklyPrice ? productInfo.itemWeeklyPrice : ""
      );
      formRef.current.setFieldValue(
        "itemDailyPrice",
        productInfo.itemDailyPrice ? productInfo.itemDailyPrice : ""
      );
      formRef.current.setFieldValue(
        "itemAvailability",
        productInfo.itemAvailability || "except"
      );
      formRef.current.setFieldValue(
        "itemToHold",
        productInfo.itemToHold || false
      );
      formRef.current.setFieldValue(
        "itemHoursUsed",
        productInfo.itemHoursUsed !== null && productInfo.itemHoursUsed !== 0
          ? productInfo.itemHoursUsed
          : ""
      );

      formRef.current.setFieldValue(
        "cancellation",
        productInfo.cancellation !== undefined
          ? productInfo.cancellation
          : false
      );

      formRef.current.setFieldValue(
        "cancellationDate",
        productInfo.cancellationDate !== undefined
          ? productInfo.cancellationDate
          : "0"
      );

      if (productInfo?.itemGoal) {
        extractLabelAndValues("category", productInfo.itemGoal);
      }
      if (productInfo?.itemCategoryName) {
        extractLabelAndValues("subcategory", productInfo.itemCategoryName);
      }
      if (
        productInfo?.itemGoal &&
        productInfo?.itemCategoryName &&
        productInfo?.itemSubCategoryName
      ) {
        fetchFormFields(
          productInfo.itemGoal,
          productInfo.itemCategoryName,
          productInfo.itemSubCategoryName
        );

        // if (formFields && formFields.length > 0) {
        //   console.log("Form Fields", formFields);
        //   formFields.forEach((fld) => {
        //     formRef.current.values[fld.formikName] =
        //       productInfo?.fldData[fld.formikName] || "";
        //   });
        // }
      }

      formRef.current.setFieldValue("itemGoal", productInfo?.itemGoal);
      setSelectedGoal(
        productInfo?.itemGoal && {
          label: productInfo.itemGoal,
          value: productInfo.itemGoal.toLowerCase().replace(/ /g, "_"),
        }
      );

      formRef.current.setFieldValue(
        "itemCategoryName",
        productInfo?.itemCategoryName
      );
      setSelectedCategory(
        productInfo?.itemCategoryName && {
          label: productInfo.itemCategoryName,
          value: productInfo.itemCategoryName.toLowerCase().replace(/ /g, "_"),
        }
      );

      formRef.current.setFieldValue(
        "chargesIncurredBySeller",
        productInfo?.chargesIncurredBySeller || false
      );

      formRef.current.setFieldValue(
        "itemTransportationPrice",
        productInfo?.itemTransportationPrice || ""
      );

      formRef.current.setFieldValue(
        "itemTransportationPrice2",
        productInfo?.itemTransportationPrice2 || ""
      );

      formRef.current.setFieldValue(
        "itemSubCategoryName",
        productInfo?.itemSubCategoryName
      );
      setSelectedSubCategory(
        productInfo?.itemSubCategoryName && {
          label: productInfo.itemSubCategoryName,
          value: productInfo.itemSubCategoryName
            .toLowerCase()
            .replace(/ /g, "_"),
        }
      );

      formRef.current.setFieldValue(
        "attachments",
        productInfo?.attachments || []
      );
      // setProductDetail((prev) => {
      //   const { fldData, ...rest } = prev;
      //   return rest;
      // });

      setEImages(productInfo.itemImages);
      let leasePeriod = productInfo.itemLeaseFields.map((lease, index) => {
        return { initVal: [new Date(lease.from), new Date(lease.to)] };
      });
      console.log(leasePeriod);
      setItemLeaseFields(leasePeriod);
      setLangLong({
        lng: productInfo.location.coordinates[0],
        lat: productInfo.location.coordinates[1],
      });
      if (productInfo.draft) {
        setIsDraft(true);
      }

      setSpinner(false);
      dispatch(reset());
    }
  }, [productInfo]);

  useEffect(() => {
    if (productInfo && pstatus === "succeeded") {
      if (!productInfo.itemHoursUsed || productInfo.itemHoursUsed === "") {
        setActiveStep(0);
        // toast.info("Please enter no. of hours used", toastOption);
      }
    }
  }, [productInfo]);

  useEffect(() => {
    if (itemError) {
      toast.error("Something Went Wrong Please Try Again", toastOption);
      dispatch(resetItem());
      setSpinner(false);
    }
  }, [itemError]);

  const populateLocation = () => {
    if (isThirdPartyEntity()) {
      formRef.current.setFieldValue(
        "itemLocation",
        userInfo.userDetailsId.userAddress
        // userInfo.userDetailsId?.userCity +
        //   "," +
        //   userInfo.userDetailsId?.userState
      );
    } else {
      formRef.current.setFieldValue(
        "itemLocation",
        userInfo.userDetailsId.userAddress
      );
    }
    formRef.current.setFieldValue("itemCity", userInfo.userDetailsId?.userCity);
    formRef.current.setFieldValue(
      "itemState",
      userInfo.userDetailsId?.userState
    );
    formRef.current.setFieldValue(
      "itemZipCode",
      userInfo.userDetailsId?.userZipCode
    );

    if (!isThirdPartyEntity()) {
      setInputValue(
        [
          userInfo.userDetailsId.userAddress,
          userInfo.userDetailsId?.userCity,
          userInfo.userDetailsId?.userState,
          userInfo.userDetailsId?.userZipCode,
        ]
          .filter(Boolean)
          .join(", ")
      );
    } else {
      setInputValue(
        [
          // userInfo.userDetailsId?.userCity, userInfo.userDetailsId?.userState

          userInfo.userDetailsId.userAddress,
          userInfo.userDetailsId?.userCity,
          userInfo.userDetailsId?.userState,
          userInfo.userDetailsId?.userZipCode,
        ]
          .filter(Boolean)
          .join(", ")
      );
    }
  };

  const depopulateLocation = () => {
    formRef.current.setFieldValue("itemLocation", "");
    formRef.current.setFieldValue("itemCity", "");
    formRef.current.setFieldValue("itemState", "");
    formRef.current.setFieldValue("itemZipCode", "");
    setInputValue("");
  };

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState([]);
  const [autopopulate, setAutopopulate] = useState(false);
  console.log("selected sub category", selectedSubCategory);
  const baseStructure = [
    { formikName: "attchName", name: "Name", type: "text", colSize: 12 },
    {
      formikName: "attchDesc",
      name: "Description",
      type: "text",
      rows: 4,
      colSize: 12,
    },
    {
      formikName: "attchGoal",
      name: "Category",
      type: "select",
      options: goalOptions,
      defaultValue: selectedGoal,
      colSize: 6,
    },
    {
      formikName: "attchCategory",
      name: "Sub-category",
      type: "select",
      options: dynamicCategoryOptions,
      defaultValue:
        selectedGoal?.label === "Earth Manipulation"
          ? {
              label: "Attachments",
              value: "Attachments",
            }
          : {
              label: "Lift Attachments",
              value: "Lift Attachments",
            },
      colSize: 6,
    },
    {
      formikName: "attchSubCategory",
      name: "Type",
      type: "select",
      options:
        formRef?.current?.values?.itemGoal === "Earth Manipulation"
          ? earthManipulationAttachments.map((key) => ({
              label: key,
              value: key,
            }))
          : liftingAttachments.map((key) => ({
              label: key,
              value: key,
              // value: key.toLowerCase().replace(/ /g, "_"),
            })),
      // defaultValue: "",
      colSize: 12,
    },
    {
      formikName: "attchCondition",
      name: "Condition",
      type: "select",
      options: conditions,
      colSize: 6,
    },
    {
      formikName: "attchBrand",
      name: "Brand",
      type: "select",
      options: brands,
      colSize: 6,
    },
    {
      formikName: "attchYearManufactured",
      name: "Year Manufactured",
      type: "select",
      options: years,
      colSize: 6,
    },
    {
      formikName: "attchHoursUsed",
      name: "Hours Used",
      type: "text",
      colSize: 6,
    },
    {
      formikName: "attchSize",
      name: "Size",
      type: "text",
      colSize: 12,
    },
  ];

  const [dynamicAttchCount, setDynamicAttchCount] = useState(0);
  const addDynamicAttachment = () => {
    setDynamicAttchCount((prev) => prev + 1);
    if (formRef.current) {
      let updatedBaseStructure = baseStructure;
      const currentAttachments = formRef.current.values.attachments || [];

      const transformedBaseStructure = updatedBaseStructure.reduce(
        (acc, field) => {
          acc[field.formikName] = "";
          if (field.formikName === "attchGoal") {
            acc[field.formikName] = selectedGoal?.label;
          } else if (field.formikName === "attchCategory") {
            acc[field.formikName] =
              selectedGoal?.label === "Earth Manipulation"
                ? "Attachments"
                : "Lift Attachments";
          } else if (field.formikName === "attchSize") {
            if (formFields && formFields.length > 0) {
              let sizeAttachment = formFields.filter(
                (fld) => fld.name === "Size"
              );
              if (sizeAttachment) {
                sizeAttachment = sizeAttachment[0];
                // sizeAttachment.formikName = "attchSize";
                // acc[field.formikName] = "attchSize";
                field.type = sizeAttachment.type;
              }
            }
          }
          return acc;
        },
        {}
      );
      formRef.current.setFieldValue("attachments", [
        ...currentAttachments,
        transformedBaseStructure,
      ]);
    }
    // formRef.current.values = [...formRef.current.values, dynamicAttachments: dy]
  };

  const deleteAttachment = (attachmentIndex) => {
    const updatedAttachments = formRef.current.values.attachments.filter(
      (_, index) => index !== attachmentIndex
    );
    formRef.current.setFieldValue("attachments", updatedAttachments);
  };

  const handleValidateSpecificFields = (fieldsToValidate) => {
    const validationPromises = fieldsToValidate.map((fieldName) =>
      formRef.current.validateField(fieldName)
    );

    Promise.all(validationPromises).then((results) => {
      const touchedFields = fieldsToValidate.reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {});
      formRef.current.setTouched(touchedFields, true);

      const errors = formRef.current.errors;
      if (
        Object.keys(errors).some((field) => fieldsToValidate.includes(field))
      ) {
        // alert('Some fields are invalid');
        toast.error("Please validate all the required fields", toastOption);
        return;
      }
      // else if (
      //   fieldsToValidate.indexOf("fldMake") !== -1 &&
      //   formRef.current.values["fldMake"] === ""
      // ) {
      //   toast.error("Please validate all the required fields", toastOption);
      //   return;
      // }
      else {
        // alert("All specified fields are valid");
        saveDetails(activeStep + 2);
        window.scrollTo(0, 0);
        setActiveStep((prev) => prev + 1);
      }
    });
  };

  const handleStep = (stepp, values) => () => {
    console.log(activeStep, stepp);
    if (activeStep === 0 && stepp > activeStep) {
      if (eimages.length === 0) {
        window.scrollTo(0, 0);
        toast.error("Please Upload Equipment Images", toastOption);
        return;
      } else {
        const formFieldsKeys = formFields.map((fld) => fld.formikName);
        handleValidateSpecificFields([
          "itemName",
          "itemDescription",
          "itemCategoryName",
          "itemSubCategoryName",
          "itemGoal",
          // "itemCondition",
          "itemBrand",
          // "itemModel",
          // "itemYearManufacture",
          // "itemHoursUsed",
          ...formFieldsKeys,
        ]);
      }
    } else if (activeStep === 1 && stepp > activeStep) {
      if (values.itemForRent === false && values.itemToSell === false) {
        toast.error(
          "Please select either Equipment to Sell or Rent",
          toastOption
        );
        return;
      } else if (values.itemToSell && values.itemAmount === "") {
        toast.error("Please select equipment amount", toastOption);
        return;
      } else if (
        values.itemForRent &&
        values.itemDailyPrice === "" &&
        values.itemWeeklyPrice === "" &&
        values.itemMonthlyPrice === ""
      ) {
        toast.error(
          "Please select atleast one price from daily, weekly or monthly",
          toastOption
        );
        return;
      } else {
        if (!isThirdPartyEntity()) {
          handleValidateSpecificFields([
            "itemTransportationPrice",
            "itemTransportationPrice2",
          ]);
        } else {
          if (
            values["itemDailyPrice"] !== staticInitialValues.itemDailyPrice ||
            values["itemMonthlyPrice"] !== staticInitialValues.itemMonthlyPrice
          ) {
            window.scrollTo(0, 0);
            saveDetails(activeStep);
            setActiveStep(stepp);
          }
        }
      }
    } else {
      saveDetails(activeStep);
      setActiveStep(stepp);
    }
  };

  let { id } = useParams();

  useEffect(() => {
    navigate(`/editproduct/${id}/#/${activeStep}`, {
      state: {
        id: location.state.id,
      },
    });
  }, [activeStep]);

  useEffect(() => {
    const handleHashChange = () => {
      const stepFromHash = window.location.hash.replace("#/", "");
      console.log("HASH CHANGDE", stepFromHash);
      if (stepFromHash) {
        setActiveStep(Number(stepFromHash));
      }
    };

    // Listen for changes to the hash in the URL
    window.addEventListener("hashchange", handleHashChange);

    // Set the initial activeStep based on the current hash
    handleHashChange();

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [setActiveStep]);

  const handleNext = (values) => {
    if (activeStep === 0) {
      if (eimages.length === 0) {
        window.scrollTo(0, 0);
        toast.error("Please Upload Equipment Images", toastOption);
        return;
      } else {
        const formFieldsKeys = formFields.map((fld) => fld.formikName);
        handleValidateSpecificFields([
          "itemName",
          "itemDescription",
          "itemCategoryName",
          "itemSubCategoryName",
          "itemGoal",
          // "itemCondition",
          "itemBrand",
          // "itemModel",
          // "itemYearManufacture",
          // "itemHoursUsed",
          ...formFieldsKeys,
        ]);
      }
    } else if (activeStep === 1) {
      if (values.itemForRent === false && values.itemToSell === false) {
        toast.error(
          "Please select either Equipment to Sell or Rent",
          toastOption
        );
        return;
      } else if (values.itemToSell && values.itemAmount === "") {
        toast.error("Please select equipment amount", toastOption);
        return;
      } else if (
        values.itemForRent &&
        values.itemDailyPrice === "" &&
        values.itemWeeklyPrice === "" &&
        values.itemMonthlyPrice === ""
      ) {
        toast.error(
          "Please select atleast one price from daily, weekly or monthly",
          toastOption
        );
        return;
      } else {
        if (!isThirdPartyEntity()) {
          handleValidateSpecificFields([
            "itemTransportationPrice",
            "itemTransportationPrice2",
          ]);
        } else {
          if (
            values["itemDailyPrice"] !== staticInitialValues.itemDailyPrice ||
            values["itemMonthlyPrice"] !== staticInitialValues.itemMonthlyPrice
          ) {
            window.scrollTo(0, 0);
            saveDetails(activeStep + 2);
            setActiveStep((prev) => prev + 1);
          }
        }
      }
    }
  };

  function delist() {
    swal({
      title: "Delist Item",
      text: "Are you sure you want to Delist this item, Once Delisted it will not be available for rent or sell",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("inktoken");
        dispatch(delistItem({ token, id: location.state.id }));
        // if (token) {
        //   setSpinner(true)
        //   dispatch(
        //     deleteProduct({
        //       token,
        //       id,
        //     })
        //   );
        // } else {
        //   // navigate("/404", { replace: true });
        // }
      }
    });
  }

  const saveDetails = (step) => {
    if (isDraft) {
      console.log(formRef.current.values);
      let values = { ...formRef.current.values };
      let payload = { ...values, images: [] };
      // console.log(process.env.REACT_APP_CLOUDINARY_API_SECRET);
      console.log(eimages);
      if (!values.itemLocation.label)
        values.itemLocation = { label: values.itemLocation };
      console.log(values.itemLocation);
      geocodeByAddress(values.itemLocation.label)
        .then((results) => {
          console.log(results);

          // formRef.current.setFieldValue("itemLocation", {
          //   label: results[0].formatted_address,
          //   value: { description: results[0].formatted_address },
          // });
          return getLatLng(results[0]);
        })
        .then(({ lat, lng }) => {
          console.log("Successfully got latitude and longitude", {
            lat,
            lng,
          });

          // setImages([...payload.images])
          // let token = localStorage.getItem("inktoken");
          // if (token) {
          //   console.log(payload);
          //   console.log(langLong);
          payload["langLong"] = {
            lat: lat,
            lng: lng,
          };
          values["langLong"] = {
            lat: lat,
            lng: lng,
          };

          //     activeStep:step,
          //     pId:savedId?savedId:""
          //   };
          //   dispatch(saveItem({ token, values: payload }));
          // } else {
          //   navigate("/signup");
          // }
          // setLangLong({ lng, lat });
          // langLongs = { lng, lat };
          run();
        });

      function run() {
        if (eimages.length > 0) {
          // setSpinner(true);
          const timestamp = Math.round(new Date().getTime() / 1000);
          const params = {
            timestamp: timestamp,
            transformation: "f_webp,q_70,c_scale,w_1000",
            upload_preset: "closet-closest",
            // add any additional parameters here, such as transformation options
          };
          const signature = CryptoJS.SHA1(
            `timestamp=${params.timestamp}&transformation=${params.transformation}&upload_preset=${params.upload_preset}${process.env.REACT_APP_CLOUDINARY_API_SECRET}`
          ).toString();

          const uploaders = eimages.map(async (file, index) => {
            if (typeof file !== "string") {
              // Initial FormData
              const formData = new FormData();
              formData.append("file", file);
              formData.append("upload_preset", "closet-closest");
              formData.append("cloud_name", "closet-closest");
              formData.append("transformation", "f_webp,q_70,c_scale,w_1000");
              formData.append("signature", signature);
              formData.append("timestamp", timestamp);
              formData.append(
                "api_key",
                process.env.REACT_APP_CLOUDINARY_API_TOKEN
              );

              return axios
                .post(process.env.REACT_APP_CLOUDINARY_API_URL, formData, {
                  headers: { "X-Requested-With": "XMLHttpRequest" },
                })
                .then((response) => {
                  const data = response.data;
                  let fileURL = data.url; // You should store this URL for future references in your app
                  if (fileURL.includes("http://")) {
                    fileURL = fileURL.replace("http://", "https://");
                  }
                  if (index === feature)
                    payload = {
                      ...values,
                      images: [fileURL, ...payload.images],
                    };
                  else
                    payload = {
                      ...values,
                      images: [...payload.images, fileURL],
                    };
                })
                .catch((e) => {
                  console.log(e);
                  throw new Error("Something went Wrong!!!!");
                });
            } else {
              if (index === feature)
                payload = { ...values, images: [file, ...payload.images] };
              else payload = { ...values, images: [...payload.images, file] };
            }
          });

          axios
            .all(uploaders)
            .then(() => {
              setEImages([...payload.images]);
              let token = localStorage.getItem("inktoken");
              if (token) {
                console.log(payload);
                console.log(langLong);
                payload = {
                  ...payload,
                  itemLeaseFields,
                  activeStep: step,
                  pId: location.state.id,
                  itemLocation: payload.itemLocation.label,
                };
                dispatch(saveItem({ token, values: payload }));
              } else {
                navigate("/signup");
              }

              // setValues(payload);
              // payload.color = colors;

              // setSubmitting(false);
            })
            .catch((e) => {
              console.log(e);
              toast.error("Something Went Wrong Please Try Again", toastOption);
              // dispatch(addBrand({ token, values: { brandName }, requestType: "get" }));
            });
        }
      }
    }
  };

  useEffect(() => {
    if (itemDelisted) {
      toast.success("Item Delisted Successfully", toastOption);
      dispatch(resetItem());
      navigate("/user/equipment", { replace: true });
    }
  }, [itemDelisted]);

  const [formFields, setFormFields] = useState([]);
  // const [initialValues, setInitialValues] = useState(initialValuess);
  const [loadingForFields, setLoadingForFields] = useState(false);
  const [disableProductDetailUsage, setDisableProductDetailUsage] =
    useState(false);

  const { fieldsInfo, fieldStatus } = useSelector(
    (state) => state.productfields
  );

  const fetchFormFields = (goal, category, subCategory) => {
    // if (!isThirdPartyEntity()) {
    setLoadingForFields(true);
    dispatch(
      getProductFields({
        goal: goal,
        category: category,
        subCategory: subCategory,
      })
    );
    // }
  };

  const toCamelCase = (str) => {
    return (
      "fld" +
      str
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove non-alphanumeric characters including parentheses
        .toLowerCase() // Convert the entire string to lowercase
        .split(" ") // Split the string by spaces
        .map((word, index) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word except the first word
        .join("") // Join the words back together
    );
  };

  const removeKeys = (obj, keysToRemove) => {
    const newObj = { ...obj };
    keysToRemove.forEach((key) => delete newObj[key]);
    return newObj;
  };

  const mergeInitialValues = (existingValues, newFields) => {
    const newValues = {};
    newFields.forEach((field) => {
      if (!(field in existingValues)) {
        newValues[field] = "";
      }
    });
    return { ...existingValues, ...newValues };
  };

  const generateDynamicFieldsValidationSchema = (fields) => {
    const schema = {};

    fields.forEach((field) => {
      let validator = Yup.string().required("This field is required");

      if (field.type === "text") {
        validator = Yup.string().required(`Please enter ${field.name}`);
      } else if (field.type === "select") {
        validator = Yup.string()
          .oneOf(field.options, "Invalid option")
          .required(`Please select ${field.name}`);
      }
      // else if (field.type === "autocompleteWithCreateOption") {
      //   validator = Yup.string().required(`Please select ${field.name}`);
      // }
      // Add other field types if needed

      schema[field.formikName] = validator;
    });

    return Yup.object().shape(schema);
  };

  useEffect(() => {
    if (formFields && formFields.length > 0) {
      const currentFormikValues = formRef.current?.values || {};
      const keysToRemove = formFields.map((fld) => fld.formikName);
      const updatedValues = removeKeys(currentFormikValues, keysToRemove);

      // setInitialValues(updatedValues);
      setFormFields([]);
      // setDisableProductDetailUsage(true);

      formRef.current.values = updatedValues;
    }
  }, [selectedCategory, selectedSubCategory, selectedGoal]);

  useEffect(() => {
    if (fieldsInfo) {
      const tempFieldsInfo = fieldsInfo?.fields.filter(
        (fld) => fld.name === "Size"
      );
      const mappedFields =
        tempFieldsInfo && tempFieldsInfo.length > 0
          ? tempFieldsInfo?.map((field) => ({
              ...field,
              formikName: toCamelCase(field.name),
            }))
          : [];
      if (mappedFields) {
        const prevFormFieldsToRemove = formFields || [];
        setFormFields(mappedFields);
        if (prevFormFieldsToRemove && prevFormFieldsToRemove.length > 0) {
          const keysToAdd = mappedFields.map((fld) => fld.formikName);
          const currentFormikValues = formRef.current?.values || {};
          const keysToRemove = prevFormFieldsToRemove.map(
            (fld) => fld.formikName
          );
          const updatedValues = removeKeys(currentFormikValues, keysToRemove);
          const merged = mergeInitialValues(updatedValues, keysToAdd);
          // setInitialValues(merged);
          formRef.current.values = merged;
          console.log("final formik", merged);
        } else {
          if (mappedFields && mappedFields.length > 0) {
            mappedFields.forEach((fld) => {
              formRef.current.values[fld.formikName] =
                (productInfo?.fldData && productInfo.fldData[fld.formikName]) ||
                // (!disableProductDetailUsage &&
                (productDetail?.fldData &&
                  productDetail?.fldData[fld.formikName]) ||
                "";
            });
          }
          // mappedFields.forEach((field) => {
          //   formRef.current.values[field.formikName] = "";
          // });
        }
        const dynamicValidationSchema =
          generateDynamicFieldsValidationSchema(mappedFields);
        setValidationSchema(
          !isThirdPartyEntity()
            ? staticValidationSchema.concat(dynamicValidationSchema)
            : staticValidationForThirdPartyEntity.concat(
                dynamicValidationSchema
              )
        );
        dispatch(resetFields());
        setLoadingForFields(false);
      }
    }
  }, [fieldsInfo]);

  const calculateTotalForPreviewForBoth = (type) => {
    const {
      itemAmount,
      itemTransportationPrice,
      itemDailyPrice,
      itemWeeklyPrice,
      itemMonthlyPrice,
      itemCleaningPrice,
      itemFuelPrice,
      itemMaintenancePrice,
      itemEnvironmentalFee,
    } = formRef.current.values;
    let totalForSell = 0;
    let totalForRent = 0;
    if (type === "buy") {
      totalForSell += itemAmount ? parseFloat(itemAmount) : 0;
      totalForSell += itemTransportationPrice
        ? parseFloat(itemTransportationPrice)
        : 0;
    } else if (type === "rent") {
      if (itemDailyPrice) {
        totalForRent += parseFloat(itemDailyPrice);
      } else if (itemWeeklyPrice) {
        totalForRent += parseFloat(itemWeeklyPrice);
      } else if (itemMonthlyPrice) {
        totalForRent += parseFloat(itemMonthlyPrice);
      }

      totalForRent += itemCleaningPrice ? parseFloat(itemCleaningPrice) : 0;
      totalForRent += itemFuelPrice ? parseFloat(itemFuelPrice) : 0;
      totalForRent += itemMaintenancePrice
        ? parseFloat(itemMaintenancePrice)
        : 0;
      totalForRent += itemTransportationPrice
        ? parseFloat(itemTransportationPrice * 2)
        : 0;
      if (itemEnvironmentalFee)
        totalForRent += (totalForRent * parseInt(itemEnvironmentalFee)) / 100;
    }
    // console.log("Total for preview", total);
    return type === "buy"
      ? totalForSell.toFixed(2).toLocaleString("en-us")
      : totalForRent.toFixed(2).toLocaleString("en-us");
  };

  const staticInitialValues = {
    itemName: "",
    itemDescription: "",
    itemCategoryName: "",
    itemSubCategoryName: "",
    itemGoal: "",
    itemCondition: "",
    itemBrand: "" || null,
    // itemModel: "",
    itemYearManufacture: "",
    itemAmount: "",
    itemLocation: "",
    itemLease: [],
    itemToSell: false,
    itemForRent: thirdPartyAccountEntity.includes(
      userInfo?.userDetailsId?.userEmail
    )
      ? true
      : false,
    itemDailyPrice: "",
    itemWeeklyPrice: "",
    itemMonthlyPrice: "",
    itemCleaningPrice: "",
    itemFuelPrice: "",
    itemMaintenancePrice: "",
    itemToHold: "",
    cancellation: false,
    cancellationDate: "3",
    itemAvailability: "",
    itemHoursUsed: "",
    itemCity: "",
    itemState: "",
    itemZipCode: "",
    chargesIncurredBySeller: false,
    itemTransportationPrice: "",
    itemTransportationPrice2: "",
    attachments: [],
    itemEnvironmentalFee: ""
    // merchantFeed: thirdPartyAccountEntity.includes(
    //   userInfo.userDetailsId.userEmail
    // )
    //   ? true
    //   : false,
    // link: "",
  };

  const staticValidationSchema = Yup.object().shape({
    itemName: Yup.string().required("Please enter Equipment Name"),
    // itemType: Yup.string().required("Please enter Equipment Type"),
    itemDescription: Yup.string().required(
      "Please enter your Equipment Description"
    ),
    itemGoal: Yup.string().required("Please select equipment category"),
    itemCategoryName: Yup.string().required(
      "Please select equipment sub-category"
    ),
    itemSubCategoryName: Yup.string().required("Please select equipment type"),
    itemCondition: Yup.string().required("Please select Equipment Condition"),
    itemBrand: Yup.string().required("Please select Equipment Brand"),
    // itemModel: Yup.string().required("Please enter Equipment Model"),
    itemYearManufacture: Yup.string()
      .matches(/^(19|20)\d{2}$/, "Please enter a valid year (e.g., 2023)")
      .required("Please select Equipment Manufacture (year)"),
    itemAmount: Yup.number()
      .typeError("Amount must be a number")
      .when("itemToSell", {
        is: true,
        then: (schema) => schema.required("Amount is required"),
        // otherwise: (schema) => schema.number(),
      }),
    itemLocation: Yup.string().required('Please enter Equipment Location"'),
    itemDailyPrice: Yup.number().typeError(
      "Equipment Daily Price must be a number"
    ),
    itemWeeklyPrice: Yup.number().typeError(
      "Equipment Weekly Price must be a number"
    ),
    itemMonthlyPrice: Yup.number().typeError(
      "Equipment Monthly Price must be a number"
    ),
    itemMaintenancePrice: Yup.number().typeError(
      "Equipment Maintenance Price must be a number"
    ),
    itemFuelPrice: Yup.number().typeError(
      "Equipment Fuel Price must be a number"
    ),
    itemCleaningPrice: Yup.number().typeError(
      "Equipment Cleaning Price must be a number"
    ),
    itemTransportationPrice: Yup.number()
      .required("Please enter Transportation price")
      .typeError("Transportation price must be a number"),
    itemTransportationPrice2: Yup.number()
      .required("Please enter Transportation price")
      .typeError("Transportation price must be a number"),
    // itemForRent: Yup.boolean().test(
    //   "atLeastOnePriceRequired",
    //   "At least one of the rental prices is required",
    //   function (value) {
    //     if (value) {
    //       const { itemDailyPrice, itemWeeklyPrice, itemMonthlyPrice } =
    //         this.parent;
    //       if (!itemDailyPrice && !itemWeeklyPrice && !itemMonthlyPrice) {
    //         return this.createError({
    //           message: "At least one of the rental prices is required",
    //           path: "itemRent",
    //         });
    //       }
    //     }
    //     return true;
    //   }
    // ),
    itemHoursUsed: Yup.number()
      .min(1, "Hours Used should be greater than 0")
      .required("Please enter no. of hours used")
      .typeError("Hours Used must be a number"),
  });

  const staticValidationForThirdPartyEntity = Yup.object().shape({
    itemName: Yup.string().required("Please enter Equipment Name"),
    // itemType: Yup.string().required("Please enter Equipment Type"),
    itemDescription: Yup.string().required(
      "Please enter your Equipment Description"
    ),
    itemGoal: Yup.string().required("Please select equipment category"),
    itemCategoryName: Yup.string().required(
      "Please select equipment sub-category"
    ),
    itemSubCategoryName: Yup.string().required("Please select equipment type"),
    itemBrand: Yup.string(),
    // itemModel: Yup.string().required("Please enter Equipment Model"),
    itemAmount: Yup.number()
      .typeError("Amount must be a number")
      .when("itemToSell", {
        is: true,
        then: (schema) => schema.required("Amount is required"),
        // otherwise: (schema) => schema.number(),
      }),
    itemLocation: Yup.string().required('Please enter Equipment Location"'),
    itemDailyPrice: Yup.number().typeError(
      "Equipment Daily Price must be a number"
    ),
    itemWeeklyPrice: Yup.number().typeError(
      "Equipment Weekly Price must be a number"
    ),
    itemMonthlyPrice: Yup.number().typeError(
      "Equipment Monthly Price must be a number"
    ),

    // itemForRent: Yup.boolean().test(
    //   "atLeastOnePriceRequired",
    //   "At least one of the rental prices is required",
    //   function (value) {
    //     if (value) {
    //       const { itemDailyPrice, itemWeeklyPrice, itemMonthlyPrice } =
    //         this.parent;
    //       if (!itemDailyPrice && !itemWeeklyPrice && !itemMonthlyPrice) {
    //         return this.createError({
    //           message: "At least one of the rental prices is required",
    //           path: "itemRent",
    //         });
    //       }
    //     }
    //     return true;
    //   }
    // ),
  });

  const [initialValues, setInitialValues] = useState(staticInitialValues);
  const [validationSchema, setValidationSchema] = useState(
    !isThirdPartyEntity()
      ? staticValidationSchema
      : staticValidationForThirdPartyEntity
  );

  return (
    <div className="page-container" style={{ position: "relative" }}>
      {spinner ? <Spinner /> : ""}
      <Formik
        initialValues={initialValues}
        initialErrors={{
          itemName: "Please enter Equipment Name",
          itemDescription: "Please enter your ItEquipmentem Description",
          // itemCategory: "Please select Equipment Category",
          // itemSubCategory: "Please select Equipment Subcategory",
          itemCondition: "Please select Equipment Condition",
          itemBrand: "Please select Equipment Brand",
          // itemModel: "Please enter Equipment Model",
          itemYearManufacture: "Please enter Equipment Manufacture",
          itemLocation: "Please enter Equipment Location",
          itemHoursUsed: "Please enter no. of hours used",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log("SUbmitting", values);
          //filter fldData before updating:
          let formRefValues = formRef.current.values;
          const fldKeys = Object.keys(formRefValues).filter((key) =>
            key.startsWith("fld")
          );
          const finalFldKeys = formFields.map((key) => key.formikName);
          const diff = fldKeys.filter((k) => !finalFldKeys.includes(k));
          const removeKeysFromObject = (obj, keys) => {
            const newObj = { ...obj };
            keys.forEach((key) => {
              delete newObj[key];
            });
            return newObj;
          };
          if (diff && diff.length > 0) {
            values = removeKeysFromObject(formRefValues, diff);
          } else {
            values = formRefValues;
          }

          console.log("Final Values: ", values);
          // if (values.itemHoursUsed <= 0) {
          //   toast.error("Please enter valid no. of hours", toastOption);
          //   return;
          // }
          if (
            itemLeaseFields &&
            itemLeaseFields.length > 0 &&
            !itemLeaseFields[0].initVal[0] &&
            values.itemAvailability === "except"
          ) {
            toast.error("Please Select Lease Date", toastOption);
            return;
          }
          if (Object.keys(langLong).length === 0) {
            toast.error("Please Select Correct Location !!", toastOption);
            formRef.current.setFieldValue("itemLocation", null);
            return;
          }
          let payload = { ...values, images: [] };

          console.log(process.env.REACT_APP_CLOUDINARY_API_SECRET);
          console.log(images);
          if (eimages.length > 0) {
            setSpinner(true);
            const timestamp = Math.round(new Date().getTime() / 1000);
            const params = {
              timestamp: timestamp,
              transformation: "f_webp,q_70,c_scale,w_1000",
              upload_preset: "closet-closest",
              // add any additional parameters here, such as transformation options
            };
            const signature = CryptoJS.SHA1(
              `timestamp=${params.timestamp}&transformation=${params.transformation}&upload_preset=${params.upload_preset}${process.env.REACT_APP_CLOUDINARY_API_SECRET}`
            ).toString();

            const uploaders = eimages.map(async (file, index) => {
              // Initial FormData
              if (typeof file !== "string") {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("upload_preset", "closet-closest");
                formData.append("cloud_name", "closet-closest");
                formData.append("transformation", "f_webp,q_70,c_scale,w_1000");
                formData.append("signature", signature);
                formData.append("timestamp", timestamp);
                formData.append(
                  "api_key",
                  process.env.REACT_APP_CLOUDINARY_API_TOKEN
                );

                return axios
                  .post(process.env.REACT_APP_CLOUDINARY_API_URL, formData, {
                    headers: { "X-Requested-With": "XMLHttpRequest" },
                  })
                  .then((response) => {
                    const data = response.data;
                    let fileURL = data.url; // You should store this URL for future references in your app
                    if (fileURL.includes("http://")) {
                      fileURL = fileURL.replace("http://", "https://");
                    }
                    if (index === feature)
                      payload = {
                        ...values,
                        images: [fileURL, ...payload.images],
                      };
                    else
                      payload = {
                        ...values,
                        images: [...payload.images, fileURL],
                      };
                  })
                  .catch((e) => {
                    console.log(e);
                    throw new Error("Something went Wrong!!!!");
                  });
              } else {
                if (index === feature)
                  payload = { ...values, images: [file, ...payload.images] };
                else payload = { ...values, images: [...payload.images, file] };
              }
            });

            axios
              .all(uploaders)
              .then(() => {
                // setValues(payload);
                // payload.color = colors;
                let token = localStorage.getItem("inktoken");
                if (token) {
                  console.log(payload);
                  payload = { ...payload, itemLeaseFields, langLong };
                  dispatch(
                    updateItem({
                      token,
                      values: payload,
                      id: location.state.id,
                    })
                  );
                } else {
                  navigate("/signup");
                }

                // setSubmitting(false);
              })
              .catch((e) => {
                toast.error(
                  "Something Went Wrong Please Try Again",
                  toastOption
                );
                // dispatch(addBrand({ token, values: { brandName }, requestType: "get" }));
              });
          } else {
            toast.error("Please upload Atlease one Image", toastOption);
          }

          // setSpinner(true);
        }}
        innerRef={formRef}
        render={(props) => {
          let {
            setFieldValue,
            values,
            handleBlur,
            handleChange,
            touched,
            errors,
            handleSubmit,
            setErrors,
            setFieldTouched,
            setFieldError,
          } = props;

          return (
            <Container
              maxWidth={"lg"}
              sx={{ margin: "2rem auto", minHeight: "100vh" }}
              data-aos="fade-in"
              data-aos-duration="1000"
            >
              <Stack
                sx={{ width: "100%" }}
                data-aos="fade-down"
                data-aos-duration="1200"
                marginTop={"2rem"}
              >
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel={true}
                  connector={<ColorlibConnector />}
                  style={{ marginBottom: "2rem" }}
                >
                  {steps.map((label, index) => (
                    <Step
                      key={label}
                      onClick={handleStep(index, values)}
                      completed={completed[index]}
                    >
                      <StepLabel
                        StepIconComponent={ColorlibStepIcon}
                      ></StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>

              <form onSubmit={handleSubmit}>
                {/* <div className="add-new-item-con">
                  <div className="add-new-item-wrapper"> */}
                <>
                  {activeStep + 1 === 1 && (
                    <>
                      <Grid container>
                        <Grid item xs={12}>
                          {eimages.length > 0 ? (
                            <div className="preview_image">
                              <div className="item_title">
                                Image Preview - Please Select the Feature Photo
                              </div>
                              <div className="preview_image_container">
                                <div
                                  className="img_name_cont"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexWrap: "wrap",
                                    margin: "10px 0px",
                                  }}
                                >
                                  {eimages.map((image, index) => {
                                    return (
                                      <div
                                        className={`img_icon_cont`}
                                        key={index}
                                      >
                                        <div className="img_name">
                                          <div
                                            className="cross"
                                            onClick={() => removeImg(index)}
                                          >
                                            X
                                          </div>
                                          <img
                                            className={`${
                                              feature === index ? "feature" : ""
                                            }`}
                                            onClick={() => setFeature(index)}
                                            src={
                                              typeof image === "string"
                                                ? image
                                                : URL.createObjectURL(image)
                                            }
                                            alt={image.name}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="img_upload_container">
                            <div className="file_icon_container">
                              <div className="item_title">
                                Take or Choose Photos
                              </div>
                              <label
                                htmlFor="closet_icon"
                                className={`file_upload ${
                                  dragActive ? "drag-active" : ""
                                }`}
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              >
                                <div className="icon_container add_icon_container">
                                  <AddAPhoto color="primary" />
                                  <p className="item_title" style={{}}>
                                    {" "}
                                    or drag them in
                                  </p>
                                </div>
                              </label>
                              <input
                                type="file"
                                multiple={true}
                                id="closet_icon"
                                hidden
                                onChange={(e) => {
                                  console.log(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                  setEImages((prevImg) => [
                                    ...prevImg,
                                    ...e.target.files,
                                  ]);
                                }}
                                style={{ borderRadius: "10px" }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      {/* <Typography
                        variant="h3"
                        fontWeight={700}
                        marginTop={"2rem"}
                        className="mont-title"
                      >
                        General Information
                      </Typography> */}
                      <Grid
                        container
                        spacing={4}
                        sx={{ flexGrow: 1 }}
                        marginTop={"0rem"}
                      >
                        <Grid item xs={12}>
                          <TextField
                            error={touched.itemName && Boolean(errors.itemName)}
                            required
                            name="itemName"
                            id="name"
                            label="Name"
                            defaultValue=""
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.itemName}
                            helperText={touched.itemName && errors.itemName} //required in case of error msg
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>

                      <TextField
                        required
                        fullWidth
                        error={
                          touched.itemDescription &&
                          Boolean(errors.itemDescription)
                        }
                        name="itemDescription" //true if error exists
                        id="description"
                        label="Description"
                        multiline
                        minRows={5}
                        placeholder="Enter Description"
                        defaultValue=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.itemDescription}
                        helperText={
                          touched.itemDescription && errors.itemDescription
                        }
                        sx={{ width: "100%", margin: "1rem auto" }}
                      />

                      {(productDetail?.itemCategory &&
                        productDetail?.itemCategory.length > 0) ||
                        (productDetail?.itemSubCategory &&
                          productDetail?.itemSubCategory?.length > 0 && (
                            <Grid
                              container
                              spacing={4}
                              sx={{ flexGrow: 1 }}
                              marginBottom={"1rem"}
                            >
                              <Grid item xs={6}>
                                <Mautocomplete
                                  onOpen={handleBlur}
                                  multiple
                                  id="combo-box-category"
                                  options={categoies.map(
                                    (category) => category._id
                                  )}
                                  getOptionLabel={(string) => {
                                    return (
                                      categoies.find(
                                        (category) => category._id === string
                                      )?.label ?? ""
                                    );
                                  }}
                                  value={values.itemCategory}
                                  sx={{ width: "100%" }}
                                  onChange={(e, value) => {
                                    const selectedIds =
                                      value.length > 0
                                        ? value.map((option) => option)
                                        : [];
                                    setFieldValue("itemCategory", selectedIds);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      name="itemCategory"
                                      label="Sub-category"
                                      error={Boolean(
                                        touched.itemCategory &&
                                          errors.itemCategory
                                      )}
                                      helperText={
                                        touched.itemCategory &&
                                        errors.itemCategory
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Mautocomplete
                                  onOpen={handleBlur}
                                  multiple
                                  id="combo-box-sub-category"
                                  options={subCategories.map((sub) => sub._id)}
                                  getOptionLabel={(string) => {
                                    return (
                                      subCategories.find(
                                        (sub) => sub._id === string
                                      )?.label ?? ""
                                    );
                                  }}
                                  value={values.itemSubCategory}
                                  sx={{ width: "100%" }}
                                  onChange={(e, value) => {
                                    const selectedIds =
                                      value.length > 0
                                        ? value.map((option) => option)
                                        : [];
                                    setFieldValue(
                                      "itemSubCategory",
                                      selectedIds
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Sub Category"
                                      name="itemSubCategory"
                                      error={Boolean(
                                        touched.itemSubCategory &&
                                          errors.itemSubCategory
                                      )}
                                      helperText={
                                        touched.itemSubCategory &&
                                        errors.itemSubCategory
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          ))}

                      <Grid
                        container
                        spacing={4}
                        sx={{ flexGrow: 1 }}
                        marginBottom={"1rem"}
                      >
                        <Grid item xs={6}>
                          <Mautocomplete
                            onOpen={handleBlur}
                            fullWidth
                            id="combo-box-goal"
                            options={goalOptions}
                            onChange={(e, value) => {
                              setFieldValue("itemGoal", value?.label);
                              setSelectedGoal(value);
                              extractLabelAndValues("category", value?.label);
                            }}
                            value={selectedGoal}
                            sx={{
                              width: "100%",
                              margin: "1rem 0",
                              marginTop: 0,
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category"
                                name="itemGoal"
                                error={Boolean(
                                  touched.itemGoal && errors.itemGoal
                                )}
                                helperText={touched.itemGoal && errors.itemGoal}
                                required
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Mautocomplete
                            noOptionsText="Please select category first..."
                            onOpen={handleBlur}
                            // multiple
                            id="combo-box-category"
                            options={dynamicCategoryOptions}
                            sx={{ width: "100%" }}
                            onChange={(e, value) => {
                              // const selectedIds =
                              //   value.length > 0
                              //     ? value.map((option) => option.value)
                              //     : [];
                              setFieldValue("itemCategoryName", value?.label);
                              setSelectedCategory(value);
                              extractLabelAndValues(
                                "subcategory",
                                value?.label
                              );
                              // debugger;
                              // if (
                              //   formRef.current.values.attachments &&
                              //   formRef.current.values.attachments.length > 0
                              // ) {
                              //   formRef.current.values.attachments = [];
                              // }
                            }}
                            value={selectedCategory}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="itemCategoryName"
                                label="Sub-category"
                                error={Boolean(
                                  touched.itemCategoryName &&
                                    errors.itemCategoryName
                                )}
                                helperText={
                                  touched.itemCategoryName &&
                                  errors.itemCategoryName
                                }
                                required
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} marginTop={"-1rem"}>
                        <Mautocomplete
                          onOpen={handleBlur}
                          // multiple
                          noOptionsText="Please select sub-category first..."
                          id="combo-box-sub-category"
                          options={dynamicSubCategoryOptions}
                          sx={{ width: "100%" }}
                          onChange={(e, value) => {
                            // const selectedIds =
                            //   value.length > 0
                            //     ? value.map((option) => option.value)
                            //     : [];
                            setFieldValue("itemSubCategoryName", value?.label);
                            setSelectedSubCategory(value);
                            // if (!isThirdPartyEntity()) {
                            fetchFormFields(
                              values.itemGoal,
                              values.itemCategoryName,
                              value?.label
                            );
                            // }
                          }}
                          value={selectedSubCategory}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Type"
                              name="itemSubCategoryName"
                              error={Boolean(
                                touched.itemSubCategoryName &&
                                  errors.itemSubCategoryName
                              )}
                              helperText={
                                touched.itemSubCategoryName &&
                                errors.itemSubCategoryName
                              }
                              required
                            />
                          )}
                        />
                      </Grid>
                      {!isThirdPartyEntity() ? (
                        <Grid
                          container
                          spacing={4}
                          sx={{
                            flexGrow: 1,
                            marginBottom: "1rem",
                            marginTop: "-1rem",
                          }}
                        >
                          <Grid item xs={6}>
                            <Mautocomplete
                              onOpen={handleBlur}
                              disablePortal
                              fullWidth
                              id="combo-box-conditions"
                              options={conditions.map(
                                (condition) => condition.label
                              )}
                              value={values.itemCondition}
                              onChange={(e, value) =>
                                setFieldValue("itemCondition", value)
                              }
                              sx={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Condition"
                                  name="itemCondition"
                                  error={Boolean(
                                    touched.itemCondition &&
                                      errors.itemCondition
                                  )}
                                  helperText={
                                    touched.itemCondition &&
                                    errors.itemCondition
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Mautocomplete
                              onOpen={handleBlur}
                              disablePortal
                              fullWidth
                              id="combo-box-brands"
                              options={brands.map((brand) => brand._id)}
                              filterOptions={(options, state) => {
                                const filtered = options.filter((option) =>
                                  option?.label
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                );

                                if (
                                  filtered.length === 0 &&
                                  state.inputValue !== ""
                                ) {
                                  filtered.push({
                                    _id: "65ceab790a582b9f43f4a880",
                                    label: "Other",
                                    value: "other",
                                  });
                                }

                                return filtered;
                              }}
                              getOptionLabel={(string) => {
                                return (
                                  brands.find((brand) => brand._id === string)
                                    ?.label ?? ""
                                );
                              }}
                              value={values.itemBrand}
                              onChange={(e, value) =>
                                setFieldValue("itemBrand", value)
                              }
                              sx={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Brand"
                                  name="itemBrand"
                                  error={Boolean(
                                    touched.itemBrand && errors.itemBrand
                                  )}
                                  helperText={
                                    touched.itemBrand && errors.itemBrand
                                  }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                        // <Grid
                        //   container
                        //   spacing={4}
                        //   sx={{
                        //     flexGrow: 1,
                        //     marginBottom: "1rem",
                        //     marginTop: "-1rem",
                        //   }}
                        // >
                        //   <Grid item xs={12}>
                        //     <Mautocomplete
                        //       onOpen={handleBlur}
                        //       disablePortal
                        //       fullWidth
                        //       id="combo-box-brands"
                        //       options={brands}
                        //       filterOptions={(options, state) => {
                        //         const filtered = options.filter((option) =>
                        //           option.label
                        //             ?.toLowerCase()
                        //             .includes(state.inputValue.toLowerCase())
                        //         );

                        //         if (
                        //           filtered.length === 0 &&
                        //           state.inputValue !== ""
                        //         ) {
                        //           filtered.push({
                        //             _id: "65ceab790a582b9f43f4a880",
                        //             label: "Other",
                        //             value: "other",
                        //           });
                        //         }

                        //         return filtered;
                        //       }}
                        //       value={selectedBrand}
                        //       onChange={(e, value) => {
                        //         setFieldValue("itemBrand", value?._id);
                        //         setSelectedBrand(value);
                        //       }}
                        //       sx={{ width: "100%" }}
                        //       renderInput={(params) => (
                        //         <TextField
                        //           {...params}
                        //           label="Brand"
                        //           name="itemBrand"
                        //           error={Boolean(
                        //             touched.itemBrand && errors.itemBrand
                        //           )}
                        //           helperText={
                        //             touched.itemBrand && errors.itemBrand
                        //           }
                        //         />
                        //       )}
                        //     />
                        //   </Grid>
                        // </Grid>
                      )}
                      {!isThirdPartyEntity() && (
                        <>
                          <Grid container spacing={4} sx={{ flexGrow: 1 }}>
                            {/* <Grid item xs={6}>
                          <TextField
                            required
                            name="itemModel"
                            error={
                              touched.itemModel && Boolean(errors.itemModel)
                            }
                            helperText={touched.itemModel && errors.itemModel}
                            id="model"
                            label="Model"
                            defaultValue=""
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.itemModel}
                            sx={{ width: "100%" }}
                          />
                        </Grid> */}
                            <Grid item xs={12}>
                              <Mautocomplete
                                onOpen={handleBlur}
                                disablePortal
                                fullWidth
                                id="combo-box-year"
                                // options={years.sort(
                                //   (a, b) => parseInt(b.label) - parseInt(a.label)
                                // )}
                                options={years
                                  .map((year) => year.value)
                                  .sort((a, b) => b - a)}
                                value={values.itemYearManufacture}
                                onChange={(e, value) =>
                                  setFieldValue("itemYearManufacture", value)
                                }
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Year Manufactured"
                                    name="itemYearManufacture"
                                    error={Boolean(
                                      touched.itemYearManufacture &&
                                        errors.itemYearManufacture
                                    )}
                                    helperText={
                                      touched.itemYearManufacture &&
                                      errors.itemYearManufacture
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            marginTop={"1rem"}
                            marginBottom={".75rem"}
                          >
                            <TextField
                              name="itemHoursUsed"
                              error={
                                touched.itemHoursUsed &&
                                Boolean(errors.itemHoursUsed)
                              }
                              helperText={
                                touched.itemHoursUsed && errors.itemHoursUsed
                              }
                              required
                              id="itemHoursUsed"
                              label="Hours Used"
                              defaultValue=""
                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment position="start">
                              //       <FaStopwatch />
                              //     </InputAdornment>
                              //   ),
                              // }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.itemHoursUsed}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid
                        container
                        marginTop={"0rem"}
                        columnSpacing={4}
                        rowSpacing={2}
                      >
                        {loadingForFields ? (
                          <Grid item>
                            <Stack alignItems={"center"} direction={"row"}>
                              <CircularProgress
                                size={"1em"}
                                color="secondary"
                              />{" "}
                              <Typography
                                color={"secondary"}
                                marginLeft={"1rem"}
                              >
                                Please Wait...
                              </Typography>
                            </Stack>
                          </Grid>
                        ) : (
                          formFields &&
                          formFields.length > 0 && (
                            <>
                              {formFields.map((field, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={
                                    formFields.length % 2 !== 0 &&
                                    index === formFields.length - 1
                                      ? 12
                                      : 6
                                  }
                                  key={field._id}
                                  // style={{
                                  //   marginBottom:
                                  //     formFields.length % 2 !== 0 &&
                                  //     index === formFields.length - 1 &&
                                  //     "0",
                                  // }}
                                >
                                  {field.type === "select" ? (
                                    <FormikSelect
                                      // value={
                                      //   formRef.current.values[field.formikName]
                                      // }
                                      // onChange={(e) => {
                                      //   formRef.current.values[field.formikName] =
                                      //     e.target.value;
                                      // }}
                                      vallue={
                                        formRef.current.values[field.formikName]
                                      }
                                      key={field._id}
                                      name={field.formikName}
                                      label={field.name}
                                      options={field.options}
                                    />
                                  ) : field.type ===
                                    "autocompleteWithCreateOption" ? (
                                    <FormikAutoCompleteWithCreateOption
                                      setFieldValue={setFieldValue}
                                      key={field._id}
                                      name={field.formikName}
                                      label={field.name}
                                      formRef={formRef}
                                    />
                                  ) : (
                                    <FormikTextField
                                      vallue={
                                        formRef.current.values[field.formikName]
                                      }
                                      // value={
                                      //   formRef.current.values[field.formikName]
                                      // }
                                      // onChange={(e) => {
                                      //   formRef.current.values[field.formikName] =
                                      //     e.target.value;
                                      // }}
                                      key={field._id}
                                      name={field.formikName}
                                      label={field.name}
                                      type="text"
                                    />
                                  )}
                                </Grid>
                              ))}

                              {formRef.current?.values.attachments.map(
                                (attachment, attachmentIndex) => (
                                  <>
                                    <Grid item marginTop={"2rem"}>
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                      >
                                        <Typography
                                          borderBottom={
                                            "2px solid var(--grey-color)"
                                          }
                                          textAlign="left"
                                          sx={{ fontWeight: 700 }}
                                        >
                                          Attachment {attachmentIndex + 1}
                                        </Typography>
                                        <Button
                                          size="small"
                                          onClick={() =>
                                            deleteAttachment(attachmentIndex)
                                          }
                                          color="secondary"
                                        >
                                          <Close color="secondary" />
                                        </Button>
                                        {/* <Close
                                            color="var(--grey-color)"
                                            onClick={deleteAttachment(
                                              attachmentIndex
                                            )}
                                          /> */}
                                      </Stack>
                                    </Grid>
                                    {Object.keys(attachment).map(
                                      (fieldKey, index) => {
                                        // Find the corresponding field in baseStructure to get details like label, type, colSize, etc.
                                        const fieldDetails = baseStructure.find(
                                          (field) =>
                                            field.formikName === fieldKey
                                        );

                                        return (
                                          <>
                                            <Grid
                                              item
                                              xs={12}
                                              sm={fieldDetails?.colSize || 12}
                                              key={`${attachmentIndex}-${index}`}
                                            >
                                              {fieldDetails?.type ===
                                                "select" && (
                                                <FormikSelect
                                                  // defaultValue={
                                                  //   fieldKey ===
                                                  //   "attchSubCategory"
                                                  //     ? {
                                                  //         label:
                                                  //           formRef.current
                                                  //             .values
                                                  //             .attachments[
                                                  //             attachmentIndex
                                                  //           ]?.attchSubCategory,
                                                  //         value:
                                                  //           formRef.current
                                                  //             .values
                                                  //             .attachments[
                                                  //             attachmentIndex
                                                  //           ]?.attchSubCategory,
                                                  //       }
                                                  //     : {
                                                  //         label:
                                                  //           formRef.current
                                                  //             .values
                                                  //             .attachments[
                                                  //             attachmentIndex
                                                  //           ]?.fieldKey,
                                                  //         value:
                                                  //           formRef.current
                                                  //             .values
                                                  //             .attachments[
                                                  //             attachmentIndex
                                                  //           ]?.fieldKey,
                                                  //       }
                                                  // }
                                                  name={`attachments[${attachmentIndex}].${fieldKey}`}
                                                  type={
                                                    fieldDetails?.type || "text"
                                                  }
                                                  label={
                                                    fieldDetails?.name ||
                                                    fieldKey
                                                  }
                                                  value={
                                                    fieldDetails?.name ===
                                                    "Category"
                                                      ? selectedGoal?.label
                                                      : fieldDetails?.name ===
                                                        "Sub-category"
                                                      ? selectedGoal?.label ===
                                                        "Earth Manipulation"
                                                        ? "Attachments"
                                                        : "Lift Attachments"
                                                      : formRef.current.values
                                                          .attachments[
                                                          attachmentIndex
                                                        ][fieldKey]
                                                  }
                                                  disabled={[
                                                    "Category",
                                                    "Sub-category",
                                                  ].includes(
                                                    fieldDetails?.name
                                                  )}
                                                  colSize={
                                                    fieldDetails?.colSize
                                                  }
                                                  rows={fieldDetails?.rows || 1}
                                                  options={
                                                    fieldDetails?.options?.map(
                                                      (opt) => opt.label
                                                    ) || []
                                                  }
                                                />
                                              )}

                                              {fieldDetails?.type ===
                                                "text" && (
                                                <FormikTextField
                                                  name={`attachments[${attachmentIndex}].${fieldKey}`}
                                                  type={
                                                    fieldDetails?.type || "text"
                                                  }
                                                  label={
                                                    fieldDetails?.name ||
                                                    fieldKey
                                                  }
                                                  colSize={
                                                    fieldDetails?.colSize
                                                  }
                                                  rows={fieldDetails?.rows || 1}
                                                  fullWidth
                                                />
                                              )}

                                              {/* <Field
                                                name={`attachments[${attachmentIndex}].${fieldKey}`}
                                                type={
                                                  fieldDetails?.type || "text"
                                                }
                                                placeholder={
                                                  fieldDetails?.name || fieldKey
                                                }
                                                as={
                                                  fieldDetails?.type ===
                                                  "select"
                                                    ? "select"
                                                    : "textarea"
                                                }
                                                rows={fieldDetails?.rows || 1}
                                                fullWidth
                                              >
                                                {fieldDetails?.type ===
                                                  "select" &&
                                                  fieldDetails.options.map(
                                                    (option) => (
                                                      <option
                                                        key={option.value}
                                                        value={option.value}
                                                      >
                                                        {option.label}
                                                      </option>
                                                    )
                                                  )}
                                              </Field>
                                              <ErrorMessage
                                                name={`attachments[${attachmentIndex}].${fieldKey}`}
                                              /> */}
                                            </Grid>
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                )
                              )}
                              {/* </Grid> */}
                              {(selectedGoal?.label === "Earth Manipulation" ||
                                selectedGoal?.label ===
                                  "Lifting People & Material") && (
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={addDynamicAttachment}
                                  >
                                    Add Attachment
                                  </Button>
                                </Grid>
                              )}
                            </>
                          )
                        )}
                      </Grid>
                    </>
                  )}
                  {activeStep + 1 === 2 && (
                    <>
                      <Grid
                        container
                        // gap={0.5}
                        // spacing={3}
                        sx={{
                          flexGrow: 1,
                          padding: 0,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid
                          xs={12}
                          sm={4}
                          md={8}
                          sx={{
                            paddingRight: "1rem",
                            borderRight: "1px solid var(--grey-color)",
                          }}
                        >
                          <Grid
                            container
                            spacing={4}
                            marginTop={"5px"}
                            marginBottom={"1rem"}
                            paddingTop={"10px"}
                          >
                            {!isThirdPartyEntity() && (
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(e) => {
                                        handleChange(e);
                                        setFieldValue("itemAmount", "");
                                      }}
                                      onBlur={handleBlur}
                                      value={values.itemToSell}
                                      name="itemToSell"
                                      checked={values.itemToSell}
                                    />
                                  }
                                  label="Equipment to Sell?"
                                />
                                {values.itemToSell && (
                                  <TextField
                                    required={values.itemToSell ? true : false}
                                    name="itemAmount"
                                    error={
                                      touched.itemAmount &&
                                      Boolean(errors.itemAmount)
                                    }
                                    helperText={
                                      touched.itemAmount && errors.itemAmount
                                    }
                                    id="amount"
                                    label="Amount"
                                    defaultValue=""
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.itemAmount}
                                    sx={{
                                      width: "100%",
                                      margin: "1rem 0",
                                    }}
                                  />
                                )}
                                {/* <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e) => {
                                          handleChange(e);
                                          setFieldValue("itemDailyPrice", "");
                                          setFieldValue("itemWeeklyPrice", "");
                                          setFieldValue("itemMonthlyPrice", "");
                                          setFieldValue(
                                            "itemCleaningPrice",
                                            ""
                                          );
                                          setFieldValue("itemFuelPrice", "");
                                          setFieldValue(
                                            "itemMaintenancePrice",
                                            ""
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        value={values.itemForRent}
                                        name="itemForRent"
                                        checked={values.itemForRent}
                                      />
                                    }
                                    label="Item for Rent?"
                                  />
                                </Grid> */}
                                {/* {((errors.itemToSell && touched.itemToSell) ||
                                  (errors.itemForRent &&
                                    touched.itemForRent)) && (
                                  <FormHelperText style={{ color: "#ff4b4b" }}>
                                    {errors.itemToSell}
                                  </FormHelperText>
                                )} */}
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={12} marginTop={"-1rem"}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e, val) => {
                                    // handleChange(e);
                                    setFieldValue("itemForRent", val);
                                    setFieldValue("itemDailyPrice", "");
                                    setFieldValue("itemWeeklyPrice", "");
                                    setFieldValue("itemMonthlyPrice", "");
                                    setFieldValue("itemCleaningPrice", "");
                                    setFieldValue("itemFuelPrice", "");
                                    setFieldValue("itemMaintenancePrice", "");
                                  }}
                                  onBlur={handleBlur}
                                  checked={values.itemForRent}
                                  name="itemForRent"
                                  disabled={isThirdPartyEntity() || false}
                                />
                              }
                              label="Equipment for Rent?"
                            />
                          </Grid>
                          {values.itemForRent && (
                            <>
                              <Grid
                                container
                                spacing={4}
                                marginTop={"-1rem"}
                                // marginBottom={"1rem"}
                              >
                                <Grid item xs={4} margin={"0rem"}>
                                  <TextField
                                    name="itemDailyPrice"
                                    error={
                                      (touched.itemDailyPrice &&
                                        Boolean(errors.itemDailyPrice)) ||
                                      Boolean(errors.itemRent)
                                    }
                                    helperText={
                                      (touched.itemDailyPrice &&
                                        errors.itemDailyPrice) ||
                                      errors.itemRent
                                    }
                                    id="dailyPrice"
                                    label="Daily Price"
                                    defaultValue=""
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.itemDailyPrice}
                                    sx={{ width: "100%" }}
                                  />
                                </Grid>

                                <Grid item xs={4} margin={"0rem"}>
                                  <TextField
                                    name="itemWeeklyPrice"
                                    error={
                                      (touched.itemWeeklyPrice &&
                                        Boolean(errors.itemWeeklyPrice)) ||
                                      Boolean(errors.itemRent)
                                    }
                                    helperText={
                                      (touched.itemWeeklyPrice &&
                                        errors.itemWeeklyPrice) ||
                                      Boolean(errors.itemRent)
                                    }
                                    id="itemWeeklyPrice"
                                    label="Weekly Price"
                                    defaultValue=""
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.itemWeeklyPrice}
                                    sx={{ width: "100%" }}
                                  />
                                </Grid>

                                <Grid item xs={4} margin={"0rem"} >
                                  <TextField
                                    name="itemMonthlyPrice"
                                    error={
                                      (touched.itemMonthlyPrice &&
                                        Boolean(errors.itemMonthlyPrice)) ||
                                      Boolean(errors.itemRent)
                                    }
                                    helperText={
                                      (touched.itemMonthlyPrice &&
                                        errors.itemMonthlyPrice) ||
                                      Boolean(errors.itemRent)
                                    }
                                    id="itemMonthlyPrice"
                                    label="Monthly Price"
                                    defaultValue=""
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.itemMonthlyPrice}
                                    sx={{ width: "100%" }}
                                  />
                                </Grid>
                              </Grid>
                              {!isThirdPartyEntity() && (
                                <>
                                <Grid
                                  container
                                  spacing={4}
                                  marginTop={"-0.5rem"}
                                  marginBottom={"1rem"}
                                >
                                  <Grid item xs={4} margin={"0rem"}>
                                    <TextField
                                      name="itemCleaningPrice"
                                      error={
                                        touched.itemCleaningPrice &&
                                        Boolean(errors.itemCleaningPrice)
                                      }
                                      helperText={
                                        touched.itemCleaningPrice &&
                                        errors.itemCleaningPrice
                                      }
                                      id="itemCleaningPrice"
                                      label="Cleaning Fee"
                                      defaultValue=""
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            <DarkToolTip
                                              arrow
                                              className="gd-tooltip"
                                              title="This is the cost to wash down the equipment to be rent ready condition"
                                              placement="bottom"
                                            >
                                              <InfoOutlined fontSize="12px" />
                                            </DarkToolTip>
                                          </InputAdornment>
                                        ),
                                      }}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.itemCleaningPrice}
                                      sx={{ width: "100%" }}
                                    />
                                  </Grid>
                                  <Grid item xs={4} margin={"0rem"}>
                                    <TextField
                                      name="itemFuelPrice"
                                      error={
                                        touched.itemFuelPrice &&
                                        Boolean(errors.itemFuelPrice)
                                      }
                                      helperText={
                                        touched.itemFuelPrice &&
                                        errors.itemFuelPrice
                                      }
                                      id="itemFuelPrice"
                                      label="Fuel Fee"
                                      defaultValue=""
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="start"
                                            sx={{ cursor: "pointer" }}
                                          >
                                            <DarkToolTip
                                              arrow
                                              className="gd-tooltip"
                                              title="This is for a full tank of fuel, where the renter will be able to bring back the machine at any fill level"
                                              placement="bottom"
                                            >
                                              <InfoOutlined fontSize="12px" />
                                            </DarkToolTip>
                                          </InputAdornment>
                                        ),
                                      }}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.itemFuelPrice}
                                      sx={{ width: "100%" }}
                                    />
                                  </Grid>
                                  <Grid item xs={4} margin={"0rem"}>
                                    <TextField
                                      name="itemMaintenancePrice"
                                      error={
                                        touched.itemMaintenancePrice &&
                                        Boolean(errors.itemMaintenancePrice)
                                      }
                                      helperText={
                                        touched.itemMaintenancePrice &&
                                        errors.itemMaintenancePrice
                                      }
                                      id="itemMaintenancePrice"
                                      label="Maintenance Fee"
                                      defaultValue=""
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            <DarkToolTip
                                              arrow
                                              className="gd-tooltip"
                                              title="This is the cost at every 250 hour increments for general maintenance (oil and filter changes). Based on that expense and the rental period Dizel will calculate the total cost for the duration of the rental period."
                                              placement="bottom"
                                            >
                                              <InfoOutlined fontSize="12px" />
                                            </DarkToolTip>
                                          </InputAdornment>
                                        ),
                                      }}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.itemMaintenancePrice}
                                      sx={{ width: "100%" }}
                                    />
                                  </Grid>
                                </Grid>
                                 <Grid
                                 xs={12}
                                 marginTop={".5rem"}
                                 marginBottom={"1rem"}
                               >
                                 <Mautocomplete
                                   onOpen={handleBlur}
                                   disablePortal
                                   fullWidth
                                   id="environmental-fee-combo-box"
                                   options={environmentalFeeOptions}
                                   onChange={(e, value) => {
                                     setFieldValue(
                                       "itemEnvironmentalFee",
                                       value?.value
                                     );
                                     setSelectedEnvironmentalFee(value);
                                   }}
                                   value={selectedEnvironmentalFee}
                                   sx={{ width: "100%", marginTop: 0 }}
                                   renderOption={(props, option) => (
                                     <li {...props} key={option.value}>
                                       <div
                                         style={{
                                           display: "flex",
                                           justifyContent: "space-between",
                                           alignItems: "center",
                                           width: "100%",
                                         }}
                                       >
                                         <span>{option.label}</span>
                                         {option.value === "2" && (
                                           <span
                                             style={{
                                               fontSize: "0.85rem",
                                               color: "var(--grey-color)",
                                             }}
                                           >
                                             (Recommended)
                                           </span>
                                         )}
                                       </div>
                                     </li>
                                   )}
                                   renderInput={(params) => (
                                     <TextField
                                       {...params}
                                       label="Environmental Fee"
                                       name="itemEnvironmentalFee"
                                       error={Boolean(
                                         touched.itemEnvironmentalFee &&
                                           errors.itemEnvironmentalFee
                                       )}
                                       helperText={
                                         touched.itemEnvironmentalFee &&
                                         errors.itemEnvironmentalFee
                                       }
                                       required
                                       InputProps={{
                                         ...params.InputProps,
                                         endAdornment: (
                                           <>
                                             {/* Custom tooltip adornment */}
                                             <DarkToolTip
                                               arrow
                                               className="gd-tooltip"
                                               title="Environmental fees are additional charges imposed by equipment rental companies to cover costs associated with environmental compliance and waste management. These fees are designed to offset expenses related to the proper handling, disposal, and recycling of materials generated during the use of rented equipment."
                                               placement="bottom"
                                             >
                                               <InfoOutlined
                                                 fontSize="small"
                                                 sx={{
                                                   cursor: "pointer",
                                                   marginRight: "8px",
                                                   color: "rgb(0 0 0 / 54%)",
                                                 }}
                                               />
                                             </DarkToolTip>

                                             {/* Retain existing endAdornment */}
                                             {params.InputProps.endAdornment}
                                           </>
                                         ),
                                       }}
                                     />
                                   )}
                                 />
                               </Grid>
                               </>
                              )}
                            </>
                          )}
                          {!isThirdPartyEntity() && (
                            <Grid item xs={12} marginTop={0}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e, val) => {
                                      setFieldValue(
                                        "chargesIncurredBySeller",
                                        val
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    checked={values.chargesIncurredBySeller}
                                    name="chargesIncurredBySeller"
                                  />
                                }
                                label="I as the Asset Owner will incur total platform fees of 18%."
                              />
                            </Grid>
                          )}

                          {!isThirdPartyEntity() && (
                            <Grid container spacing={4} marginTop={"-1rem"}>
                              <Grid item xs={6}>
                                <TextField
                                  name="itemTransportationPrice"
                                  error={
                                    touched.itemTransportationPrice &&
                                    Boolean(errors.itemTransportationPrice)
                                  }
                                  helperText={
                                    touched.itemTransportationPrice &&
                                    Boolean(errors.itemTransportationPrice)
                                      ? errors.itemTransportationPrice
                                      : "Enter Estimated cost of delivery up to 25 miles "
                                  }
                                  id="itemTransportationPrice"
                                  label="Transportation fees (upto 25 miles)"
                                  // defaultValue=""
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <DarkToolTip
                                          arrow
                                          className="gd-tooltip"
                                          title="If the Equipment Owner doesn’t pick up the equipment on the scheduled date, Dizel and the Renter are not responsible for keeping it safe, maintaining it, or protecting it in any way. Any problems, costs, or damages that happen after the pick-up date—like theft, damage, loss, extra use, or anything else—are not their responsibility. This rule follows the laws of the rental agreement’s location."
                                          placement="bottom"
                                        >
                                          <InfoOutlined fontSize="12px" />
                                        </DarkToolTip>
                                      </InputAdornment>
                                    ),
                                  }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.itemTransportationPrice}
                                  sx={{ width: "100%" }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  name="itemTransportationPrice2"
                                  error={
                                    touched.itemTransportationPrice2 &&
                                    Boolean(errors.itemTransportationPrice2)
                                  }
                                  helperText={
                                    touched.itemTransportationPrice2 &&
                                    Boolean(errors.itemTransportationPrice2)
                                      ? errors.itemTransportationPrice2
                                      : "Enter Estimated cost of additional miles"
                                  }
                                  id="itemTransportationPrice2"
                                  label="Transportation fees (per mile after 25 miles)"
                                  // InputProps={{
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <DarkToolTip
                                          arrow
                                          className="gd-tooltip"
                                          title="If the Equipment Owner doesn’t pick up the equipment on the scheduled date, Dizel and the Renter are not responsible for keeping it safe, maintaining it, or protecting it in any way. Any problems, costs, or damages that happen after the pick-up date—like theft, damage, loss, extra use, or anything else—are not their responsibility. This rule follows the laws of the rental agreement’s location."
                                          placement="bottom"
                                        >
                                          <InfoOutlined fontSize="12px" />
                                        </DarkToolTip>
                                      </InputAdornment>
                                    ),
                                  }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.itemTransportationPrice2}
                                  sx={{ width: "100%" }}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                        {/* LIVE PREVIEW */}
                        <Grid xs={12} md={4} sx={{ paddingLeft: "1rem" }}>
                          <Paper
                            data-aos="fade-in"
                            elevation={0}
                            style={{
                              padding: "1rem",
                              paddingBottom: "1rem",
                              background: "var(--black-color)",
                              color: "var(--white-color)",
                              // minHeight: "100vh",
                              // border: "1px solid #ddd",
                              borderRadius: "10px",
                            }}
                          >
                            <Typography
                              variant="h4"
                              fontWeight={700}
                              margin={"1rem"}
                              textAlign={
                                values.itemForRent || values.itemToSell
                                  ? "center"
                                  : "center"
                              }
                              color="var(--grey-color)"
                            >
                              Preview to Buyers
                            </Typography>
                            {(values.itemForRent || values.itemToSell) && (
                              <Typography
                                data-aos="fade-in"
                                data-aos-duration="500"
                                sx={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  letterSpacing: 1.2,
                                  color: "var(--white-color)",
                                  margin: "0 1rem",
                                  borderBottomColor: "var(--grey-color)",
                                  textAlign: "center",
                                }}
                              >
                                Equipment Type :{" "}
                                {values.itemForRent && values.itemToSell
                                  ? "Rent & Buy"
                                  : values.itemToSell
                                  ? "Buy"
                                  : values.itemForRent
                                  ? "Rent"
                                  : ""}
                              </Typography>
                            )}

                            {values.itemToSell &&
                              !values.itemForRent &&
                              (values.itemAmount ||
                                values.itemTransportationPrice) && (
                                <OnlySell
                                  data-aos="fade-in"
                                  data-aos-duration="500"
                                  values={values}
                                  calculateTotalForPreviewForBoth={
                                    calculateTotalForPreviewForBoth
                                  }
                                  platformFees={platformFees}
                                />
                              )}

                            {!values.itemToSell &&
                              values.itemForRent &&
                              (values.itemDailyPrice ||
                                values.itemWeeklyPrice ||
                                values.itemMonthlyPrice ||
                                values.itemCleaningPrice ||
                                values.itemFuelPrice ||
                                values.itemMaintenancePrice ||
                                values.itemTransportationPrice) && (
                                <OnlyRent
                                  values={values}
                                  calculateTotalForPreviewForBoth={
                                    calculateTotalForPreviewForBoth
                                  }
                                />
                              )}

                            {values.itemForRent && values.itemToSell && (
                              <>
                                {(values.itemAmount ||
                                  values.itemTransportationPrice) && (
                                  <>
                                    <Typography
                                      data-aos="fade-in"
                                      data-aos-duration="500"
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: "12px",
                                        textTransform: "uppercase",
                                        letterSpacing: 1.5,
                                        color: "var(--grey-color)",
                                        margin: "1rem",
                                        marginBottom: 0,
                                        borderBottomColor: "var(--grey-color)",
                                      }}
                                    >
                                      Buy Estimate
                                    </Typography>
                                    <OnlySell
                                      values={values}
                                      calculateTotalForPreviewForBoth={
                                        calculateTotalForPreviewForBoth
                                      }
                                    />
                                  </>
                                )}
                                {(values.itemDailyPrice ||
                                  values.itemWeeklyPrice ||
                                  values.itemMonthlyPrice ||
                                  values.itemCleaningPrice ||
                                  values.itemFuelPrice ||
                                  values.itemMaintenancePrice ||
                                  values.itemTransportationPrice) && (
                                  <>
                                    <br />
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: "12px",
                                        textTransform: "uppercase",
                                        letterSpacing: 1.5,
                                        color: "var(--grey-color)",
                                        margin: "1rem",
                                        marginBottom: 0,
                                      }}
                                    >
                                      Rent Estimate
                                    </Typography>

                                    <OnlyRent
                                      values={values}
                                      calculateTotalForPreviewForBoth={
                                        calculateTotalForPreviewForBoth
                                      }
                                    />
                                  </>
                                )}
                              </>
                            )}

                            {/* {values.itemToSell && values.itemForRent} */}
                          </Paper>

                          {/* <Paper
                elevation={3}
                style={{
                  marginTop: "2rem",
                  padding: "1rem 2rem",
                  paddingBottom: "6.5rem",
                  borderRadius: "10px",
                }}
              >
                <h2>Payment Information</h2>
                <form>
                  <TextField label="Full Name" fullWidth margin="normal" />
                  <TextField label="Email" fullWidth margin="normal" />
                  <TextField
                    label="Address"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                  />
                  <TextField
                    label="Credit Card Number"
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Expiration Date"
                    fullWidth
                    margin="normal"
                  />
                  <TextField label="CVV" fullWidth margin="normal" />
                  <Button variant="contained" color="primary" fullWidth>
                    Submit
                  </Button>
                </form>
              </Paper> */}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {activeStep + 1 === 3 && (
                    <>
                      <Typography
                        variant="h3"
                        fontWeight={700}
                        className="mont-title"
                        marginBottom={2}
                      >
                        Location
                      </Typography>
                      <Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                // handleChange(e);
                                console.log("YELLOYELLO", e.target.value);
                                if (autopopulate) {
                                  setAutopopulate(false);
                                  depopulateLocation();
                                } else {
                                  setAutopopulate(true);
                                  populateLocation();
                                }

                                // setFieldValue("itemAvailability", "");
                                // else
                                // setFieldValue("itemAvailability", "always");
                              }}
                              // onBlur={handleBlur}
                              checked={autopopulate}
                              name="autopopulate"
                            />
                          }
                          label="Auto populate address from Account"
                        />
                      </Box>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          {!isThirdPartyEntity() ? (
                            <GooglePlacesAutocomplete
                              selectProps={{
                                ref: selectRef,
                                value: fullLocation,
                                onChange: (val) => {
                                  console.log(val);
                                  if (val) {
                                    geocodeByAddress(val.label)
                                      .then((results) => {
                                        console.log(results);
                                        let zipCode =
                                          results[0].address_components.find(
                                            (zp) =>
                                              zp.types.includes("postal_code")
                                          );
                                        if (zipCode) {
                                          console.log(
                                            "zipcode",
                                            zipCode.long_name
                                          );
                                          setFieldValue(
                                            "itemZipCode",
                                            zipCode.long_name
                                          );
                                        } else {
                                          setFieldValue("itemZipCode", "");
                                        }
                                        //previous extra
                                        return getLatLng(results[0]);
                                        //
                                      })
                                      .then(({ lat, lng }) => {
                                        console.log(
                                          "Successfully got latitude and longitude",
                                          { lat, lng }
                                        );
                                        setLangLong({ lng, lat });
                                      });

                                    if (val.value.terms.length < 4) {
                                      toast.info(
                                        "Please Enter Complete Address",
                                        toastOption
                                      );
                                      return;
                                    }

                                    setFullLocation(val);

                                    setInputValue(
                                      val ? val.value.description : ""
                                    );

                                    const terms = val.value.terms;

                                    // Extract city and state based on typical indexing in terms array
                                    const streetExtracted =
                                      terms.length === 4
                                        ? terms
                                            .slice(0, 1)
                                            .map((term) => term.value)
                                        : terms
                                            .slice(0, 2)
                                            .map((term) => term.value);
                                    const cityExtracted =
                                      terms.length === 4
                                        ? terms[1].value
                                        : terms[2].value; // Third item
                                    const stateExtracted =
                                      terms.length === 4
                                        ? terms[2].value
                                        : terms[3].value; // Fourth item

                                    setFieldValue(
                                      "itemLocation",
                                      streetExtracted.join(", ")
                                    );
                                    setFieldValue("itemCity", cityExtracted);
                                    console.log("itemCity", cityExtracted);

                                    setFieldValue("itemState", stateExtracted);
                                    console.log("itemState", stateExtracted);
                                  }
                                },
                                styles: {
                                  input: (provided) => ({
                                    ...provided,
                                    padding: 12,
                                    border: "none",
                                    background: "transparent",
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                  }),
                                  clearIndicator: (base) => ({
                                    ...base,
                                    display: "none", // Hides the clear indicator
                                  }),
                                  // indicatorSeparator: (provided) => ({
                                  //   ...provided,
                                  //   display: "none",
                                  // }),
                                },
                                inputValue: inputValue,

                                onFocus: onFocus,
                                isClearable: true,
                                onInputChange: onInputChange,
                                controlShouldRenderValue: false,
                                components: { SingleValue, Input },
                                placeholder: "Enter Location",
                              }}
                              apiKey="AIzaSyAw_j2KY4CDtksQcd6JexS3J1xt4BdbnGQ"
                              apiOptions={{ region: "US" }}
                            />
                          ) : (
                            <GooglePlacesAutocomplete
                              selectProps={{
                                ref: selectRef,
                                value: fullLocation,
                                styles: {
                                  input: (provided) => ({
                                    ...provided,
                                    padding: 10,
                                    border: "none",
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    zIndex: 999,
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                  }),
                                  clearIndicator: (base) => ({
                                    ...base,
                                    display: "none", // Hides the clear indicator
                                  }),
                                },
                                // onFocus: onFocus,
                                isClearable: true,
                                onInputChange: onInputChange,
                                controlShouldRenderValue: false,
                                components: { SingleValue, Input },
                                placeholder: "Search for a city and state",
                                inputValue: inputValue,
                                onChange: (val) => {
                                  setAutopopulate(false);
                                  if (val) {
                                    geocodeByAddress(val.label)
                                      .then((results) => {
                                        console.log(results);
                                        let zipCode =
                                          results[0].address_components.find(
                                            (zp) =>
                                              zp.types.includes("postal_code")
                                          );
                                        if (zipCode) {
                                          console.log(
                                            "zipcode",
                                            zipCode.long_name
                                          );
                                          setFieldValue(
                                            "itemZipCode",
                                            zipCode.long_name
                                          );
                                        } else {
                                          setFieldValue("itemZipCode", "");
                                        }
                                        //previous extra
                                        return getLatLng(results[0]);
                                        //
                                      })
                                      .then(({ lat, lng }) => {
                                        console.log(
                                          "Successfully got latitude and longitude",
                                          { lat, lng }
                                        );
                                        setLangLong({ lng, lat });
                                      });

                                    if (val.value.terms.length < 4) {
                                      toast.info(
                                        "Please Enter Complete Address",
                                        toastOption
                                      );
                                      return;
                                    }

                                    setFullLocation(val);

                                    setInputValue(
                                      val ? val.value.description : ""
                                    );

                                    const terms = val.value.terms;

                                    // Extract city and state based on typical indexing in terms array
                                    const streetExtracted =
                                      terms.length === 4
                                        ? terms
                                            .slice(0, 1)
                                            .map((term) => term.value)
                                        : terms
                                            .slice(0, 2)
                                            .map((term) => term.value);
                                    const cityExtracted =
                                      terms.length === 4
                                        ? terms[1].value
                                        : terms[2].value; // Third item
                                    const stateExtracted =
                                      terms.length === 4
                                        ? terms[2].value
                                        : terms[3].value; // Fourth item

                                    setFieldValue(
                                      "itemLocation",
                                      streetExtracted.join(", ")
                                    );
                                    setFieldValue("itemCity", cityExtracted);
                                    console.log("itemCity", cityExtracted);

                                    setFieldValue("itemState", stateExtracted);
                                    console.log("itemState", stateExtracted);
                                  }
                                  // const addressComponents = value?.value?.terms;
                                  // const streetExtracted = addressComponents
                                  //   .slice(0, 1)
                                  //   .map((term) => term.value);
                                  // const cityExtracted =
                                  //   addressComponents.length > 2
                                  //     ? addressComponents[2].value
                                  //     : null; // Third item
                                  // const stateExtracted =
                                  //   addressComponents.length > 3
                                  //     ? addressComponents[3].value
                                  //     : null; // Fourth item
                                  // setFieldValue(
                                  //   "itemLocation",
                                  //   streetExtracted.join(", ")
                                  // );
                                  // setFieldValue("itemCity", cityExtracted);
                                  // // console.log("itemCity", cityExtracted);
                                  // setFieldValue("itemState", stateExtracted);
                                  // // console.log("itemState", stateExtracted);
                                  // setInputValue(streetExtracted);
                                  // setFullLocation(value.value);
                                },
                              }}
                              apiKey="AIzaSyAw_j2KY4CDtksQcd6JexS3J1xt4BdbnGQ"
                              autocompletionRequest={{
                                types: ["(regions)"], // Includes both cities and states
                                componentRestrictions: { country: "us" }, // Restrict to the US
                              }}
                              fields={["address_components", "geometry"]}
                            />
                          )}
                          {errors.itemLocation && touched.itemLocation && (
                            <FormHelperText style={{ color: "#ff4b4b" }}>
                              {errors.itemLocation.label}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                      {!isThirdPartyEntity() && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "2rem",
                            }}
                          >
                            <Typography
                              variant="h3"
                              fontWeight={700}
                              className="mont-title"
                              marginBottom={1}
                            >
                              Rent Period &nbsp;&nbsp;&nbsp;
                            </Typography>
                          </Box>
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    handleChange(e);
                                    if (e.target.value === true)
                                      setFieldValue("itemAvailability", "");
                                    else
                                      setFieldValue(
                                        "itemAvailability",
                                        "always"
                                      );
                                  }}
                                  onBlur={handleBlur}
                                  checked={values.itemToHold}
                                  name="itemToHold"
                                />
                              }
                              label="Hold (choose availability later)"
                            />
                          </Box>
                          {!values.itemToHold && (
                            <FormControl component={"fieldset"}>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="itemAvailability"
                                value={values.itemAvailability}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (
                                    itemLeaseFields &&
                                    itemLeaseFields.length > 0 &&
                                    e.target.value === "always"
                                  ) {
                                    setItemLeaseFields([
                                      {
                                        initVal: { from: "", to: "" },
                                      },
                                    ]);
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value={"always"}
                                  control={<Radio />}
                                  label="Always Available"
                                />
                                <FormControlLabel
                                  value={"except"}
                                  control={
                                    <Radio
                                    // checked={values.itemAvailability === "except"}
                                    />
                                  }
                                  label="Always Available, except:"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                          {values.itemAvailability === "except" && (
                            <Box>
                              {/* {Array.from({ length: leaseCounts }, (_, index) => ( */}
                              {itemLeaseFields.map((field, index) => (
                                <Box
                                  key={index}
                                  margin={"1.5rem auto"}
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <DateRangePicker
                                    style={{
                                      width: index > 0 ? "96%" : "100%",
                                    }}
                                    size="large"
                                    placeholder="Select Date Range : From ~ To"
                                    disabledDate={beforeToday()}
                                    // onOk={(newValue) => {
                                    //   setValue(newValue);
                                    //   setFieldValue("itemLease", [
                                    //     ...values.itemLease,
                                    //     {
                                    //       from: newValue[0],
                                    //       to: newValue[1],
                                    //     },
                                    //   ]);
                                    // }}
                                    onOk={(newValue) =>
                                      handleDateRangeSelectt(index, newValue)
                                    }
                                    value={field.initVal}
                                    // value={value}
                                  />
                                  {index > 0 && (
                                    <span
                                      style={{
                                        marginTop: "5px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Clear
                                        // onClick={() => {
                                        //   const updatedLease = [...values.itemLease];
                                        //   updatedLease.splice(index, 1);
                                        //   setFieldValue("itemLease", updatedLease);
                                        //   console.log("valuessss", values.itemLease);
                                        // }}
                                        onClick={() => handleRemoveField(index)}
                                      />
                                    </span>
                                  )}
                                </Box>
                              ))}
                              <Box sx={{ position: "relative" }}>
                                <Button
                                  variant="outlined"
                                  // sx={{
                                  //   position: "absolute",
                                  //   right: 0,
                                  //   bottom: ".5rem",
                                  // }}
                                  // onClick={() => {setLeaseCounts((prev) => prev + 1); setValue([...value, { initVal: [null, null] }]);}}
                                  onClick={handleAddField}
                                >
                                  Add More
                                </Button>
                              </Box>
                            </Box>
                          )}

                          {/* {
                    values.itemForRent && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "2rem",
                          }}
                        >
                          <Typography
                            variant="h3"
                            fontWeight={700}
                            className="mont-title"
                            marginBottom={2}
                          >
                            Cancellation Policy
                          </Typography>
                        </Box>
                        <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          // variant="h3"
                          // fontWeight={700}
                          // className="mont-title"
                          marginBottom={1}
                        >
                          Default 3 Days Before Rental Period Start
                        </Typography>
                      </Box>
                      <Typography
                        variant="h3"
                        fontWeight={"700"}
                        // textAlign={"center"}
                        color={"var(--dark-grey-color)"}
                        my={3}
                      >
                        OR
                      </Typography>
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  handleChange(e);
                                  if (e.target.value === true)
                                    setFieldValue("cancellationDate", "3");
                                  else setFieldValue("cancellationDate", '3');
                                }}
                                onBlur={handleBlur}
                                checked={values.cancellation}
                                name="cancellation"
                              />
                            }
                            label="Custom Cancellation Policy"
                          />
                        </Box>
                      </>
                    )
                  } */}

                          {values.itemForRent && (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginTop: "2rem",
                                }}
                              >
                                <Typography
                                  variant="h3"
                                  fontWeight={700}
                                  className="mont-title"
                                  marginBottom={2}
                                >
                                  Cancellation Policy
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <FormControl component={"fieldset"}>
                                  {/* <FormLabel
                          component="legend"
                          id="demo-row-radio-buttons-group-label"
                        >
                          Availability
                        </FormLabel> */}
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="cancellation"
                                    value={values.cancellation}
                                    onChange={(e) => {
                                      const newValue =
                                        e.target.value === "true";
                                      handleChange({
                                        target: {
                                          name: "cancellation",
                                          value: newValue,
                                        },
                                      });
                                    }}
                                  >
                                    <FormControlLabel
                                      value="false"
                                      control={<Radio />}
                                      label="Default (72 hours from booking date)"
                                    />
                                    <FormControlLabel
                                      value="true"
                                      control={<Radio />}
                                      label="Specific (choose cancellation period)"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Box>
                            </>
                          )}

                          {values.cancellation && (
                            <Box
                              margin={"1rem auto"}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <TextField
                                name="cancellationDate"
                                error={
                                  touched.cancellationDate &&
                                  Boolean(errors.cancellationDate)
                                }
                                helperText={
                                  touched.cancellationDate &&
                                  errors.cancellationDate
                                }
                                required
                                id="cancellationDate"
                                label="Choose cancellation period (days before rental start)"
                                defaultValue=""
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FaStopwatch />
                                    </InputAdornment>
                                  ),
                                }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.cancellationDate}
                                sx={{ width: "100%" }}
                              />
                            </Box>
                          )}
                        </>
                      )}

                      <Box
                        margin={"2rem auto"}
                        display={"flex"}
                        justifyContent={"center"}
                        color="secondary"
                        gap={2}
                      >
                        <Button
                          variant="contained"
                          onClick={() => delist()}
                          disabled={
                            !steps.includes("Information")
                              ? activeStep === 2
                              : activeStep === 3
                          }
                          sx={{
                            padding: ".5rem 4rem",
                            textAlign: "center",
                            background: "var(--secondary-color)",
                            "&: hover": {
                              background: "var(--grey-color)",
                              color: "var(--black-color)",
                            },
                            minWidth: 180,
                          }}
                        >
                          Delist
                        </Button>
                        {/* <Button variant="outlined" >Save as Draft</Button> */}
                        {itemStatus === "loading" ? (
                          <LoadingButton
                            loading
                            loadingPosition="end"
                            endIcon={<Save />}
                            variant="outlined"
                            disabled={true}
                          >
                            <span>Please Wait</span>
                          </LoadingButton>
                        ) : (
                          <Button
                            color="secondary"
                            variant="contained"
                            sx={{ minWidth: 180 }}
                            type="submit"
                            disabled={itemStatus === "loading" ? true : false}
                          >
                            {itemStatus === "loading"
                              ? "Please Wait"
                              : "Update"}
                          </Button>
                        )}
                      </Box>
                    </>
                  )}
                </>
                {activeStep + 1 !== 3 && (
                  <Stack direction={"row"} justifyContent={"center"} gap={2}>
                    <Button
                      variant="contained"
                      onClick={() => delist()}
                      disabled={
                        !steps.includes("Information")
                          ? activeStep === 2
                          : activeStep === 3
                      }
                      sx={{
                        mt: 4,
                        padding: ".5rem 4rem",
                        textAlign: "center",
                        background: "var(--secondary-color)",
                        "&: hover": {
                          background: "var(--grey-color)",
                          color: "var(--black-color)",
                        },
                      }}
                    >
                      Delist
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleNext(values)}
                      disabled={
                        !steps.includes("Information")
                          ? activeStep === 2
                          : activeStep === 3
                      }
                      sx={{
                        mt: 4,
                        padding: ".5rem 4rem",
                        textAlign: "center",
                        background: "var(--secondary-color)",
                        "&: hover": {
                          background: "var(--grey-color)",
                          color: "var(--black-color)",
                        },
                      }}
                    >
                      Next
                    </Button>
                  </Stack>
                )}
                {/* </div>
                </div> */}
              </form>
            </Container>
          );
        }}
      />
    </div>
  );
};

const OnlySell = ({
  values,
  calculateTotalForPreview,
  calculateTotalForPreviewForBoth,
  platformFees,
}) => {
  return (
    <>
      <TableContainer
        data-aos="fade-up"
        data-aos-duration="500"
        sx={{ margin: "1rem 0" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 700,
                  fontSize: "12px",
                  textTransform: "uppercase",
                  letterSpacing: 1.5,
                  color: "var(--grey-color)",

                  borderBottomColor: "var(--grey-color)",
                }}
              >
                Description
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: 700,
                  fontSize: "12px",
                  textTransform: "uppercase",
                  letterSpacing: 1.5,
                  color: "var(--grey-color)",

                  borderBottomColor: "var(--grey-color)",
                }}
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow></TableRow>
            {values.itemAmount && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Amount
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemAmount).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            )}
            {values.itemTransportationPrice && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Transportation Fees
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemTransportationPrice).toLocaleString(
                    "en-US",
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )}
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell
                sx={{
                  color: "var(--white-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                }}
              >
                Platform Fees
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={"var(--grey-color)"}
                  marginLeft={".3rem"}
                >
                  (4%)
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  color: "var(--white-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                }}
                align="right"
              >
                {`$${parseFloat(
                  values.itemAmount
                    ? Number(((values.itemAmount * 4) / 100).toFixed(2))
                    : 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} `}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "700",
                  textTransform: "uppercase",
                  letterSpacing: 1.5,
                  color: "var(--white-color)",
                  border: "none",
                  borderTop: "1px solid var(--grey-color)",
                  borderBottom: "none",
                }}
                align="left"
              >
                Total{" "}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "700",
                  border: "none",
                  borderTop: "1px solid var(--grey-color)",
                  borderBottom: "none",
                  color: "var(--white-color)",
                  position: "relative",
                }}
                align="right"
              >
                $
                {parseFloat(
                  calculateTotalForPreviewForBoth("buy")
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </TableCell>
            </TableRow>

            <TableRow
              style={{
                color: "var(--secondary-color)",
              }}
            >
              <TableCell
                sx={{
                  color: "var(--secondary-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
              >
                Dizel
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={"var(--secondary-color)"}
                  marginLeft={".3rem"}
                >
                  (4%)
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  color: "var(--secondary-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
                align="right"
              >
                $
                {`${parseFloat(
                  values.itemAmount ? Number((values.itemAmount * 4) / 100) : 0
                ).toLocaleString("en-US")} `}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  color: "var(--secondary-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
              >
                Earned By You
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={"var(--secondary-color)"}
                  marginLeft={".3rem"}
                >
                  (96%)
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  color: "var(--secondary-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
                align="right"
              >
                $
                {`${parseFloat(
                  parseFloat(calculateTotalForPreviewForBoth("buy")) -
                    parseFloat(
                      values.itemAmount
                        ? Number(((values.itemAmount * 4) / 100).toFixed(2))
                        : 0
                    )
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const OnlyRent = ({
  values,
  calculateTotalForPreview,
  calculateTotalForPreviewForBoth,
}) => {
  return (
    <>
      <TableContainer
        data-aos="fade-up"
        data-aos-duration="500"
        sx={{ margin: "1rem 0" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 700,
                  fontSize: "12px",
                  textTransform: "uppercase",
                  letterSpacing: 1.5,
                  color: "var(--grey-color)",

                  borderBottomColor: "var(--grey-color)",
                }}
              >
                Description
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: 700,
                  fontSize: "12px",
                  textTransform: "uppercase",
                  letterSpacing: 1.5,
                  color: "var(--grey-color)",

                  borderBottomColor: "var(--grey-color)",
                }}
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow></TableRow>
            {values.itemWeeklyPrice ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Weekly Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemWeeklyPrice).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            ) : values.itemDailyPrice ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Weekly Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemDailyPrice * 7).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            ) : values.itemWeeklyPrice ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Weekly Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemMonthlyPrice / 4).toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  )}
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
            {/* {values.itemDailyPrice &&
              !values.itemWeeklyPrice &&
              !values.itemMonthlyPrice && } */}
            {/* {values.itemMonthlyPrice &&
              !values.itemDailyPrice &&
              !values.itemWeeklyPrice && } */}
            {values.itemCleaningPrice && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Cleaning Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemCleaningPrice).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            )}

            {values.itemFuelPrice && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Fuel Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemFuelPrice).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            )}

            {values.itemMaintenancePrice && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Maintenance Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemMaintenancePrice).toLocaleString(
                    "en-US",
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )}
                </TableCell>
              </TableRow>
            )}
            {values.itemEnvironmentalFee && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Environmental Fee
                  <Typography
                    variant="caption"
                    fontWeight={700}
                    color={"var(--grey-color)"}
                    marginLeft={".3rem"}
                  >
                    ({values.itemEnvironmentalFee}%)
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  { Math.round(
                        Number(((calculateTotalForPreviewForBoth("rent") * parseInt(values.itemEnvironmentalFee)) / 100).toFixed(2)))}{" "}
                </TableCell>
              </TableRow>
            )}
            {values.itemTransportationPrice && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Transportation Fees
                  <Typography
                    variant="caption"
                    fontWeight={700}
                    color={"var(--grey-color)"}
                    marginLeft={".3rem"}
                  >
                    (2x)
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemTransportationPrice * 2).toLocaleString(
                    "en-US",
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )}{" "}
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell
                sx={{
                  color: "var(--white-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                }}
              >
                Platform Fees
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={"var(--grey-color)"}
                  marginLeft={".3rem"}
                >
                  {values.chargesIncurredBySeller ? "(18%)" : "(6.5%)"}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  color: "var(--white-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                }}
                align="right"
              >
                $
                {`${parseFloat(
                  Number(
                    (
                      ((
                        calculateTotalForPreviewForBoth("rent") -
                        parseInt(values.itemTransportationPrice * 2)
                      ).toFixed(2) *
                        (values.chargesIncurredBySeller ? 18 : 6.5)) /
                      100
                    ).toFixed(2)
                  )
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} `}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "700",
                  textTransform: "uppercase",
                  letterSpacing: 1.5,
                  color: "var(--white-color)",
                  border: "none",
                  borderTop: "1px solid var(--grey-color)",
                  borderBottom: "none",
                }}
                align="left"
              >
                Total{" "}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "700",
                  border: "none",
                  borderTop: "1px solid var(--grey-color)",
                  borderBottom: "none",
                  color: "var(--white-color)",
                  position: "relative",
                }}
                align="right"
              >
                $
                {parseInt(
                  calculateTotalForPreviewForBoth("rent")
                ).toLocaleString("en-US")}
                {/* ${calculateTotalForPreview(values)} */}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  color: "var(--secondary-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
              >
                Dizel
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={"var(--secondary-color)"}
                  marginLeft={".3rem"}
                >
                  {values.chargesIncurredBySeller ? "(18%)" : "(6.5%)"}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  color: "#ff4b4b",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
                align="right"
              >
                $
                {`${parseFloat(
                  Number(
                    (
                      ((
                        calculateTotalForPreviewForBoth("rent") -
                        parseInt(values.itemTransportationPrice * 2)
                      ).toFixed(2) *
                        (values.chargesIncurredBySeller ? 18 : 6.5)) /
                      100
                    ).toFixed(2)
                  )
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} `}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  color: "var(--secondary-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
              >
                Earned By You
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={"var(--secondary-color)"}
                  marginLeft={".3rem"}
                >
                  {values.chargesIncurredBySeller ? "(82%)" : "(93.5%)"}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  color: "var(--secondary-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
                align="right"
              >
                $
                {`${parseFloat(
                  parseFloat(calculateTotalForPreviewForBoth("rent")) -
                    parseFloat(
                      Number(
                        (
                          ((
                            calculateTotalForPreviewForBoth("rent") -
                            parseInt(values.itemTransportationPrice * 2)
                          ).toFixed(2) *
                            (values.chargesIncurredBySeller ? 18 : 6.5)) /
                          100
                        ).toFixed(2)
                      )
                    )
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} `}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EditItemStepper;
