import { PrecisionManufacturing } from "@mui/icons-material";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getElasticSearch } from "../StateSlices/elasticSearchSlice";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

const ElasticAutocomplete = ({ inputSearchRef }) => {
  const dispatch = useDispatch();
  const { searchinfo } = useSelector((state) => state.elasticsearch);

  useEffect(() => {
    if (searchinfo && searchinfo.length > 0) {
      setFilteredSuggestions(searchinfo);
      setSuggestionLoading(false);
    } else {
      setFilteredSuggestions([]);
    }

    // return () => setRses([]);
  }, [searchinfo]);

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [valuee, setValuee] = useState("");
  const [suggestionLoading, setSuggestionLoading] = useState(false);

  const debouncedFetchSuggestions = debounce((inputQuery) => {
    if (inputQuery.trim() !== "") {
      setSuggestionLoading(true);
      dispatch(getElasticSearch(inputQuery));
    } else {
      setFilteredSuggestions([]); // Clear suggestions if inputQuery is empty
    }
  }, 1500);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedFetchSuggestions(value);
    // dispatch(getElasticSearch(value));
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const selectedValue = inputValue.trim();
      setInputValue(selectedValue);
      if (inputSearchRef.current) {
        inputSearchRef.current.value = e.target.value;
      }
    }
  };

  const navigate = useNavigate();
  return (
    <Autocomplete
      loading={suggestionLoading}
      value={valuee}
      onChange={(event, newValue) => {
        // debugger 
        // console.log("valuee", valuee, newValue);
        setValuee(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        // console.log("input valuee", newInputValue);
        inputSearchRef.current.value = newInputValue;
        setInputValue(newInputValue);
      }}
      id="highlights-elastic"
      options={filteredSuggestions}
      getOptionLabel={(option) => (option ? option.itemName : "")}
      renderInput={(params) => (
        <TextField
          {...params}
          ref={inputSearchRef}
          onChange={(e) => handleInputChange(e)}
          label=""
          margin="normal"
          sx={{
            m: 1,
            width: {xs: "320px", md: "350px"},
            color: "var(--grey-color)",
            background: "var(--white-color)",
            borderRadius: "5px",
            "&:hover": {
              border: "none",
              outline: "none",
            },
            fontFamily: "Helvetica,Arial,sans-serif",
            fontSize: "1.125rem",
            "::placeholder": {
              fontSize: "14px", // Set placeholder font size to 14px
              fontFamily: "Helvetica, Arial, sans-serif", // Set placeholder font family
              color: "#ccc",
            },
          }}
          placeholder="Search makes, models or keywords"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {suggestionLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          // isOptionEqualToValue={(option, value) =>
          //   value && (option.inputValue === value || option.itemName === value)
          // }
          //   value={inputValue}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.itemName, inputValue, {
          insideWords: true,
        });
        const parts = parse(option.itemName, matches);

        return (
          <li
            {...props}
            onKeyDown={(e) => handleEnterKeyPress(e)}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
            <div>
              {option.isCategory && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Chip
                    size="small"
                    label="Category"
                    sx={{ marginRight: ".5rem", fontSize: "12px" }}
                  />
                  <FaExternalLinkAlt
                    onClick={() => {
                      if (option.isCategory) {
                        navigate("/shop?category=" + option._id);
                      }
                    }}
                    size={".75em"}
                    color="var(--grey-color)"
                  />
                </div>
              )}
              {option.isSubCategory && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Chip
                    size="small"
                    label="Sub Category"
                    sx={{ marginRight: ".5rem", fontSize: "12px" }}
                  />
                  <FaExternalLinkAlt
                    onClick={() => {
                      if (option.isSubCategory) {
                        navigate("/shop?subcategory=" + option._id);
                      }
                    }}
                    size={".75em"}
                    color="var(--grey-color)"
                  />
                </div>
              )}
            </div>
          </li>
        );
      }}
      filterOptions={(options, { inputValue }) => {
        const filtered = options.filter((option) =>
          option.itemName.toLowerCase().includes(inputValue.toLowerCase())
        );
    
        // Add custom option for unlisted input
        if (inputValue !== "" && !filtered.some((option) => option.itemName === inputValue)) {
          filtered.push({
            inputValue,
            itemName: inputValue, // Display custom option
          });
        }
    
        return filtered;
      }}
      noOptionsText={
        filteredSuggestions.length === 0
          ? "Please type in to get suggestions..."
          : null
      }
      sx={{
        "::placeholder": {
          fontSize: "14px", // Set placeholder font size to 14px
          fontFamily: "Helvetica, Arial, sans-serif", // Set placeholder font family
        },
      }}
      //   value={inputValue}
    />
  );
};

export default ElasticAutocomplete;
