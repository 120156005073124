import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";

const TermsPopup = ({
  handleAccept,
  handleClose,
  isPopupVisible,
  setIsPopupVisible,
}) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isPopupVisible}
      onRequestClose={() => handleClose()}
      style={{
        overlay: {
          background: "rgba(0,0,0,0.5)",
          zIndex: 999,
        },
        content: {
          maxWidth: "700px",
          maxHeight: "190px",
          //   minHeight: "auto",
          margin: "auto",
          position: "absolute",
          background: "#fff",
          zIndex: 999,
        },
      }}
      contentLabel="Terms and Conditions Modal"
    >
      <div className="terms-modal-con">
        <div className="terms-modal-wrapper">
          <h3>Terms and Conditions</h3>
          <hr
            style={{
            //   borderStyle: "solid",
            //   borderColor: "var(--background-color)",
            //   width: "50px",
            //   borderWidth: "2px",
            //   marginBottom: "10px",
            //   marginTop: "-10px",
            }}
          ></hr>
          <h5 style={{ textAlign: "left" }} className="h5">
            I agree to use Dizel{" "}
            <a
              onClick={() => {
                const width = window.innerWidth * 0.75;
                const height = window.innerHeight * 0.75;
                const left = (window.innerWidth - width) / 2;
                const top = (window.innerHeight - height) / 2;

                const popupWindow = window.open(
                  "https://godizel.com/terms-and-conditions",
                  "_blank",
                  `width=${width}, height=${height}, left=${left}, top=${top}`
                );

                // Add event listener to close the popup window when the component unmounts
                window.addEventListener("beforeunload", () => {
                  if (popupWindow && !popupWindow.closed) {
                    popupWindow.close();
                  }
                });
              }}
              style={{fontWeight:700, color: "var(--secondary-color)", borderBottom: "1px solid var(--secondary-color)", cursor: "pointer"}}
            >
              Terms and Conditions
            </a>

            {/* <Link target="_blank" to={"/terms-conditions"}>Terms and Conditions</Link> */}
          </h5>
          <div>
            <Button
            variant="contained"
            color="secondary"
            
            sx={{marginTop: "1rem", boxShadow: "none"}}
             fullWidth
              onClick={() => handleAccept()}
            >
              I Accept
            </Button>
          </div>
        </div>
      </div>{" "}
    </Modal>
  );
};

// Basic styles for the popup
const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  popup: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "20px",
    textAlign: "center",
    maxWidth: "500px",
    width: "90%",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
  },
  buttons: {
    marginTop: "20px",
  },
  acceptButton: {
    marginRight: "10px",
    padding: "10px 20px",
    backgroundColor: "#4caf50",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  closeButton: {
    padding: "10px 20px",
    backgroundColor: "#f44336",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default TermsPopup;
