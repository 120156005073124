import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Select as MuiSelect } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { editProfile, resetProfileEdit } from "../StateSlices/editprofileSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";
import { getuserInfo } from "../StateSlices/getuserSlice";
import "./EditProfile.css";
import userAvatarIcon from "../../assets/icons/user.png";
import axios from "axios";
import CryptoJS from "crypto-js";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { components } from "react-select";
import {
  DarkToolTip,
  formatPhoneNumber,
  US_PHONE_REGEX,
} from "../../utils/utils";
import { InfoOutlined } from "@mui/icons-material";

const toastOption = {
  position: "top-right",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data.value.structured_formatting.main_text}
    </components.SingleValue>
  );
};

const Input = (props) => <components.Input {...props} isHidden={false} />;

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const selectRef = useRef();
  const { status, userInfo, profile } = useSelector((state) => state.user);
  const { profileStatus, userUpdatedInfo, profileError } = useSelector(
    (state) => state.editprofile
  );
  const [loading, setLoading] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [fullLocation, setFullLocation] = useState(null);

  useEffect(() => {
    setLoading(true);
    let token = localStorage.getItem("inktoken");
    dispatch(getuserInfo({ token }));
  }, []);

  const onFocus = () => fullLocation && selectRef.current.inputRef.select();

  const onInputChange = (inputValue, { action }) => {
    // onBlur => setInputValue to last selected value
    // if (action === "input-blur") {
    //   setInputValue(value ? value.label : "");
    // }
    console.log(action);
    // onInputChange => update inputValue
    if (action === "input-change") {
      setInputValue(inputValue);
    }
  };

  useEffect(() => {
    if (userInfo) {
      formRef.current.setFieldValue(
        "userFName",
        userInfo.userDetailsId.userFName
      );
      formRef.current.setFieldValue(
        "userLName",
        userInfo.userDetailsId.userLName
      );
      formRef.current.setFieldValue(
        "userEmail",
        userInfo.userDetailsId.userEmail
      );
      formRef.current.setFieldValue(
        "userAddress",
        userInfo.userDetailsId.userAddress
      );
      setFullLocation({
        label: userInfo.userDetailsId.userAddress,
        value: { description: userInfo.userDetailsId.userAddress },
      });
      setInputValue(userInfo.userDetailsId.userAddress);
      formRef.current.setFieldValue(
        "userPhone",
        userInfo.userDetailsId.userPhone
      );
      formRef.current.setFieldValue(
        "userCity",
        userInfo.userDetailsId.userCity
      );
      formRef.current.setFieldValue(
        "userState",
        userInfo.userDetailsId.userState
      );
      formRef.current.setFieldValue(
        "userZipCode",
        userInfo.userDetailsId.userZipCode
      );
      formRef.current.setFieldValue(
        "companyName",
        userInfo.userDetailsId.companyName
      );
      formRef.current.setFieldValue(
        "companyDesc",
        userInfo.userDetailsId.companyDesc
      );
      console.log("hai bhai idhar", userInfo.userDetailsId.companyDesc);
      formRef.current.setFieldValue(
        "companyDesgn",
        userInfo.userDetailsId.companyDesgn
      );
      formRef.current.setFieldValue(
        "userTaxId",
        userInfo.userDetailsId.userTaxId
      );
      if (userInfo.userDetailsId.userIcon) {
        setImagePreviewUrl(userInfo.userDetailsId.userIcon);
        formRef.current.setFieldValue(
          "userIcon",
          userInfo.userDetailsId?.userIcon
        );
      }
      setLoading(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userUpdatedInfo) {
      setLoading(false);
      toast.success("User Details Updated Successfully", toastOption);
      dispatch(resetProfileEdit());
      let token = localStorage.getItem("inktoken");

      setTimeout(() => {
        dispatch(getuserInfo({ token }));
      }, 100);
    }
  }, [userUpdatedInfo]);

  useEffect(() => {
    if (profileError) {
      toast.error(profileError, toastOption);
      dispatch(resetProfileEdit());
      setLoading(false);
    }
  }, [profileError]);

  const [imagePreviewUrl, setImagePreviewUrl] = useState("");

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Box component={"div"} marginTop={"2rem"}>
        <Typography
          variant="h2"
          marginTop={"4.5rem"}
          className="title mont-title"
        >
          Edit Profile
        </Typography>
        {userInfo ? (
          <Formik
            initialValues={{
              userFName: "",
              userLName: "",
              userEmail: "",
              userAddress: "",
              userPhone: "",
              userIcon: "",
              companyName: "",
              companyDesc: "",
              companyDesgn: "",
              userCity: "",
              userState: "",
              userZipCode: "",
              userTaxId: "",
            }}
            initialErrors={{
              userFName: "Please Select this field value",
              userLName: "Please Select this field value",
              userEmail: "Please Select this field value",
              userAddress: "Please Select this field value",
              userPhone: "Please Select this field value",
            }}
            validationSchema={Yup.object().shape({
              userFName: Yup.string().required("Please Enter User First Name"),
              userLName: Yup.string().required("Please Enter User Last Name"),
              userAddress: Yup.string().required("Please Enter User Address"),
              userEmail: Yup.string()
                .email("Enter Valid Email")
                .required("Please enter your Email"),
              userPhone: Yup.string()
                .matches(US_PHONE_REGEX, "Invalid phone number")
                .required("Phone number is required"),
                userTaxId: Yup.string()
                .matches(
                  /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/,
                  "Invalid TaxID / SSN. Format should be XXX-XX-XXXX or XX-XXXXXXX."
                )
                .required("TaxID / SSN is required"),
            })}
            onSubmit={(values, actions) => {
              window.scrollTo(0, 0);
              setLoading(true);
              if (
                imagePreviewUrl !== "" &&
                imagePreviewUrl !== values.userIcon
              ) {
                const timestamp = Math.round(new Date().getTime() / 1000);
                const params = {
                  timestamp: timestamp,
                  transformation: "f_webp,q_70,c_scale,w_1000",
                  upload_preset: "closet-closest",
                  folder: "dizel_user_icons",
                  // add any additional parameters here, such as transformation options
                };
                const signature = CryptoJS.SHA1(
                  `timestamp=${params.timestamp}&transformation=${params.transformation}&upload_preset=${params.upload_preset}${process.env.REACT_APP_CLOUDINARY_API_SECRET}`
                ).toString();

                const formData = new FormData();
                formData.append("file", imagePreviewUrl);
                formData.append("upload_preset", "closet-closest");
                formData.append("cloud_name", "closet-closest");
                formData.append("transformation", "f_webp,q_70,c_scale,w_1000");
                formData.append("signature", signature);
                formData.append("timestamp", timestamp);
                // formData.append("folder", "dizel_user_icons");
                formData.append("api_key", "738997887579849");

                // Use await to wait for the axios.post to complete
                axios
                  .post(
                    "https://api.cloudinary.com/v1_1/closet-closest/image/upload",
                    formData,
                    {
                      headers: { "X-Requested-With": "XMLHttpRequest" },
                    }
                  )
                  .then((response) => {
                    const data = response.data;
                    const fileURL = data.secure_url; // You should store this URL for future references in your app
                    values.userIcon = fileURL;
                    console.log(values);
                    let token = localStorage.getItem("inktoken");
                    console.log(token);
                    if (token) {
                      setLoading(true);
                      dispatch(editProfile({ token, values: values }));
                    } else {
                      navigate("/signup");
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    throw new Error("Something went Wrong!!!!");
                  });
              } else {
                console.log(values);
                let token = localStorage.getItem("inktoken");
                console.log(token);
                if (token) {
                  dispatch(editProfile({ token, values: values }));
                } else {
                  navigate("/signup");
                }
              }
            }}
            innerRef={formRef}
            render={(props) => {
              let {
                setFieldValue,
                values,
                handleBlur,
                handleChange,
                touched,
                errors,
                handleSubmit,
                setErrors,
                setFieldTouched,
                setFieldError,
              } = props;

              return (
                <Box marginTop={"2rem"}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <ScrollToFieldError />
                      <Grid container spacing={4} marginTop={"0rem"}>
                        <Grid
                          item
                          rowGap={4}
                          xs={12}
                          md={8}
                          style={{ paddingTop: 0 }}
                        >
                          <TextField
                            fullWidth
                            color="secondary"
                            label="First Name"
                            name="userFName"
                            type="text"
                            error={
                              touched.userFName && Boolean(errors.userFName)
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.userFName}
                            helperText={touched.userFName && errors.userFName} //required in case of error msg
                          ></TextField>

                          <TextField
                            sx={{ marginTop: "2rem" }}
                            fullWidth
                            color="secondary"
                            name="userLName"
                            label="Last Name"
                            type="text"
                            error={
                              touched.userLName && Boolean(errors.userLName)
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.userLName}
                            helperText={touched.userLName && errors.userLName} //required in case of error msg
                          ></TextField>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{ paddingTop: 0, textAlign: "center" }}
                        >
                          <label
                            htmlFor="photo-upload"
                            className="custom-file-upload fas"
                          >
                            <div className="img-wrap img-upload">
                              <img
                                src={
                                  imagePreviewUrl !== ""
                                    ? imagePreviewUrl
                                    : userAvatarIcon
                                }
                                alt="Upload Preview"
                              />
                            </div>
                            <input
                              id="photo-upload"
                              type="file"
                              onChange={photoUpload}
                            />
                          </label>
                        </Grid>
                      </Grid>
                      <Grid container margin={"2rem auto"}>
                        {/* <Grid item xs={6}> */}
                        <TextField
                          fullWidth
                          color="secondary"
                          label="Email Address"
                          type="text"
                          aria-readonly
                          name="userEmail"
                          error={touched.userEmail && Boolean(errors.userEmail)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.userEmail}
                          helperText={touched.userEmail && errors.userEmail} //required in case of error msg
                        ></TextField>
                        {/* </Grid> */}
                      </Grid>

                      <Grid item xs={12}>
                        {/* <TextField
                          // sx={{ marginTop: "2rem" }}
                          color="secondary"
                          fullWidth
                          label="Address"
                          name="userAddress"
                          type="text"
                          aria-readonly
                          error={
                            touched.userAddress && Boolean(errors.userAddress)
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.userAddress}
                          helperText={touched.userAddress && errors.userAddress} //required in case of error msg
                        ></TextField> */}

                        <Grid item xs={12} sx={{ background: "transparent" }}>
                          <GooglePlacesAutocomplete
                            selectProps={{
                              ref: selectRef,
                              value: fullLocation,
                              onChange: (val) => {
                                if (val) {
                                  geocodeByAddress(val.label)
                                    .then((results) => {
                                      console.log(results);
                                      let zipCode =
                                        results[0].address_components.find(
                                          (zp) =>
                                            zp.types.includes("postal_code")
                                        );
                                      if (zipCode) {
                                        setFieldValue(
                                          "userZipCode",
                                          zipCode.long_name
                                        );
                                      } else {
                                        setFieldValue("userZipCode", "");
                                      }
                                    })
                                    .catch((error) => console.error(error));
                                }

                                // console.log(val);
                                setFullLocation(val);
                                setInputValue(
                                  val
                                    ? val.value.structured_formatting.main_text
                                    : ""
                                );
                                setFieldValue(
                                  "userAddress",
                                  val
                                    ? val.value.structured_formatting.main_text
                                    : ""
                                );
                                if (val) {
                                  let total = val.value.terms.length;
                                  // console.log(total)
                                  setFieldValue(
                                    "userCity",
                                    val.value.terms[total - 3] &&
                                      val.value.terms[total - 3].value
                                  );
                                  setFieldValue(
                                    "userState",
                                    val.value.terms[total - 2] &&
                                      val.value.terms[total - 2].value
                                  );
                                }

                                console.log(val);
                                // setFieldValue(
                                //   "city",
                                //   val.terms.length > 3
                                //     ? val.value.terms[
                                //         val.value.terms.length - 3
                                //       ]
                                //     : val.value.terms[
                                //         val.value.terms.length - 2
                                //       ]
                                // );
                                // setFieldValue(
                                //   "state",
                                //   val.terms.length > 3
                                //     ? val.value.terms[
                                //         val.value.terms.length - 2
                                //       ]
                                //     : val.value.terms[
                                //         val.value.terms.length - 1
                                //       ]
                                // );
                              },
                              styles: {
                                input: (provided) => ({
                                  ...provided,
                                  padding: 12,
                                  border: "none",
                                  background: "transparent",
                                }),
                                option: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none",
                                }),
                              },
                              inputValue: inputValue,
                              onFocus: onFocus,
                              isClearable: true,
                              onInputChange: onInputChange,
                              controlShouldRenderValue: false,
                              components: { SingleValue, Input },
                              placeholder: "Street Address *",
                            }}
                            apiKey="AIzaSyAw_j2KY4CDtksQcd6JexS3J1xt4BdbnGQ"
                            apiOptions={{ region: "US" }}
                          />
                          {errors.itemLocation && touched.itemLocation ? (
                            <FormHelperText
                              style={{ color: "red", marginLeft: "1rem" }}
                            >
                              {errors.itemLocation.label}
                            </FormHelperText>
                          ) : (
                            <FormHelperText
                              style={{
                                marginLeft: "1rem",
                                // color: "text.secondary",
                              }}
                            >
                              E.g XYZ Street, City, Country
                            </FormHelperText>
                          )}
                        </Grid>

                        <Grid container columnSpacing={1}>
                          <Grid item xs={4}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              error={
                                touched.userCity && Boolean(errors.userCity)
                              } //true if error exists
                              required
                              name="userCity"
                              id="userCity"
                              label="City"
                              defaultValue=""
                              sx={{
                                width: "100%",
                                marginTop: "2rem",
                              }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.userCity}
                              helperText={touched.userCity && errors.userCity}
                            />
                          </Grid>

                          <Grid item xs={4}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              error={touched.state && Boolean(errors.state)} //true if error exists
                              required
                              name="userState"
                              id="userState"
                              label="State"
                              defaultValue=""
                              sx={{
                                width: "100%",
                                marginTop: "2rem",
                              }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.userState}
                              helperText={touched.userState && errors.userState}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              error={touched.zip && Boolean(errors.zip)} //true if error exists
                              required
                              name="userZipCode"
                              id="userZipCode"
                              label="Zip Code"
                              defaultValue=""
                              sx={{
                                width: "100%",
                                marginTop: "2rem",
                              }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.userZipCode}
                              helperText={
                                touched.userZipCode && errors.userZipCode
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container margin={"2rem auto"}>
                        {/* <Grid item xs={6}> */}
                        <TextField
                          color="secondary"
                          fullWidth
                          label="Phone Number"
                          name="userPhone"
                          type="text"
                          aria-readonly
                          error={Boolean(touched.userPhone && errors.userPhone)}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            const formattedNumber = formatPhoneNumber(
                              e.target.value
                            );
                            setFieldValue("userPhone", formattedNumber);
                          }}
                          value={values.userPhone}
                          helperText={
                            touched.userPhone && errors.userPhone
                              ? errors.userPhone
                              : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DarkToolTip
                                  title="Accepted format: (555) 123-4567"
                                  arrow
                                >
                                  <IconButton>
                                    <InfoOutlined sx={{ fontSize: "18px" }} />
                                  </IconButton>
                                </DarkToolTip>
                              </InputAdornment>
                            ),
                          }}
                        ></TextField>
                        {/* </Grid> */}
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          fontWeight={700}
                          marginTop={"1rem"}
                          marginBottom={"1rem"}
                        >
                          Company (Legal) Information
                        </Typography>
                        <TextField
                          label="Company (Legal) Name"
                          name="companyName"
                          id="company"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.companyName}
                          fullWidth
                          variant="outlined"
                          helperText={
                            touched.company && errors.company
                              ? errors.company
                              : "E.g. Tempshare INC."
                          }
                          error={
                            touched.companyName && Boolean(errors.companyName)
                          }
                        />
                        {/* <TextField
                                  error={
                                    touched.companyDesc &&
                                    Boolean(errors.companyDesc)
                                  } //true if error exists
                                  name="companyDesc"
                                  id="company-name"
                                  label="Company (Seller) Description"
                                  defaultValue=""
                                  sx={{ width: "100%", marginTop: "1.2rem" }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.companyDesc}
                                  helperText={
                                    touched.companyDesc && errors.companyDesc
                                  }
                                /> */}
                        <Grid item xs={12}>
                          <FormControl
                            fullWidth
                            sx={{
                              marginTop: "1rem",
                            }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Company (Legal) Description
                            </InputLabel>
                            <MuiSelect
                              labelId="demo-simple-select-label"
                              id="demo-simple-select-label"
                              value={values.companyDesc}
                              name="companyDesc"
                              label="Company (Legal) Description"
                              onChange={handleChange}
                            >
                              <MenuItem value={"rental_company"}>
                                Rental Company
                              </MenuItem>
                              <MenuItem value={"contractor"}>
                                Contractor
                              </MenuItem>
                            </MuiSelect>
                          </FormControl>
                        </Grid>
                        <TextField
                          error={
                            touched.companyDesgn && Boolean(errors.companyDesgn)
                          } //true if error exists
                          name="companyDesgn"
                          id="company-desgn"
                          label="Your Role/Title"
                          defaultValue=""
                          sx={{ width: "100%", marginTop: "1.2rem" }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.companyDesgn}
                          helperText={
                            touched.companyDesgn && errors.companyDesgn
                              ? errors.companyDesgn
                              : "E.g. CEO"
                          }
                        />
                        <TextField
                          error={touched.userTaxId && Boolean(errors.userTaxId)} //true if error exists
                          name="userTaxId"
                          id="tax-id"
                          label="TaxID (Company) / SSN (Individual)"
                          defaultValue=""
                          sx={{ width: "100%", marginTop: "1rem" }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.userTaxId}
                          helperText={
                            touched.userTaxId && errors.userTaxId
                              ? errors.userTaxId
                              : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DarkToolTip
                                  title="Accepted format: XXX-XX-XXXX or XX-XXXXXXX"
                                  arrow
                                >
                                  <IconButton>
                                    <InfoOutlined sx={{ fontSize: "18px" }} />
                                  </IconButton>
                                </DarkToolTip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid container spacing={4}>
                        {/* <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label=" Profile Status"
                    type="text"
                    value={userInfo.userDetailsId.profileStatus}
                  ></TextField>
                </Grid> */}
                        <Grid item xs={12}>
                          <TextField
                            sx={{ marginTop: "1.5rem" }}
                            fullWidth
                            label="Last Updated At"
                            type="text"
                            disabled
                            value={
                              new Date(
                                userInfo.userDetailsId.updatedAt
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }) +
                              " - " +
                              new Date(
                                userInfo.userDetailsId.updatedAt
                              ).toLocaleTimeString("en-US")
                            }
                          ></TextField>
                        </Grid>
                      </Grid>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          marginTop: "2rem",
                          background: "var(--secondary-color)",
                        }}
                        disabled={profileStatus === "loading" ? true : false}
                      >
                        {profileStatus === "loading"
                          ? "Please Wait"
                          : "Update Details"}
                      </Button>
                    </form>
                  )}
                </Box>
              );
            }}
          />
        ) : (
          <Spinner />
        )}
      </Box>
    </>
  );
};

export default EditProfile;
