import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Divider,
  Fade,
  Grid,
  Modal,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Gallery from "./Gallery";
import MobileGallery from "./MobileGallery";
import Description from "./Description";
import "./Product.css";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getProduct, reset } from "../StateSlices/getproductSlice";
import Spinner from "../Spinner/Spinner";
import { ExpandCircleDown, ExpandMore } from "@mui/icons-material";
import {
  categoies,
  subCategories,
  brands,
  transportationOptions,
  otherLogos,
  fldMappings,
} from "../../pages/AddNewItem/data";
import { toast } from "react-toastify";
import {
  addtoCart,
  addtoCartForRental,
  resetCartMessage,
} from "../StateSlices/addtocartSlice";
import { DateRangePicker } from "rsuite";
import { Formik } from "formik";
import { useRef } from "react";
// import { resetCartMessage } from "../StateSlices/getCheckoutProductsSlice";
import { getuserInfo } from "../StateSlices/getuserSlice";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TabsSorter from "../TabsSorter/TabsSorter";
import { getallProducts } from "../StateSlices/getproductsSlice";
import TempGallery from "./TempGallery";
import dizelLogo from "../../assets/godizel-logo.png";
import {
  getThirdPartyLogo,
  getThirdPartyName,
  shouldDisableDatee,
} from "../../utils/utils";
import { Helmet } from "react-helmet";

const chipStyle = {
  padding: "1.25rem 1.5rem",
  color: "var(--white-color)",
  background: "var(--black-color)",
  cursor: "pointer",
  transition: ".3s ease",
  borderRadius: "30px",
  "&:hover": {
    background: "var(--dark-grey-color)",
    transform: "scale(1.05)",
    transition: ".3s ease",
  },
};

const Product = ({ setAddedtoCart }) => {
  const { allowedRange, combine } = DateRangePicker;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef();

  const [cartValue, setCartValue] = useState([]);

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const { pstatus, productInfo, images, closetInfo, error } = useSelector(
    (state) => state.getproduct
  );

  const { products, productCount, filters } = useSelector(
    (state) => state.product
  );
  const { status, userInfo } = useSelector((state) => state.user);

  const { cstatus, message } = useSelector((state) => state.cart);

  const { beforeToday } = DateRangePicker;

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [otherLoading, setOtherLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [expandedAccordion, setExpandedAccordion] = React.useState(false);
  const [openRentInfoModal, setOpenRentInfoModal] = useState(false);
  const [checkoutRentalPriceType, setCheckoutRentalPriceType] =
    useState("itemDailyPrice");
  const [checkoutRentalDateRange, setCheckoutRentalDateRange] = useState(null);
  const [PId, setPId] = useState(null);
  const [otherProducts, setOtherProducts] = useState([]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  console.log("asdasd", productDetails, productInfo);

  useEffect(() => {
    // dispatch(reset());
    //console.log(location.state);
    let cartItems = localStorage.getItem("cartItems") || [];
    if (cartItems.length > 0) cartItems = JSON.parse(cartItems);
    setCartValue(cartItems.map((item) => item.id));

    if (location.state && location.state.id) {
      //console.log("Yesssss");
      dispatch(getProduct(location.state.id));
    } else if (searchParams.get("product")) {
      console.log("idhar aaya ke nai");
      dispatch(getProduct(searchParams.get("product")));
    } else if (searchParams.get("id")) {
      console.log("Aavi gyo", searchParams.get("id"));
      dispatch(getProduct(searchParams.get("id")));
    } else {
      const productIdFromUrl = window.location.pathname.split("/").pop();
      if (productIdFromUrl && productIdFromUrl.length === 24) {
        dispatch(getProduct(productIdFromUrl));
      } else {
        navigate("/404");
      }
    }

    // if(productInfo !== {}){
    //     productInfo.productImage.map((val)=>{
    //         setImages(prevState => {
    //             return [...prevState,{original:val,thumbnail:val}]
    //         })
    //     })
    // }
  }, [location?.state]);

  if (productInfo) {
    setLoading(false);
    setProductDetails(productInfo);
    if (!productInfo.fldData) {
      setOtherLoading(true);
      dispatch(
        getallProducts({
          tab: "",
          categories: productInfo?.itemCategory.join(","),
          brands: "",
          page: 1,
          date: "",
          priceFilterType: "",
          minRentPriceFilter: "",
          maxRentPriceFilter: "",
          minBuyPriceFilter: "",
          maxBuyPriceFilter: "",
          langLong: "",
          search: "",
          milesFilter: "",
          transportationFilter: "",
          sortFilter: "",
        })
      );
    }
    dispatch(reset());
  }

  useEffect(() => {
    if (products && products.length > 0) {
      let filteredProducts =
        products &&
        products.filter((prod) => prod?._id !== productDetails?._id);
      setOtherProducts(filteredProducts);
      setOtherLoading(false);
      dispatch(reset());
    } else {
      setOtherProducts([]);
      setOtherLoading(false);
    }
  }, [products]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxWidth: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
    zIndex: 1,
  };

  useEffect(() => {
    if (message) {
      setLoading(false);
      dispatch(resetCartMessage());
      toast.success("Equipment added to cart successfully!", toastOption);
      let token = localStorage.getItem("inktoken");
      if (token) {
        dispatch(getuserInfo({ token: token }));
      }
    }
  }, [message]);

  const customDisabledDate = (date) => {
    const { itemLeaseStart, itemLeaseEnd } = productDetails;
    const isBeforeToday = date < new Date();
    const isOutsideAllowedRange =
      date < new Date(itemLeaseStart) || date > new Date(itemLeaseEnd);
    return isBeforeToday || isOutsideAllowedRange;
  };

  // CHECKOUT
  const AddToCart = (id, productType) => {
    if (productType == "rent") {
      setOpenRentInfoModal(true);
      setPId(id);
      return;
    } else {
      //temporary basis LOCAL STORAGE
      let cartItems = localStorage.getItem("cartItems") || [];
      if (cartItems.length > 0) cartItems = JSON.parse(cartItems);
      cartItems.push({ id, itemType: "buy" });
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      ///////////////////////////////////////////////////////
      let token = localStorage.getItem("inktoken");
      setLoading(true);
      // let cart = [];
      // cart.push(id);
      // sessionStorage.setItem("closetcart", JSON.stringify(cart));
      setCartValue((prev) => [...prev, id]);
      if (userInfo) {
        dispatch(addtoCart({ token, id: { id, itemType: "buy" } }));
      } else {
        setLoading(false);
        setAddedtoCart(true);
        toast.success("Equipment added to cart successfully!", toastOption);
      }

      // setLoading(false);

      // toast.success("Coming Soon", toastOption);
    }
  };

  const handleCheckoutRentalInfo = () => {
    // temporary basis LOCAL STORAGE
    setOpenRentInfoModal(false);
    let cartItems = localStorage.getItem("cartItems") || [];
    if (cartItems.length > 0) cartItems = JSON.parse(cartItems);
    cartItems.push({
      id: PId,
      itemType: "rent",
      checkoutRentalDateRange,
    });
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    ///////////////////////////////////////////////////////
    let token = localStorage.getItem("inktoken");
    setLoading(true);
    // let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
    // cart.push(PId);
    // sessionStorage.setItem("closetcart", JSON.stringify(cart));
    // setCartVal(cart);
    setCartValue((prev) => [...prev, PId]);
    const payload = { checkoutRentalDateRange, checkoutRentalPriceType };

    // dispatch(addtoCartForRental({ token, PId, payload }));
    if (userInfo) {
      dispatch(
        addtoCart({
          token,
          id: {
            id: PId,
            itemType: "rent",
            checkoutRentalDateRange,
          },
        })
      );
    } else {
      setLoading(false);
      setAddedtoCart(true);
      toast.success("Equipment added to cart successfully!", toastOption);
    }

    // setLoading(false);
    // toast.success("Equipment added to cart successfully!", toastOption);

    // toast.success("Coming Soon", toastOption);
  };
  
  return (
    <>
      <Helmet>
        <title>
          GoDizel {productDetails ? "| " + productDetails.itemName : ""}
        </title>
      </Helmet>
      {loading ? (
        <Spinner pos={"relative"} />
      ) : (
        <>
          {/* <div
            className={`confetti-container ${showConfetti ? "show" : "hide"}`}
          > */}

          {/* </div> */}
          <Container
            sx={{ minHeight: "100vh" }}
            component="section"
            maxWidth={"lg"}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <Box sx={{ margin: { xs: "1rem auto", md: "2rem auto" } }}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="12" />}
                sx={{
                  mb: "2rem",
                  fontSize: { xs: 12, md: 16 },
                  fontWeight: 700,
                  color: "#777",
                }}
              >
                <Link to={"/"} style={{ color: "777" }}>
                  Home
                </Link>
                <Link to={"/shop"} style={{ color: "#777" }}>
                  Shop
                </Link>
                <Link
                  to={"/product/" + productDetails._id}
                  style={{ color: "#777" }}
                >
                  {productDetails.itemName}
                </Link>
              </Breadcrumbs>
              <section className="core">
                {/* <MobileGallery product={productDetails} /> */}
                <Gallery product={productDetails} />
                <Description
                  getThirdPartyName={getThirdPartyName}
                  product={productDetails}
                  userInfo={userInfo}
                  AddToCart={AddToCart}
                  cartVal={cartValue}
                />
              </section>
            </Box>
            {otherLoading ? (
              <Spinner />
            ) : (
              <>
                {otherProducts.length > 0 && (
                  <Box
                    sx={{ margin: "4rem auto" }}
                    component={"div"}
                    className={"other-products-wrapper"}
                    data-aos="fade-in"
                  >
                    <Divider
                      sx={{ mb: ".5rem", borderBottomWidth: "medium" }}
                    />
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      maxWidth={"90%"}
                      margin={"auto"}
                      flexWrap={"wrap"}
                      justifyContent={"space-between"}
                    >
                      <Typography variant="h4">
                        Compare more{" "}
                        <strong>
                          {
                            categoies.find(
                              (item) =>
                                item._id === productDetails.itemCategory[0]
                            )?.label
                          }
                        </strong>{" "}
                        equipment
                      </Typography>
                      <TabsSorter />
                    </Stack>
                    <Divider
                      sx={{ mt: ".5rem", borderBottomWidth: "medium" }}
                    />
                    <Box component={"div"} className="other-products">
                      <>
                        {otherProducts.map((item, ind) => (
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            maxWidth={"80%"}
                            margin={"1rem auto"}
                          >
                            <Box display={"flex"}>
                              {item.merchantFeed ? (
                                <img
                                  src={getThirdPartyLogo(item.link)}
                                  width={"30px"}
                                  height={"30px"}
                                  alt="logo of party"
                                  title={getThirdPartyName(item.link)}
                                />
                              ) : (
                                <img
                                  src={dizelLogo}
                                  width={"30px"}
                                  height={"30px"}
                                  alt="logo of party"
                                />
                              )}
                            </Box>
                            <Typography variant="h5">
                              {item.itemName}
                            </Typography>
                            <Box component={"div"}>
                              {item.itemToSell ? (
                                <Chip
                                  sx={{
                                    ...chipStyle,
                                    marginRight: "1rem",
                                    minWidth: "200px",
                                    "&:hover": {
                                      transform: "none",
                                      background: "var(--black-color)",
                                      cursor: "default",
                                    },
                                  }}
                                  label={
                                    "Buy for $" +
                                    item.itemAmount.toLocaleString("en-US")
                                  }
                                />
                              ) : (
                                <Chip
                                  sx={{
                                    ...chipStyle,
                                    marginRight: "1rem",
                                    minWidth: "200px",
                                    "&:hover": {
                                      transform: "none",
                                      background: "var(--black-color)",
                                      cursor: "default",
                                    },
                                  }}
                                  label={
                                    item.itemWeeklyPrice &&
                                    item.itemWeeklyPrice !== 0
                                      ? "$" +
                                        item.itemWeeklyPrice.toLocaleString(
                                          "en-US"
                                        ) +
                                        " per week"
                                      : item.itemDailyPrice &&
                                        item.itemDailyPrice !== 0
                                      ? "$" +
                                        item.itemDailyPrice.toLocaleString(
                                          "en-US"
                                        ) +
                                        " per day"
                                      : "$" + item.itemMonthlyPrice &&
                                        item.itemMonthlyPrice.toLocaleString(
                                          "en-US"
                                        ) + " per month"
                                  }
                                />
                              )}
                              <Link
                                onClick={() => {
                                  setLoading(true);
                                  dispatch(reset());
                                }}
                                to={"/product/" + item._id}
                                state={{ id: item._id }}
                                style={{
                                  textDecoration: "none",
                                }}
                              >
                                <Chip sx={chipStyle} label={"View"} />
                              </Link>
                            </Box>
                          </Stack>
                        ))}
                      </>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Container>

          {openRentInfoModal && (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openRentInfoModal}
              onClose={() => setOpenRentInfoModal(false)}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={openRentInfoModal}>
                <Box sx={style} className="checkout-rental-info-modal">
                  <Typography
                    id="transition-modal-title"
                    variant="h3"
                    fontWeight={700}
                  >
                    Provide Rental Details
                  </Typography>
                  <Typography id="transition-modal-description" variant="h6">
                    Please provide rental information for your selected item for
                    the checkout
                  </Typography>
                  <br />

                  <Typography variant="h6" margin={".5rem 0"}>
                    Rental Period
                  </Typography>
                  <DateRangePicker
                    ranges={[]}
                    defaultOpen
                    shouldDisableDate={(date) =>
                      productDetails.itemAvailability &&
                      shouldDisableDatee(
                        date,
                        productDetails.itemLeaseFields,
                        productDetails.buyRange
                      )
                    }
                    style={{ width: "100%" }}
                    onOk={(newValue) => setCheckoutRentalDateRange(newValue)}
                  />
                  <br />
                  <Button
                    type="submit"
                    sx={{ margin: "2rem 0" }}
                    variant="outlined"
                    onClick={handleCheckoutRentalInfo}
                  >
                    Add to Cart
                  </Button>
                </Box>
              </Fade>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default Product;
