import React, { useEffect, useState, useRef } from "react";
import "./AddNewItem.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import {
  FaCalendarCheck,
  FaCircleDollarToSlot,
  FaCircleInfo,
  FaPlus,
  FaStopwatch,
} from "react-icons/fa6";
import { components } from "react-select";

import {
  Autocomplete as Mautocomplete,
  Divider,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Container,
  Box,
  Stack,
  StepConnector,
  StepLabel,
  stepConnectorClasses,
  styled,
  FormLabel,
  RadioGroup,
  Radio,
  MenuItem,
  CircularProgress,
  createFilterOptions,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TableCell,
  TableRow,
  AccordionDetails,
  AccordionSummary,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Tooltip,
  Accordion,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import "./AddNewItem.css";
import {
  Add,
  AddAPhoto,
  Camera,
  CameraFrontTwoTone,
  Clear,
  Close,
  ControlPointRounded,
  ExpandCircleDown,
  ExpandCircleDownRounded,
  InfoOutlined,
  PlusOneRounded,
  Save,
} from "@mui/icons-material";
import {
  Formik,
  useFormik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
  useFormikContext,
  useField,
} from "formik";
import { DatePicker, DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import dayjs, { Dayjs } from "dayjs";
import {
  categoies,
  subCategories,
  brands,
  conditions,
  years,
  transportationOptions,
  itemGoalsNested,
  thirdPartyAccountEntity,
  placeholderMapping,
  earthManipulationAttachments,
  liftingAttachments,
  environmentalFeeOptions,
} from "./data";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import axios from "axios";
import {
  addItem,
  resetId,
  resetItem,
  saveItem,
} from "../../components/StateSlices/additemSlice";
import {
  MultiInputDateTimeRangeField,
  SingleInputTimeRangeField,
} from "@mui/x-date-pickers-pro";
import { getuserInfo } from "../../components/StateSlices/getuserSlice";
import { isBefore } from "rsuite/esm/utils/dateUtils";
import {
  getProductFields,
  resetFields,
} from "../../components/StateSlices/getProductFields";
import DynamicField from "./DynamicField";
import { getMakeFieldOptions } from "../../components/StateSlices/getMakeFieldOptions";
import { DarkToolTip, LightToolTip } from "../../utils/utils";
import { LoadingButton } from "@mui/lab";

// import { useBeforeunload } from 'react-beforeunload';

const FormikTextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <TextField
      // style={{ marginBottom: "1rem" }}
      {...field}
      required
      {...props}
      label={label}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ marginRight: "1.5rem" }}>
            <DarkToolTip
              className="gd-tooltip"
              placement="bottom"
              title={`Please enter in mentioned unit: ${placeholderMapping[label]}`}
            >
              {placeholderMapping[label] || ""}
            </DarkToolTip>
          </InputAdornment>
        ),
      }}
    />
  );
};

const FormikSelect = ({ label, options, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <TextField
      // style={{ marginBottom: "1rem" }}
      select
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ marginRight: "1.5rem" }}>
            <DarkToolTip
              className="gd-tooltip"
              placement="bottom"
              title={`Please enter in mentioned unit: ${placeholderMapping[label]}`}
            >
              {placeholderMapping[label] || ""}
            </DarkToolTip>
          </InputAdornment>
        ),
      }}
      required
      {...field}
      {...props}
      label={label}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      fullWidth
    >
      {/* <MenuItem value="">
        <em>None</em>
      </MenuItem> */}
      {options.map((option, index) => (
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

const filterForCreateOptions = createFilterOptions();
const FormikAutoCompleteWithCreateOption = ({
  label,
  name,
  setFieldValue,
  ...props
}) => {
  const [value, setValue] = React.useState(null);
  const [optionss, setOptionss] = useState([]);
  const [field, meta] = useField(props);
  const dispatchh = useDispatch();
  const { makeoptions } = useSelector((state) => state.getmakefieldoptions);
  useEffect(() => {
    const token = localStorage.getItem("inktoken");
    if (token) dispatchh(getMakeFieldOptions({ token }));
  }, [dispatchh]);

  useEffect(() => {
    if (makeoptions && makeoptions.length > 0) {
      // console.log("Options set:", makeoptions);
      setOptionss(makeoptions);
    } else {
      console.log("No Options Available");
    }
  }, [makeoptions]);

  return (
    optionss && (
      <Mautocomplete
        fullWidth
        // loading={optionss.length === 0}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setFieldValue(name, newValue);
            setValue({
              title: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              title: newValue.inputValue,
            });
            setFieldValue(name, newValue.inputValue);
          } else {
            setValue(newValue);
            setFieldValue(name, newValue?.title);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filterForCreateOptions(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.title
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={optionss}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              {option.title}
            </li>
          );
        }}
        freeSolo
        renderInput={(params) => (
          <TextField
            name={name}
            label={label}
            {...params}
            required
            // error={meta.touched && Boolean(meta.error)}
            // helperText={meta.touched && meta.error}
          />
        )}
      />
    )
  );
};

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data.value.description}
    </components.SingleValue>
  );
};
const Input = (props) => <components.Input {...props} isHidden={false} />;

const AddNewItemStepper = () => {
  //options based on goal, categories and subcategories
  const [goalOptions, setGoalOptions] = useState(
    Object.keys(itemGoalsNested).map((key) => ({
      label: key,
      value: key.toLowerCase().replace(/ /g, "_"),
    })) || []
  );

  const [dynamicCategoryOptions, setDynamicCategoryOptions] = useState([]);
  const [dynamicSubCategoryOptions, setDynamicSubCategoryOptions] = useState(
    []
  );

  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 450px)") || false;
  console.log("is Mobile ", isMobile);

  const extractLabelAndValues = (type, selectedLabel) => {
    if (type === "category") {
      const filteredGoal = itemGoalsNested[selectedLabel];
      if (filteredGoal) {
        setDynamicCategoryOptions(
          Object.keys(filteredGoal).map((key) => ({
            label: key,
            value: key.toLowerCase().replace(/ /g, "_"),
          }))
        );
        if (selectedCategory) {
          setSelectedCategory([]);
        }
        if (selectedSubCategory) {
          setSelectedSubCategory([]);
        }
      }
    } else if (type === "subcategory") {
      const filteredCategory =
        itemGoalsNested[selectedGoal?.label]?.[selectedLabel];
      console.log("here it came", filteredCategory);
      if (filteredCategory) {
        setDynamicSubCategoryOptions(
          filteredCategory.map((item) => ({
            label: item,
            value: item.toLowerCase().replace(/ /g, "_"),
          }))
        );
      }
      if (selectedSubCategory) {
        setSelectedSubCategory([]);
      }
    }
  };

  // STEPPER CORE
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: isMobile ? 22 : 27,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,var(--black-color) 0%,var(--black-color),var(--black-color) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,var(--black-color) 0%,var(--black-color),var(--black-color) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[500]
          : "var(--black-color)",
      borderRadius: 1,
    },
  }));
  const ColorlibStepIconRoot = React.memo(
    styled("div")(({ theme, ownerState }) => ({
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[700]
          : "var(--secondary-color)",
      zIndex: 1,
      color: "var(--white-color)",
      padding: isMobile ? ".75rem" : "1rem",
      display: "flex",
      borderRadius: !ownerState.active ? "50%" : "30px",
      justifyContent: "center",
      alignItems: "center",
      ...(ownerState.active && {
        background: "var(--secondary-color)",
        padding: isMobile ? ".6rem" : ".75rem",
        // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      }),
      ...(ownerState.completed && {
        backgroundImage:
          "linear-gradient( 95deg,var(--secondary-color) 0%,var(--secondary-color),var(--secondary-color) 100%)",
      }),
      cursor: "pointer",
      // animation: ownerState.active ? `${fadeIn} 0.3s ease-in-out` : "none",
      height: { xs: 45, md: 60 },
      minWidth: { xs: 45, md: 60 },
    }))
  );

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    let icons = null;

    icons = {
      1: <FaCircleInfo className="responsive-icon" />,
      2: <FaCircleDollarToSlot className="responsive-icon" />,
      3: <FaCalendarCheck className="responsive-icon" />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
        {active && (
          <Typography
            sx={{ fontSize: { xs: 14, md: 16, lg: 18 } }}
            marginLeft={".5rem"}
          >
            {steps[props.icon - 1]}
          </Typography>
        )}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
    index: PropTypes.any,
  };
  // END STEPPER CORE

  const isThirdPartyEntity = () => {
    if (
      userInfo &&
      userInfo?.userDetailsId &&
      userInfo?.userDetailsId?.userEmail
    ) {
      return thirdPartyAccountEntity.includes(
        userInfo?.userDetailsId?.userEmail
      );
    }
  };

  const [steps, setSteps] = useState([
    "Information",
    "Pricing",
    "Availability",
  ]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [images, setImages] = useState([]);
  const [feature, setFeature] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [itemLeaseFields, setItemLeaseFields] = useState([
    {
      initVal: { from: "", to: "" },
    },
  ]);
  const [itemLocTemp, setItemLocTemp] = useState(null);

  const [leaseCounts, setLeaseCounts] = useState(1);
  const [langLong, setLangLong] = useState({});
  const [userLocation, setUserLocation] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(
    ""
    // isThirdPartyEntity()
    //   ? {
    //       _id: "633eb7bbde5850c79a25d003",
    //       label: "Volvo",
    //       value: "volvo",
    //     }
    //   : null
  );
  const [selectedYearManufacture, setSelectedYearManufacture] = useState([]);
  const [selectedEqpToSell, setSelectedEqpToSell] = useState(false);
  const [selectedEqpForRent, setSelectedEqpForRent] = useState(false);
  const [selectedHold, setSelectedHold] = useState(false);
  const [selectedTransportation, setSelectedTransportation] = useState([]);
  const [oldId, setOldId] = useState("");
  const [fullLocation, setFullLocation] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedEnviromentalFee, setSelectedEnviromentalFee] = useState("");


  const selectRef = useRef();

  const onFocus = () => itemLocTemp && selectRef.current.inputRef.select();

  const onInputChange = (inputValue, { action }) => {
    // onBlur => setInputValue to last selected value
    // if (action === "input-blur") {
    //   setInputValue(value ? value.label : "");
    // }
    console.log(action);
    // onInputChange => update inputValue
    if (action === "input-change") {
      setInputValue(inputValue);
    }
  };

  const formRef = useRef();

  const handleAddField = () => {
    setLeaseCounts(leaseCounts + 1);
    // Add a new empty field
    setItemLeaseFields([...itemLeaseFields, { initVal: { from: "", to: "" } }]);
    // console.log("Updated Fields after add ", itemLeaseFields);
  };

  const handleDateRangeSelectt = (index, newValue) => {
    const updatedFields = [...itemLeaseFields];
    updatedFields[index].initVal = newValue;
    setItemLeaseFields(updatedFields);
    console.log(itemLeaseFields);
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...itemLeaseFields];
    updatedFields.splice(index, 1);
    setItemLeaseFields(updatedFields);
    // console.log("Updated Fields after remove ", updatedFields);
  };

  const { itemStatus, itemInfo, itemError, savedId } = useSelector(
    (state) => state.add
  );
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  const { beforeToday } = DateRangePicker;

  const [autopopulate, setAutopopulate] = useState(true);

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const removeImg = (id) => {
    if (feature === id) setFeature(0);
    let updatedImg = images.filter((img, index) => index !== id);
    setImages([...updatedImg]);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setImages([...images, ...e.dataTransfer.files]);
    }
  };

  useEffect(() => {
    if (savedId) {
      setOldId(savedId);
      dispatch(resetId());
    }
  }, [savedId]);

  if (itemInfo) {
    toast.success("Equipment Added Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    // let hang = itemInfo.itemInfo.productHanger;
    // let productStock = itemInfo.itemInfo.productStock
    // toast.success(`+${productStock * hang} Hanger Added Successfully`, toastOption);
    let token = localStorage.getItem("inktoken");

    dispatch(getuserInfo({ token }));
    dispatch(resetItem());

    navigate(`/user/equipment`, {
      state: { id: itemInfo?.itemInfo?._id, showConfetti: true },
    });

    // setTimeout(() => {
    //   navigate(0);
    // }, 5000);
  }

  useEffect(() => {
    const token = localStorage.getItem("inktoken");
    if (token) {
      if (!userInfo) dispatch(getuserInfo({ token }));
    } else {
      navigate("/signup");
    }
  });

  const populateLocation = () => {
    if (isThirdPartyEntity()) {
      formRef.current.setFieldValue(
        "itemLocation",
        userInfo.userDetailsId?.userCity +
          "," +
          userInfo.userDetailsId?.userState
      );
    } else {
      formRef.current.setFieldValue(
        "itemLocation",
        userInfo.userDetailsId.userAddress
      );
    }
    formRef.current.setFieldValue("itemCity", userInfo.userDetailsId?.userCity);
    formRef.current.setFieldValue(
      "itemState",
      userInfo.userDetailsId?.userState
    );
    formRef.current.setFieldValue(
      "itemZipCode",
      userInfo.userDetailsId?.userZipCode
    );
    setItemLocTemp({
      label: userInfo.userDetailsId.userAddress,

      value: {
        description: userInfo.userDetailsId.userAddress,
      },
    });
    if (!isThirdPartyEntity()) {
      setInputValue(
        [
          userInfo.userDetailsId.userAddress,
          userInfo.userDetailsId?.userCity,
          userInfo.userDetailsId?.userState,
          userInfo.userDetailsId?.userZipCode,
        ]
          .filter(Boolean)
          .join(", ")
      );
    } else {
      setInputValue(
        [userInfo.userDetailsId?.userCity, userInfo.userDetailsId?.userState]
          .filter(Boolean)
          .join(", ")
      );
    }
  };

  const depopulateLocation = () => {
    formRef.current.setFieldValue("itemLocation", "");
    formRef.current.setFieldValue("itemCity", "");
    formRef.current.setFieldValue("itemState", "");
    formRef.current.setFieldValue("itemZipCode", "");
    setItemLocTemp(null);
    setInputValue("");
  };

  useEffect(() => {
    if (userInfo) {
      if (isThirdPartyEntity()) {
        setSelectedBrand({
          _id: "633eb7bbde5850c79a25d003",
          label: "Volvo",
          value: "volvo",
        });
      }
      console.log(userInfo.userDetailsId);
      if (userInfo.userDetailsId && userInfo.userDetailsId.userAddress) {
        console.log(userInfo.userDetailsId.userAddress);
        populateLocation();
      }
    }
  }, [userInfo]);

  // useBeforeunload(() => 'You’ll lose your data!');

  const handleValidateSpecificFields = (fieldsToValidate) => {
    const validationPromises =
      fieldsToValidate &&
      fieldsToValidate.length > 0 &&
      fieldsToValidate.map((fieldName) =>
        formRef.current.validateField(fieldName)
      );

    Promise.all(validationPromises).then((results) => {
      const touchedFields = fieldsToValidate.reduce((acc, fieldName) => {
        acc[fieldName] = true;
        return acc;
      }, {});
      formRef.current.setTouched(touchedFields, true);

      const errors = formRef.current.errors;
      console.log("ERRORS", errors);
      if (
        Object.keys(errors).some((field) => fieldsToValidate.includes(field))
      ) {
        // alert('Some fields are invalid');
        toast.error("Please validate all the required fields", toastOption);
        return;
      } else {
        // alert("All specified fields are valid");
        saveDetails(activeStep + 2);
        window.scrollTo(0, 0);
        setActiveStep((prev) => prev + 1);
      }
    });
  };

  const handleStep = (stepp, values) => () => {
    if (activeStep === 0 && stepp > activeStep) {
      if (images.length === 0) {
        window.scrollTo(0, 0);
        toast.error("Please Upload Equipment Images", toastOption);
        return;
      } else {
        const formFieldsKeys = formFields.map((fld) => fld.formikName);
        if (
          handleValidateSpecificFields([
            "itemName",
            "itemDescription",
            "itemCategoryName",
            "itemSubCategoryName",
            "itemGoal",
            // "itemCondition",
            "itemBrand",
            "link",
            // "itemYearManufacture",
            // "itemHoursUsed",
            ...formFieldsKeys,
          ])
        ) {
          saveDetails(activeStep + 2);
          window.scrollTo(0, 0);
          setActiveStep((prev) => prev + 1);
        }
      }
    } else if (activeStep === 1 && stepp > activeStep) {
      if (
        values.itemForRent === initialValues["itemForRent"] &&
        values.itemToSell === initialValues["itemToSell"] &&
        !isThirdPartyEntity()
      ) {
        toast.error(
          "Please select either Equipment to Sell or Rent",
          toastOption
        );

        return;
      } else if (
        values.itemToSell &&
        values.itemAmount === initialValues["itemAmount"]
      ) {
        toast.error("Please select equipment amount", toastOption);
        return;
      } else if (
        values.itemForRent &&
        values.itemDailyPrice === initialValues["itemDailyPrice"] &&
        values.itemWeeklyPrice === initialValues["itemWeeklyPrice"] &&
        values.itemMonthlyPrice === initialValues["itemMonthlyPrice"]
      ) {
        if (!isThirdPartyEntity()) {
          toast.error(
            "Please select atleast one price from daily, weekly or monthly",
            toastOption
          );
        } else {
          toast.error(
            "Please select either daily price or monthly price",
            toastOption
          );
        }
        return;
      } else {
        if (!isThirdPartyEntity()) {
          handleValidateSpecificFields([
            "itemTransportationPrice",
            "itemTransportationPrice2",
          ]);
        } else {
          if (
            values["itemDailyPrice"] !== staticInitialValues.itemDailyPrice ||
            values["itemMonthlyPrice"] !== staticInitialValues.itemMonthlyPrice
          ) {
            window.scrollTo(0, 0);
            saveDetails(activeStep);
            setActiveStep(stepp);
          }
        }
      }
    } else {
      console.log(activeStep);
      saveDetails(activeStep);
      setActiveStep(stepp);
    }
  };

  const handleNext = (values) => {
    if (activeStep === 0) {
      if (images.length === 0) {
        window.scrollTo(0, 0);
        toast.error("Please Upload Equipment Images", toastOption);
        return;
      } else {
        const formFieldsKeys = formFields.map((fld) => fld.formikName);
        handleValidateSpecificFields([
          "itemName",
          "itemDescription",
          "itemCategoryName",
          "itemSubCategoryName",
          "itemGoal",
          // "itemCondition",
          "itemBrand",
          "link",
          // "itemYearManufacture",
          // "itemHoursUsed",
          ...formFieldsKeys,
        ]);
      }
    } else if (activeStep === 1) {
      if (
        values.itemForRent === initialValues["itemForRent"] &&
        values.itemToSell === initialValues["itemToSell"] &&
        !isThirdPartyEntity()
      ) {
        toast.error(
          "Please select either Equipment to Sell or Rent",
          toastOption
        );

        return;
      } else if (
        values.itemToSell &&
        values.itemAmount === initialValues["itemAmount"]
      ) {
        toast.error("Please select equipment amount", toastOption);
        return;
      } else if (
        values.itemForRent &&
        values.itemDailyPrice === initialValues["itemDailyPrice"] &&
        values.itemWeeklyPrice === initialValues["itemWeeklyPrice"] &&
        values.itemMonthlyPrice === initialValues["itemMonthlyPrice"]
      ) {
        if (!isThirdPartyEntity()) {
          toast.error(
            "Please select atleast one price from daily, weekly or monthly",
            toastOption
          );
        } else {
          toast.error(
            "Please select either daily price or monthly price",
            toastOption
          );
        }
        return;
      } else {
        if (!isThirdPartyEntity()) {
          handleValidateSpecificFields([
            "itemTransportationPrice",
            "itemTransportationPrice2",
          ]);
        } else {
          if (
            values["itemDailyPrice"] !== staticInitialValues.itemDailyPrice ||
            values["itemMonthlyPrice"] !== staticInitialValues.itemMonthlyPrice
          ) {
            window.scrollTo(0, 0);
            saveDetails(activeStep + 2);
            setActiveStep((prev) => prev + 1);
          }
        }
        // window.scrollTo(0, 0);
        // saveDetails(activeStep + 2);
        // setActiveStep((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    navigate(`/add-new-item/#/${activeStep}`);
  }, [activeStep]);

  useEffect(() => {
    const handleHashChange = () => {
      const stepFromHash = window.location.hash.replace("#/", "");
      console.log("HASH CHANGDE", stepFromHash);
      if (stepFromHash) {
        setActiveStep(Number(stepFromHash));
      }
    };

    // Listen for changes to the hash in the URL
    window.addEventListener("hashchange", handleHashChange);

    // Set the initial activeStep based on the current hash
    handleHashChange();

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [setActiveStep]);

  const saveDetails = (step) => {
    // return false;
    console.log(formRef.current.values);
    let values = { ...formRef.current.values };

    if (values.merchantFeed && !values.link) {
      toast.error("Please enter Equipment URL", toastOption);
      return false;
    }

    let payload = { ...values, images: [] };
    // console.log(process.env.REACT_APP_CLOUDINARY_API_SECRET);
    console.log(images);
    geocodeByAddress(values.itemLocation)
      .then((results) => {
        console.log(results);

        setItemLocTemp({
          label: results[0].formatted_address,
          value: { description: results[0].formatted_address },
        });
        // formRef.current.setFieldValue("itemLocation", {
        //   label: results[0].formatted_address,
        //   value: { description: results[0].formatted_address },
        // });
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        console.log("Successfully got latitude and longitude", {
          lat,
          lng,
        });

        // setImages([...payload.images])
        // let token = localStorage.getItem("inktoken");
        // if (token) {
        //   console.log(payload);
        //   console.log(langLong);
        payload["langLong"] = {
          lat: lat,
          lng: lng,
        };
        values["langLong"] = {
          lat: lat,
          lng: lng,
        };

        //     activeStep:step,
        //     pId:savedId?savedId:""
        //   };
        //   dispatch(saveItem({ token, values: payload }));
        // } else {
        //   navigate("/signup");
        // }
        // setLangLong({ lng, lat });
        // langLongs = { lng, lat };
        run();
      });

    function run() {
      if (images.length > 0) {
        // setSpinner(true);
        const timestamp = Math.round(new Date().getTime() / 1000);
        const params = {
          timestamp: timestamp,
          transformation: "f_webp,q_70,c_scale,w_1000",
          upload_preset: "closet-closest",
          // add any additional parameters here, such as transformation options
        };
        const signature = CryptoJS.SHA1(
          `timestamp=${params.timestamp}&transformation=${params.transformation}&upload_preset=${params.upload_preset}${process.env.REACT_APP_CLOUDINARY_API_SECRET}`
        ).toString();

        const uploaders = images.map(async (file, index) => {
          if (typeof file !== "string") {
            // Initial FormData
            const formData = new FormData();
            formData.append("file", file);
            formData.append("upload_preset", "closet-closest");
            formData.append("cloud_name", "closet-closest");
            formData.append("transformation", "f_webp,q_70,c_scale,w_1000");
            formData.append("signature", signature);
            formData.append("timestamp", timestamp);
            formData.append(
              "api_key",
              process.env.REACT_APP_CLOUDINARY_API_TOKEN
            );

            return axios
              .post(process.env.REACT_APP_CLOUDINARY_API_URL, formData, {
                headers: { "X-Requested-With": "XMLHttpRequest" },
              })
              .then((response) => {
                const data = response.data;
                let fileURL = data.url; // You should store this URL for future references in your app
                // in this check if there is https and if http then replace it with https
                if (fileURL.includes("http://")) {
                  fileURL = fileURL.replace("http://", "https://");
                }
                if (index === feature)
                  payload = {
                    ...values,
                    images: [fileURL, ...payload.images],
                  };
                else
                  payload = {
                    ...values,
                    images: [...payload.images, fileURL],
                  };
              })
              .catch((e) => {
                console.log(e);
                throw new Error("Something went Wrong!!!!");
              });
          } else {
            if (index === feature)
              payload = { ...values, images: [file, ...payload.images] };
            else payload = { ...values, images: [...payload.images, file] };
          }
        });

        axios
          .all(uploaders)
          .then(() => {
            setImages([...payload.images]);
            let token = localStorage.getItem("inktoken");
            if (token) {
              console.log(payload);
              console.log(langLong);
              payload = {
                ...payload,
                itemLeaseFields,
                activeStep: step,
                pId: oldId ? oldId : "",
              };
              dispatch(saveItem({ token, values: payload }));
            } else {
              navigate("/signup");
            }

            // setValues(payload);
            // payload.color = colors;

            // setSubmitting(false);
          })
          .catch((e) => {
            console.log(e);
            toast.error("Something Went Wrong Please Try Again", toastOption);
            // dispatch(addBrand({ token, values: { brandName }, requestType: "get" }));
          });
      }
    }
  };

  const staticInitialValues = {
    itemName: "",
    itemDescription: "",
    itemCategoryName: "", //changed from [] to ""
    itemSubCategoryName: "", //changed from [] to ""
    itemGoal: "",
    itemCondition: "",
    itemBrand: !isThirdPartyEntity() ? "" : "",
    itemYearManufacture: "",
    itemAmount: "",
    itemLocation: userLocation ? userLocation : "",
    itemLease: [],
    itemToSell: false,
    itemForRent: thirdPartyAccountEntity.includes(
      userInfo.userDetailsId.userEmail
    )
      ? true
      : false,
    itemDailyPrice: "",
    itemWeeklyPrice: "",
    itemMonthlyPrice: "",
    itemCleaningPrice: "",
    itemFuelPrice: "",
    itemMaintenancePrice: "",
    itemToHold: false,
    cancellation: false,
    cancellationDate: "3",
    itemAvailability: "always",
    itemHoursUsed: "",
    itemCity: "",
    itemState: "",
    itemZipCode: "",
    chargesIncurredBySeller: false,
    itemTransportationPrice: "",
    itemTransportationPrice2: "",
    merchantFeed: thirdPartyAccountEntity.includes(
      userInfo.userDetailsId.userEmail
    )
      ? true
      : false,
    link: "",
    attachments: [],
    itemEnvironmentalFee: "",
  };

  console.log("user info ", userInfo);

  const staticValidationSchema = Yup.object().shape({
    itemName: Yup.string().required("Please enter Equipment Name"),
    // itemType: Yup.string().required("Please enter Equipment Type"),
    itemDescription: Yup.string().required(
      "Please enter your Equipment Description"
    ),
    itemGoal: Yup.string().required("Please select equipment category"),
    itemCategoryName: Yup.string().required(
      "Please select equipment sub-category"
    ),
    itemSubCategoryName: Yup.string().required("Please select equipment type"),
    itemCondition: Yup.string().required("Please select Equipment Condition"),
    itemBrand: Yup.string().required("Please select Equipment Brand"),
    itemYearManufacture: Yup.string()
      .matches(/^(19|20)\d{2}$/, "Please enter a valid year (e.g., 2023)")
      .required("Please select Equipment Manufacture (year)"),
    itemAmount: Yup.number()
      .typeError("Amount must be a number")
      .when("itemToSell", {
        is: true,
        then: (schema) => schema.required("Amount is required"),
        // otherwise: (schema) => schema.number(),
      }),
    itemLocation: Yup.string().required('Please enter Equipment Location"'),
    itemDailyPrice: Yup.number().typeError(
      "Equipment Daily Price must be a number"
    ),
    itemWeeklyPrice: Yup.number().typeError(
      "Equipment Weekly Price must be a number"
    ),
    itemMonthlyPrice: Yup.number().typeError(
      "Equipment Monthly Price must be a number"
    ),
    itemMaintenancePrice: Yup.number().typeError(
      "Equipment Maintenance Price must be a number"
    ),
    itemFuelPrice: Yup.number().typeError(
      "Equipment Fuel Price must be a number"
    ),
    itemCleaningPrice: Yup.number()
      .typeError("Equipment Cleaning Price must be a number")
      .max(1000, "Equipment Cleaning Price cannot exceed $1000"),
    itemTransportationPrice: Yup.number()
      .required("Please enter Transportation price")
      .typeError("Transportation price must be a number"),
    itemTransportationPrice2: Yup.number()
      .required("Please enter Transportation price")
      .typeError("Transportation price must be a number"),
    // itemForRent: Yup.boolean().test(
    //   "atLeastOnePriceRequired",
    //   "At least one of the rental prices is required",
    //   function (value) {
    //     if (value) {
    //       const { itemDailyPrice, itemWeeklyPrice, itemMonthlyPrice } =
    //         this.parent;
    //       if (!itemDailyPrice && !itemWeeklyPrice && !itemMonthlyPrice) {
    //         return this.createError({
    //           message: "At least one of the rental prices is required",
    //           path: "itemRent",
    //         });
    //       }
    //     }
    //     return true;
    //   }
    // ),
    itemHoursUsed: Yup.number()
      .min(1, "Hours Used should be greater than 0")
      .required("Please enter no. of hours used")
      .typeError("Hours Used must be a number"),
    link: Yup.string().when("merchantFeed", {
      is: true,
      then: () =>
        Yup.string()
          .required("Please enter Equipment URL")
          .url("Invalid URL format"),
      otherwise: () => Yup.string().url("Invalid URL format"),
    }),
  });

  const staticValidationForThirdPartyEntity = Yup.object().shape({
    itemName: Yup.string().required("Please enter Equipment Name"),
    // itemType: Yup.string().required("Please enter Equipment Type"),
    itemDescription: Yup.string().required(
      "Please enter your Equipment Description"
    ),
    itemGoal: Yup.string().required("Please select equipment category"),
    itemCategoryName: Yup.string().required(
      "Please select equipment sub-category"
    ),
    itemSubCategoryName: Yup.string().required("Please select equipment type"),

    itemAmount: Yup.number()
      .typeError("Amount must be a number")
      .when("itemToSell", {
        is: true,
        then: (schema) => schema.required("Amount is required"),
        // otherwise: (schema) => schema.number(),
      }),
    itemLocation: Yup.string().required('Please enter Equipment Location"'),
    itemDailyPrice: Yup.number().typeError(
      "Equipment Daily Price must be a number"
    ),
    itemWeeklyPrice: Yup.number().typeError(
      "Equipment Weekly Price must be a number"
    ),
    itemMonthlyPrice: Yup.number().typeError(
      "Equipment Monthly Price must be a number"
    ),
    itemBrand: Yup.string(),

    // itemForRent: Yup.boolean().test(
    //   "atLeastOnePriceRequired",
    //   "At least one of the rental prices is required",
    //   function (value) {
    //     if (value) {
    //       const { itemDailyPrice, itemWeeklyPrice, itemMonthlyPrice } =
    //         this.parent;
    //       if (!itemDailyPrice && !itemWeeklyPrice && !itemMonthlyPrice) {
    //         return this.createError({
    //           message: "At least one of the rental prices is required",
    //           path: "itemRent",
    //         });
    //       }
    //     }
    //     return true;
    //   }
    // ),

    link: Yup.string()
      .required("Please enter Equipment URL")
      .url("Invalid URL format"),
  });

  // const [initialValues, setInitialValues] = useState({
  //   ...staticInitialValues,
  //   ...formFields.reduce((acc, field) => {
  //     acc[field.formikName] = '';
  //     return acc;
  //   }, {})
  // });
  const [formFields, setFormFields] = useState([]);
  const [initialValues, setInitialValues] = useState(staticInitialValues);
  const [validationSchema, setValidationSchema] = useState(
    !isThirdPartyEntity()
      ? staticValidationSchema
      : staticValidationForThirdPartyEntity
  );
  const [loadingForFields, setLoadingForFields] = useState(false);

  const [dynamicAttachment, setDynamicAttachment] = useState([]);

  const { fieldsInfo, fieldStatus } = useSelector(
    (state) => state.productfields
  );

  const fetchFormFields = (goal, category, subCategory) => {
    setLoadingForFields(true);
    dispatch(
      getProductFields({
        goal: goal,
        category: category,
        subCategory: subCategory,
      })
    );
  };

  const toCamelCase = (str) => {
    return (
      "fld" +
      str
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove non-alphanumeric characters including parentheses
        .toLowerCase() // Convert the entire string to lowercase
        .split(" ") // Split the string by spaces
        .map((word, index) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word except the first word
        .join("") // Join the words back together
    );
  };

  const removeKeys = (obj, keysToRemove) => {
    const newObj = { ...obj };
    keysToRemove.forEach((key) => delete newObj[key]);
    return newObj;
  };

  const mergeInitialValues = (existingValues, newFields) => {
    const newValues = {};
    newFields.forEach((field) => {
      if (!(field in existingValues)) {
        newValues[field] = "";
      }
    });
    return { ...existingValues, ...newValues };
  };

  const generateDynamicFieldsValidationSchema = (fields) => {
    const schema = {};

    fields.forEach((field) => {
      let validator = Yup.string().required("This field is required");

      if (field.type === "text") {
        validator = Yup.number()
          .required(`Please enter ${field.name}`)
          .typeError(`${field.name} must be a numeric values`);
      } else if (field.type === "select") {
        validator = Yup.string()
          .oneOf(field.options, "Invalid option")
          .required(`Please select ${field.name}`);
      }
      // else if (field.type === "autocompleteWithCreateOption") {
      //   validator = Yup.string().required(`Please select ${field.name}`);
      // }
      // Add other field types if needed

      schema[field.formikName] = validator;
    });

    return Yup.object().shape(schema);
  };

  useEffect(() => {
    if (formFields && formFields.length > 0) {
      const currentFormikValues = formRef.current?.values || {};
      const keysToRemove = formFields.map((fld) => fld.formikName);
      const updatedValues = removeKeys(currentFormikValues, keysToRemove);
      setInitialValues(updatedValues);
      setFormFields([]);
      setValidationSchema(
        !isThirdPartyEntity()
          ? staticValidationSchema
          : staticValidationForThirdPartyEntity
      );
      formRef.current.values = updatedValues;
    }
  }, [selectedCategory, selectedSubCategory, selectedGoal]);

  useEffect(() => {
    if (formRef.current.values.attachments?.length > 0) {
      formRef.current.values.attachments = [];
    }
  }, [selectedGoal, selectedCategory]);

  useEffect(() => {
    if (fieldsInfo) {
      // console.log("Fields Info ", fieldsInfo);
      let tempFieldsInfo = fieldsInfo;
      if (isThirdPartyEntity()) {
        tempFieldsInfo = fieldsInfo?.fields.filter(
          (fld) => fld.name === "Size"
        );
      } else {
        tempFieldsInfo = fieldsInfo?.fields.filter((fld) => fld);
      }
      const mappedFields =
        tempFieldsInfo && tempFieldsInfo.length > 0
          ? tempFieldsInfo?.map((field) => ({
              ...field,
              formikName: toCamelCase(field.name),
            }))
          : [];

      // if (mappedFields) {
      const prevFormFieldsToRemove = formFields || [];
      setFormFields(mappedFields);
      if (prevFormFieldsToRemove && prevFormFieldsToRemove.length > 0) {
        const keysToAdd = mappedFields.map((fld) => fld.formikName);
        const currentFormikValues = formRef.current?.values || {};
        const keysToRemove = prevFormFieldsToRemove.map(
          (fld) => fld.formikName
        );
        const updatedValues = removeKeys(currentFormikValues, keysToRemove);
        const merged = mergeInitialValues(updatedValues, keysToAdd);
        setInitialValues(merged);
        formRef.current.values = merged;
        console.log("final formik", merged);
      } else {
        mappedFields.forEach((field) => {
          formRef.current.values[field.formikName] = "";
        });
      }
      const dynamicValidationSchema =
        generateDynamicFieldsValidationSchema(mappedFields);
      setValidationSchema(
        !isThirdPartyEntity()
          ? staticValidationSchema.concat(dynamicValidationSchema)
          : staticValidationForThirdPartyEntity.concat(dynamicValidationSchema)
      );
      dispatch(resetFields());
      setLoadingForFields(false);
    }
    // }
  }, [fieldsInfo]);

  const calculateTotalForPreviewForBoth = (type) => {
    const {
      itemAmount,
      itemTransportationPrice,
      itemDailyPrice,
      itemWeeklyPrice,
      itemMonthlyPrice,
      itemCleaningPrice,
      itemFuelPrice,
      itemMaintenancePrice,
      itemEnvironmentalFee,
    } = formRef.current.values;
    let totalForSell = 0;
    let totalForRent = 0;
    if (type === "buy") {
      totalForSell += itemAmount ? parseFloat(itemAmount) : 0;
      totalForSell += itemTransportationPrice
        ? parseFloat(itemTransportationPrice)
        : 0;
    } else if (type === "rent") {
      if (itemDailyPrice) {
        totalForRent += parseFloat(itemDailyPrice);
      } else if (itemWeeklyPrice) {
        totalForRent += parseFloat(itemWeeklyPrice);
      } else if (itemMonthlyPrice) {
        totalForRent += parseFloat(itemMonthlyPrice);
      }

      totalForRent += itemCleaningPrice ? parseFloat(itemCleaningPrice) : 0;
      totalForRent += itemFuelPrice ? parseFloat(itemFuelPrice) : 0;
      totalForRent += itemMaintenancePrice
        ? parseFloat(itemMaintenancePrice)
        : 0;
      totalForRent += itemTransportationPrice
        ? parseFloat(itemTransportationPrice * 2)
        : 0;
      if (itemEnvironmentalFee)
        totalForRent += (totalForRent * parseInt(itemEnvironmentalFee)) / 100;
    }
    // console.log("Total for preview", total);
    return type === "buy"
      ? totalForSell.toFixed(2).toLocaleString("en-us")
      : totalForRent.toFixed(2).toLocaleString("en-us");
  };

  const baseStructure = [
    { formikName: "attchName", name: "Name", type: "text", colSize: 12 },
    {
      formikName: "attchDesc",
      name: "Description",
      type: "text",
      rows: 4,
      colSize: 12,
    },
    {
      formikName: "attchGoal",
      name: "Category",
      type: "select",
      options: goalOptions,
      defaultValue: selectedGoal,
      colSize: 6,
    },
    {
      formikName: "attchCategory",
      name: "Sub-category",
      type: "select",
      options: dynamicCategoryOptions,
      defaultValue:
        selectedGoal?.label === "Earth Manipulation"
          ? {
              label: "Attachments",
              value: "Attachments",
            }
          : {
              label: "Lift Attachments",
              value: "Lift Attachments",
            },
      colSize: 6,
    },
    {
      formikName: "attchSubCategory",
      name: "Type",
      type: "select",
      options:
        selectedGoal?.label === "Earth Manipulation"
          ? earthManipulationAttachments.map((key) => ({
              label: key,
              value: key,
            }))
          : liftingAttachments.map((key) => ({
              label: key,
              value: key,
              // value: key.toLowerCase().replace(/ /g, "_"),
            })),
      defaultValue: selectedSubCategory,
      colSize: 12,
    },
    {
      formikName: "attchCondition",
      name: "Condition",
      type: "select",
      options: conditions,
      colSize: 6,
    },
    {
      formikName: "attchYearManufactured",
      name: "Year Manufactured",
      type: "select",
      options: years,
      colSize: 6,
    },
    {
      formikName: "attchHoursUsed",
      name: "Hours Used",
      type: "text",
      colSize: 6,
    },
    {
      formikName: "attchSize",
      name: "Size",
      type: "text",
      colSize: 12,
    },
  ];

  const [dynamicAttchCount, setDynamicAttchCount] = useState(0);

  const addDynamicAttachment = () => {
    setDynamicAttchCount((prev) => prev + 1);
    if (formRef.current) {
      let updatedBaseStructure = baseStructure;
      const currentAttachments = formRef.current.values.attachments || [];

      const transformedBaseStructure = updatedBaseStructure.reduce(
        (acc, field) => {
          acc[field.formikName] = "";
          if (field.formikName === "attchGoal") {
            acc[field.formikName] = selectedGoal?.label;
          } else if (field.formikName === "attchCategory") {
            acc[field.formikName] =
              selectedGoal?.label === "Earth Manipulation"
                ? "Attachments"
                : "Lift Attachments";
          } else if (field.formikName === "attchSize") {
            if (formFields && formFields.length > 0) {
              let sizeAttachment = formFields.filter(
                (fld) => fld.name === "Size"
              );
              if (sizeAttachment) {
                sizeAttachment = sizeAttachment[0];
                // sizeAttachment.formikName = "attchSize";
                // acc[field.formikName] = "attchSize";
                field.type = sizeAttachment.type;
              }
            }
          }
          return acc;
        },
        {}
      );
      console.log(
        "transformedBaseStructure",
        transformedBaseStructure,
        currentAttachments
      );
      formRef.current.setFieldValue("attachments", [
        ...currentAttachments,
        transformedBaseStructure,
      ]);
    }
    // formRef.current.values = [...formRef.current.values, dynamicAttachments: dy]
  };

  const deleteAttachment = (attachmentIndex) => {
    const updatedAttachments = formRef.current.values.attachments.filter(
      (_, index) => index !== attachmentIndex
    );
    formRef.current.setFieldValue("attachments", updatedAttachments);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // initialErrors={{
        //   itemName: "Please enter Equipment Name",
        //   itemDescription: "Please enter your Equipment Description",
        //   itemGoal: "Please select Equipment Category",
        //   itemCategoryName: "Please select Equipment Sub-category",
        //   itemSubCategoryName: "Please select Equipment Type",
        //   itemCondition: "Please select Equipment Condition",
        //   itemBrand: "Please select Equipment Brand",
        //   itemYearManufacture: "Please enter Equipment Manufacture",
        //   itemLocation: "Please enter Equipment Location",
        //   transportation: "Please select selivery",
        //   itemHoursUsed: "Please enter Number of Hours",
        // }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          // debugger;
          console.log("SUbmitting", values);
          console.log(itemLeaseFields, langLong);
          let langLongs;
          if (
            itemLeaseFields &&
            itemLeaseFields.length > 0 &&
            !itemLeaseFields[0].initVal[0] &&
            values.itemAvailability === "except"
          ) {
            toast.error("Please Select Lease Date", toastOption);
            return;
          }
          if (Object.keys(langLong).length === 0) {
            if (values.itemLocation) {
              geocodeByAddress(values.itemLocation)
                .then((results) => {
                  console.log(results);

                  setItemLocTemp({
                    label: results[0].formatted_address,
                    value: { description: results[0].formatted_address },
                  });
                  // formRef.current.setFieldValue("itemLocation", {
                  //   label: results[0].formatted_address,
                  //   value: { description: results[0].formatted_address },
                  // });
                  return getLatLng(results[0]);
                })
                .then(({ lat, lng }) => {
                  console.log("Successfully got latitude and longitude", {
                    lat,
                    lng,
                  });
                  setLangLong({ lng, lat });
                  langLongs = { lng, lat };
                  run();
                });
            } else {
              toast.error("Please Select Correct Location !!", toastOption);
              setItemLocTemp(null);
              return;
            }
          } else {
            run();
          }

          function run() {
            let payload = { ...values, images: [] };
            // console.log(process.env.REACT_APP_CLOUDINARY_API_SECRET);
            console.log(images);
            if (images.length > 0) {
              // setSpinner(true);
              const timestamp = Math.round(new Date().getTime() / 1000);
              const params = {
                timestamp: timestamp,
                transformation: "f_webp,q_70,c_scale,w_1000",
                upload_preset: "closet-closest",
                // add any additional parameters here, such as transformation options
              };
              const signature = CryptoJS.SHA1(
                `timestamp=${params.timestamp}&transformation=${params.transformation}&upload_preset=${params.upload_preset}${process.env.REACT_APP_CLOUDINARY_API_SECRET}`
              ).toString();

              const uploaders = images.map(async (file, index) => {
                // Initial FormData
                if (typeof file !== "string") {
                  const formData = new FormData();
                  formData.append("file", file);
                  formData.append("upload_preset", "closet-closest");
                  formData.append("cloud_name", "closet-closest");
                  formData.append(
                    "transformation",
                    "f_webp,q_70,c_scale,w_1000"
                  );
                  formData.append("signature", signature);
                  formData.append("timestamp", timestamp);
                  formData.append(
                    "api_key",
                    process.env.REACT_APP_CLOUDINARY_API_TOKEN
                  );

                  return axios
                    .post(process.env.REACT_APP_CLOUDINARY_API_URL, formData, {
                      headers: { "X-Requested-With": "XMLHttpRequest" },
                    })
                    .then((response) => {
                      const data = response.data;
                      let fileURL = data.url; // You should store this URL for future references in your app
                      if (fileURL.includes("http://")) {
                        fileURL = fileURL.replace("http://", "https://");
                      }
                      if (index === feature)
                        payload = {
                          ...values,
                          images: [fileURL, ...payload.images],
                        };
                      else
                        payload = {
                          ...values,
                          images: [...payload.images, fileURL],
                        };
                    })
                    .catch((e) => {
                      console.log(e);
                      throw new Error("Something went Wrong!!!!");
                    });
                } else {
                  if (index === feature)
                    payload = { ...values, images: [file, ...payload.images] };
                  else
                    payload = { ...values, images: [...payload.images, file] };
                }
              });

              axios
                .all(uploaders)
                .then(() => {
                  // setValues(payload);
                  // payload.color = colors;
                  let token = localStorage.getItem("inktoken");
                  if (token) {
                    console.log(payload);
                    console.log(langLong);
                    if (Object.keys(langLong).length !== 0) {
                      langLongs = langLong;
                    }
                    payload = {
                      ...payload,
                      itemLeaseFields,
                      langLong: langLongs,
                      pId: oldId ? oldId : "",
                    };
                    dispatch(addItem({ token, values: payload }));
                  } else {
                    navigate("/signup");
                  }

                  // setSubmitting(false);
                })
                .catch((e) => {
                  toast.error(
                    "Something Went Wrong Please Try Again",
                    toastOption
                  );
                  // dispatch(addBrand({ token, values: { brandName }, requestType: "get" }));
                });
            } else {
              toast.error("Please upload Atlease one Image", toastOption);
            }
          }

          // setSpinner(true);
        }}
        innerRef={formRef}
        render={(props) => {
          let {
            setFieldValue,
            values,
            handleBlur,
            handleChange,
            touched,
            errors,
            handleSubmit,
            setErrors,
            setFieldTouched,
            setFieldError,
          } = props;
          return (
            <>
              <Container
                maxWidth={"lg"}
                sx={{ margin: "2rem auto", minHeight: "100vh" }}
                data-aos="fade-in"
                data-aos-duration="1000"
              >
                {/* <Typography variant="h1" className="mont-title">
              Add New Equipment
            </Typography> */}
                {/* <Divider sx={{ marginBottom: "2rem" }} /> */}
                <Stack
                  sx={{ width: "100%" }}
                  data-aos="fade-down"
                  data-aos-duration="1200"
                  marginTop={"2rem"}
                >
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel={true}
                    connector={<ColorlibConnector />}
                    style={{ marginBottom: "2rem" }}
                  >
                    {steps.map((label, index) => (
                      <Step
                        key={label}
                        onClick={handleStep(index, values)}
                        completed={completed[index]}
                      >
                        <StepLabel
                          sx={{ paddingTop: isMobile ? "4px" : "0" }}
                          StepIconComponent={ColorlibStepIcon}
                        ></StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Stack>
                <form onSubmit={handleSubmit}>
                  {activeStep + 1 === 1 && (
                    <>
                      <Grid container>
                        <Grid item xs={12}>
                          {images.length > 0 ? (
                            <div className="preview_image">
                              <div className="item_title">
                                Image Preview - Please Select the Feature Photo
                              </div>
                              <div className="preview_image_container">
                                <div
                                  className="img_name_cont"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexWrap: "wrap",
                                    margin: "10px 0px",
                                  }}
                                >
                                  {images.map((image, index) => {
                                    return (
                                      <div
                                        className={`img_icon_cont`}
                                        key={index}
                                      >
                                        <div className="img_name">
                                          <div
                                            className="cross"
                                            onClick={() => removeImg(index)}
                                          >
                                            X
                                          </div>
                                          <img
                                            className={`${
                                              feature === index ? "feature" : ""
                                            }`}
                                            onClick={() => setFeature(index)}
                                            src={
                                              typeof image === "string"
                                                ? image
                                                : URL.createObjectURL(image)
                                            }
                                            alt={image.name}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="img_upload_container">
                            <div className="file_icon_container">
                              <div className="item_title ">
                                Take or Choose Photos *
                              </div>
                              <label
                                htmlFor="closet_icon"
                                className={`file_upload ${
                                  dragActive ? "drag-active" : ""
                                }`}
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              >
                                <div className="icon_container add_icon_container">
                                  <AddAPhoto color="primary" />
                                  <p className="item_title" style={{}}>
                                    {" "}
                                    or drag them in
                                  </p>
                                </div>
                              </label>
                              <input
                                type="file"
                                multiple={true}
                                id="closet_icon"
                                hidden
                                onChange={(e) => {
                                  // console.log(
                                  //   URL.createObjectURL(e.target.files[0])
                                  // );
                                  setImages([...images, ...e.target.files]);
                                }}
                                style={{ borderRadius: "10px" }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      {userInfo.role === "admin" && !isThirdPartyEntity() && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "2rem",
                            }}
                          >
                            <Typography
                              variant="h3"
                              fontWeight={700}
                              className="mont-title"
                              marginBottom={1}
                            >
                              Admin Access
                            </Typography>
                          </Box>

                          <Grid item xs={12} marginTop={"0"}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e, val) => {
                                    if (val === false && values.link !== "") {
                                      setFieldValue("link", "");
                                    }
                                    setFieldValue("merchantFeed", val);
                                  }}
                                  onBlur={handleBlur}
                                  checked={values.merchantFeed}
                                  name="merchantFeed"
                                />
                              }
                              label="Is it Third Party?"
                            />
                          </Grid>

                          {values.merchantFeed && (
                            <Grid item xs={12} marginTop={"1rem"}>
                              <TextField
                                error={touched.link && Boolean(errors.link)}
                                required
                                name="link"
                                id="link"
                                label="Third Party Provider Link"
                                defaultValue=""
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.link}
                                helperText={
                                  (touched.link && errors.link) ||
                                  "https://abc.com/equipment"
                                } //required in case of error msg
                                sx={{ width: "100%" }}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                      {isThirdPartyEntity() && userInfo.role !== "admin" && (
                        <Grid item xs={12} marginTop={"1rem"}>
                          <TextField
                            error={touched.link && Boolean(errors.link)}
                            required
                            name="link"
                            id="link"
                            label="Third Party Provider Link"
                            defaultValue=""
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.link}
                            helperText={
                              (touched.link && errors.link) ||
                              "https://abc.com/equipment"
                            } //required in case of error msg
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      )}
                      <Grid
                        container
                        spacing={4}
                        sx={{ flexGrow: 1 }}
                        marginTop={"0rem"}
                      >
                        <Grid item xs={12} marginTop={"0rem"}>
                          <TextField
                            error={touched.itemName && Boolean(errors.itemName)}
                            required
                            name="itemName"
                            id="name"
                            label="Name"
                            defaultValue=""
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.itemName}
                            helperText={touched.itemName && errors.itemName} //required in case of error msg
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <TextField
                        required
                        fullWidth
                        error={
                          touched.itemDescription &&
                          Boolean(errors.itemDescription)
                        }
                        name="itemDescription" //true if error exists
                        id="description"
                        label="Description"
                        multiline
                        minRows={5}
                        placeholder="Enter Description"
                        defaultValue=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.itemDescription}
                        helperText={
                          touched.itemDescription && errors.itemDescription
                        }
                        sx={{ width: "100%", margin: "1rem auto" }}
                      />
                      {/* <Grid
                    container
                    spacing={4}
                    sx={{ flexGrow: 1 }}
                    marginTop={"0rem"}
                  > */}
                      {/* <Grid item xs={12}> */}

                      {/* </Grid> */}
                      {/* </Grid> */}
                      <Grid
                        container
                        spacing={isMobile ? 1 : 4}
                        sx={{ flexGrow: 1 }}
                        marginBottom={"1rem"}
                      >
                        <Grid item xs={6}>
                          <Mautocomplete
                            onOpen={handleBlur}
                            fullWidth
                            id="combo-box-goal"
                            options={goalOptions}
                            onChange={(e, value) => {
                              setFieldValue("itemGoal", value?.label);
                              setSelectedGoal(value);
                              extractLabelAndValues("category", value?.label);
                            }}
                            value={selectedGoal}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category"
                                name="itemGoal"
                                error={Boolean(
                                  touched.itemGoal && errors.itemGoal
                                )}
                                helperText={touched.itemGoal && errors.itemGoal}
                                required
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Mautocomplete
                            noOptionsText="Please select category first..."
                            onOpen={handleBlur}
                            // multiple
                            id="combo-box-category"
                            options={dynamicCategoryOptions}
                            sx={{ width: "100%" }}
                            onChange={(e, value) => {
                              // const selectedIds =
                              //   value.length > 0
                              //     ? value.map((option) => option.value)
                              //     : [];
                              setFieldValue("itemCategoryName", value?.label);
                              setSelectedCategory(value);
                              extractLabelAndValues(
                                "subcategory",
                                value?.label
                              );
                            }}
                            value={selectedCategory}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="itemCategoryName"
                                label="Sub-category"
                                error={Boolean(
                                  touched.itemCategoryName &&
                                    errors.itemCategoryName
                                )}
                                helperText={
                                  touched.itemCategoryName &&
                                  errors.itemCategoryName
                                }
                                required
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Mautocomplete
                        onOpen={handleBlur}
                        // multiple
                        noOptionsText="Please select sub-category first..."
                        id="combo-box-sub-category"
                        options={dynamicSubCategoryOptions}
                        sx={{ width: "100%", margin: "1rem 0", marginTop: 0 }}
                        onChange={(e, value) => {
                          // const selectedIds =
                          //   value.length > 0
                          //     ? value.map((option) => option.value)
                          //     : [];
                          setFieldValue("itemSubCategoryName", value?.label);
                          setSelectedSubCategory(value);
                          // if (!isThirdPartyEntity()) {
                          if (value) {
                            fetchFormFields(
                              values.itemGoal,
                              values.itemCategoryName,
                              value?.label
                            );
                          }
                          // }
                        }}
                        value={selectedSubCategory}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Type"
                            name="itemSubCategoryName"
                            error={Boolean(
                              touched.itemSubCategoryName &&
                                errors.itemSubCategoryName
                            )}
                            helperText={
                              touched.itemSubCategoryName &&
                              errors.itemSubCategoryName
                            }
                            required
                          />
                        )}
                      />
                      {!isThirdPartyEntity() ? (
                        <>
                          <Grid
                            container
                            rowSpacing={isMobile ? 4 : 0}
                            spacing={isMobile ? 1 : 4}
                            sx={{
                              flexGrow: 1,
                              marginBottom: "1rem",
                              marginTop: "-2rem",
                            }}
                          >
                            <Grid item xs={6}>
                              <Mautocomplete
                                onOpen={handleBlur}
                                disablePortal
                                fullWidth
                                id="combo-box-conditions"
                                options={conditions}
                                onChange={(e, value) => {
                                  setFieldValue("itemCondition", value?.value);
                                  setSelectedCondition(value);
                                }}
                                value={selectedCondition}
                                sx={{ width: "100%", marginTop: 0 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Condition"
                                    name="itemCondition"
                                    error={Boolean(
                                      touched.itemCondition &&
                                        errors.itemCondition
                                    )}
                                    helperText={
                                      touched.itemCondition &&
                                      errors.itemCondition
                                    }
                                    required
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Mautocomplete
                                onOpen={handleBlur}
                                disablePortal
                                fullWidth
                                id="combo-box-brands"
                                options={brands}
                                onChange={(e, value) => {
                                  setFieldValue("itemBrand", value?._id);
                                  setSelectedBrand(value);
                                }}
                                value={selectedBrand}
                                sx={{ width: "100%" }}
                                filterOptions={(options, state) => {
                                  const filtered = options.filter((option) =>
                                    option.label
                                      .toLowerCase()
                                      .includes(state.inputValue.toLowerCase())
                                  );

                                  if (
                                    filtered.length === 0 &&
                                    state.inputValue !== ""
                                  ) {
                                    filtered.push({
                                      _id: "65ceab790a582b9f43f4a880",
                                      label: "Other",
                                      value: "other",
                                    });
                                  }

                                  return filtered;
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Brand"
                                    name="itemBrand"
                                    error={Boolean(
                                      touched.itemBrand && errors.itemBrand
                                    )}
                                    helperText={
                                      touched.itemBrand && errors.itemBrand
                                    }
                                    required
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={isMobile ? 1 : 4}
                            sx={{ flexGrow: 1 }}
                            // marginTop={"1rem"}
                            marginBottom={".75rem"}
                          >
                            <Grid item xs={6}>
                              <Mautocomplete
                                onOpen={handleBlur}
                                disablePortal
                                fullWidth
                                id="combo-box-year"
                                options={years.sort(
                                  (a, b) =>
                                    parseInt(b.label) - parseInt(a.label)
                                )}
                                onChange={(e, value) => {
                                  setFieldValue(
                                    "itemYearManufacture",
                                    value?.value
                                  );
                                  setSelectedYearManufacture(value);
                                }}
                                value={selectedYearManufacture}
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Year Manufactured"
                                    name="itemYearManufacture"
                                    error={Boolean(
                                      touched.itemYearManufacture &&
                                        errors.itemYearManufacture
                                    )}
                                    helperText={
                                      touched.itemYearManufacture &&
                                      errors.itemYearManufacture
                                    }
                                    required
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                name="itemHoursUsed"
                                error={
                                  touched.itemHoursUsed &&
                                  Boolean(errors.itemHoursUsed)
                                }
                                helperText={
                                  touched.itemHoursUsed && errors.itemHoursUsed
                                }
                                required
                                id="itemHoursUsed"
                                label="Hours Used"
                                defaultValue=""
                                // InputProps={{
                                //   startAdornment: (
                                //     <InputAdornment position="start">
                                //       <FaStopwatch />
                                //     </InputAdornment>
                                //   ),
                                // }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.itemHoursUsed}
                                sx={{ width: "100%" }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                        // <Grid
                        //   container
                        //   spacing={4}
                        //   rowSpacing={0}
                        //   sx={{
                        //     flexGrow: 1,
                        //     marginBottom: "1rem",
                        //     marginTop: "-2rem",
                        //   }}
                        // >
                        //   <Grid item xs={12}>
                        //     <Mautocomplete
                        //       onOpen={handleBlur}
                        //       disablePortal
                        //       fullWidth
                        //       id="combo-box-brands"
                        //       options={brands}
                        //       onChange={(e, value) => {
                        //         console.log("selected brand ", value);
                        //         setFieldValue("itemBrand", value?._id);
                        //         setSelectedBrand(value);
                        //       }}
                        //       value={selectedBrand}
                        //       sx={{ width: "100%" }}
                        //       filterOptions={(options, state) => {
                        //         const filtered = options.filter((option) =>
                        //           option.label
                        //             .toLowerCase()
                        //             .includes(state.inputValue.toLowerCase())
                        //         );

                        //         if (
                        //           filtered.length === 0 &&
                        //           state.inputValue !== ""
                        //         ) {
                        //           filtered.push({
                        //             _id: "65ceab790a582b9f43f4a880",
                        //             label: "Other",
                        //             value: "other",
                        //           });
                        //         }

                        //         return filtered;
                        //       }}
                        //       renderInput={(params) => (
                        //         <TextField
                        //           {...params}
                        //           label="Brand"
                        //           name="itemBrand"
                        //           error={Boolean(
                        //             touched.itemBrand && errors.itemBrand
                        //           )}
                        //           helperText={
                        //             touched.itemBrand && errors.itemBrand
                        //           }
                        //           required
                        //         />
                        //       )}
                        //     />
                        //   </Grid>
                        // </Grid>
                      )}
                      {/* <Grid
                        item
                        xs={12}
                        marginTop={"1rem"}
                        marginBottom={".75rem"}
                      >
                        
                      </Grid> */}
                      {/* <FormikSelect /> */}
                      <Grid container columnSpacing={4} rowSpacing={2}>
                        {loadingForFields ? (
                          <Grid item>
                            <Stack alignItems={"center"} direction={"row"}>
                              <CircularProgress
                                size={"1em"}
                                color="secondary"
                              />{" "}
                              <Typography
                                color={"secondary"}
                                marginLeft={"1rem"}
                              >
                                Please Wait...
                              </Typography>
                            </Stack>
                          </Grid>
                        ) : (
                          <>
                            {/* {formFields && formFields.length > 0 && (
                              <Grid
                                xs={12}
                                marginTop={"2rem"}
                                marginBottom={"1rem"}
                              >
                                <Typography
                                  variant="h2"
                                  textAlign={"center"}
                                  color={"var(--grey-color)"}
                                  fontWeight={700}
                                >
                                  Accessories
                                </Typography>
                              </Grid>
                            )} */}
                            {formFields && formFields.length > 0 && (
                              <>
                                {formFields.map((field, index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={
                                      formFields.length % 2 !== 0 &&
                                      index === formFields.length - 1
                                        ? 12
                                        : 6
                                    }
                                    key={field._id}
                                    // style={{
                                    //   marginBottom:
                                    //     formFields.length % 2 !== 0 &&
                                    //     index === formFields.length - 1 &&
                                    //     "0",
                                    // }}
                                  >
                                    {field.type === "select" ? (
                                      <FormikSelect
                                        key={field._id}
                                        name={field.formikName}
                                        label={field.name}
                                        options={field.options}
                                      />
                                    ) : field.type ===
                                      "autocompleteWithCreateOption" ? (
                                      <FormikAutoCompleteWithCreateOption
                                        setFieldValue={setFieldValue}
                                        key={field._id}
                                        name={field.formikName}
                                        label={field.name}
                                      />
                                    ) : (
                                      <FormikTextField
                                        key={field._id}
                                        name={field.formikName}
                                        label={field.name}
                                        type="text"
                                      />
                                    )}
                                  </Grid>
                                ))}
                                {/* <Grid container columnSpacing={4} rowSpacing={2}> */}
                                {formRef.current?.values.attachments.map(
                                  (attachment, attachmentIndex) => (
                                    <>
                                      <Grid item marginTop={"2rem"}>
                                        <Stack
                                          direction={"row"}
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <Typography
                                            borderBottom={
                                              "2px solid var(--grey-color)"
                                            }
                                            textAlign="left"
                                            sx={{ fontWeight: 700 }}
                                          >
                                            Attachment {attachmentIndex + 1}
                                          </Typography>
                                          <Button
                                            size="small"
                                            onClick={() =>
                                              deleteAttachment(attachmentIndex)
                                            }
                                            color="secondary"
                                          >
                                            <Close color="secondary" />
                                          </Button>
                                          {/* <Close
                                            color="var(--grey-color)"
                                            onClick={deleteAttachment(
                                              attachmentIndex
                                            )}
                                          /> */}
                                        </Stack>
                                      </Grid>
                                      {Object.keys(attachment).map(
                                        (fieldKey, index) => {
                                          // Find the corresponding field in baseStructure to get details like label, type, colSize, etc.
                                          const fieldDetails =
                                            baseStructure.find(
                                              (field) =>
                                                field.formikName === fieldKey
                                            );

                                          return (
                                            <>
                                              <Grid
                                                item
                                                xs={12}
                                                sm={fieldDetails?.colSize || 12}
                                                key={`${attachmentIndex}-${index}`}
                                              >
                                                {fieldDetails?.type ===
                                                  "select" && (
                                                  <FormikSelect
                                                    name={`attachments[${attachmentIndex}].${fieldKey}`}
                                                    type={
                                                      fieldDetails?.type ||
                                                      "text"
                                                    }
                                                    label={
                                                      fieldDetails?.name ||
                                                      fieldKey
                                                    }
                                                    value={
                                                      fieldDetails?.name ===
                                                      "Category"
                                                        ? selectedGoal?.label
                                                        : fieldDetails?.name ===
                                                          "Sub-category"
                                                        ? selectedGoal?.label ===
                                                          "Earth Manipulation"
                                                          ? "Attachments"
                                                          : "Lift Attachments"
                                                        : formRef.current.values
                                                            .attachments[
                                                            attachmentIndex
                                                          ]?.fieldKey
                                                    }
                                                    disabled={[
                                                      "Category",
                                                      "Sub-category",
                                                    ].includes(
                                                      fieldDetails?.name
                                                    )}
                                                    colSize={
                                                      fieldDetails?.colSize
                                                    }
                                                    rows={
                                                      fieldDetails?.rows || 1
                                                    }
                                                    options={
                                                      fieldDetails?.options?.map(
                                                        (opt) => opt.label
                                                      ) || []
                                                    }
                                                  />
                                                )}

                                                {fieldDetails?.type ===
                                                  "text" && (
                                                  <FormikTextField
                                                    name={`attachments[${attachmentIndex}].${fieldKey}`}
                                                    type={
                                                      fieldDetails?.type ||
                                                      "text"
                                                    }
                                                    label={
                                                      fieldDetails?.name ||
                                                      fieldKey
                                                    }
                                                    colSize={
                                                      fieldDetails?.colSize
                                                    }
                                                    rows={
                                                      fieldDetails?.rows || 1
                                                    }
                                                    fullWidth
                                                  />
                                                )}

                                                {/* <Field
                                                name={`attachments[${attachmentIndex}].${fieldKey}`}
                                                type={
                                                  fieldDetails?.type || "text"
                                                }
                                                placeholder={
                                                  fieldDetails?.name || fieldKey
                                                }
                                                as={
                                                  fieldDetails?.type ===
                                                  "select"
                                                    ? "select"
                                                    : "textarea"
                                                }
                                                rows={fieldDetails?.rows || 1}
                                                fullWidth
                                              >
                                                {fieldDetails?.type ===
                                                  "select" &&
                                                  fieldDetails.options.map(
                                                    (option) => (
                                                      <option
                                                        key={option.value}
                                                        value={option.value}
                                                      >
                                                        {option.label}
                                                      </option>
                                                    )
                                                  )}
                                              </Field>
                                              <ErrorMessage
                                                name={`attachments[${attachmentIndex}].${fieldKey}`}
                                              /> */}
                                              </Grid>
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  )
                                )}
                                {/* </Grid> */}
                                {(selectedGoal?.label ===
                                  "Earth Manipulation" ||
                                  selectedGoal?.label ===
                                    "Lifting People & Material") && (
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={addDynamicAttachment}
                                    >
                                      Add Attachment
                                    </Button>
                                  </Grid>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                    </>
                  )}
                  {activeStep + 1 === 2 && (
                    <>
                      <Grid
                        container
                        // gap={0.5}
                        // spacing={3}
                        sx={{
                          flexGrow: 1,
                          padding: 0,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid
                          xs={12}
                          sm={4}
                          md={8}
                          sx={{
                            paddingRight: "1rem",
                            borderRight: "1px solid var(--grey-color)",
                          }}
                        >
                          <Grid
                            container
                            columnSpacing={4}
                            rowSpacing={2}
                            marginTop={"0"}
                            marginBottom={"1rem"}
                            // paddingTop={"10px"}
                          >
                            <Grid item xs={12}>
                              {/* <Accordion
                                expanded={values.itemToSell === true}
                                sx={{
                                  boxShadow: "none",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                <AccordionDetails> */}
                              {!isThirdPartyEntity() && (
                                <>
                                  {((errors.itemToSell && touched.itemToSell) ||
                                    (errors.itemForRent &&
                                      touched.itemForRent)) && (
                                    <FormHelperText style={{ color: "red" }}>
                                      {errors.itemToSell}
                                    </FormHelperText>
                                  )}
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e, val) => {
                                          setFieldValue("itemToSell", val);
                                          setFieldValue("itemAmount", "");
                                        }}
                                        onBlur={handleBlur}
                                        checked={values.itemToSell}
                                        name="itemToSell"
                                      />
                                    }
                                    label="Equipment to Sell?"
                                  />
                                </>
                              )}
                              {/* </AccordionDetails>
                                <AccordionSummary
                                  // expandIcon={
                                  //   <ExpandCircleDown
                                  //     sx={{ color: "var(--grey-color)" }}
                                  //   />
                                  // }
                                  sx={{
                                    "&:hover": {
                                      background: "none",
                                    },
                                  }}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                > */}
                              <>
                                {values.itemToSell && (
                                  <TextField
                                    required={values.itemToSell ? true : false}
                                    name="itemAmount"
                                    error={
                                      touched.itemAmount &&
                                      Boolean(errors.itemAmount)
                                    }
                                    helperText={
                                      touched.itemAmount && errors.itemAmount
                                    }
                                    id="amount"
                                    label="Amount"
                                    defaultValue=""
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.itemAmount}
                                    sx={{
                                      width: "100%",
                                      marginTop: ".5rem",
                                      marginBottom: "0",
                                    }}
                                  />
                                )}
                              </>
                              {/* </AccordionSummary>
                              </Accordion> */}
                            </Grid>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e, val) => {
                                      // handleChange(e);
                                      setFieldValue("itemForRent", val);
                                      setFieldValue("itemDailyPrice", "");
                                      setFieldValue("itemWeeklyPrice", "");
                                      setFieldValue("itemMonthlyPrice", "");
                                      setFieldValue("itemCleaningPrice", "");
                                      setFieldValue("itemFuelPrice", "");
                                      setFieldValue("itemMaintenancePrice", "");
                                    }}
                                    onBlur={handleBlur}
                                    checked={values.itemForRent}
                                    name="itemForRent"
                                    disabled={isThirdPartyEntity() || false}
                                  />
                                }
                                label="Equipment for Rent?"
                              />
                            </Grid>
                          </Grid>
                          {values.itemForRent && (
                            <>
                              <Grid
                                container
                                columnSpacing={isMobile ? 1 : 4}
                                // marginTop={"1rem"}
                                // marginBottom={"1rem"}
                              >
                                <Grid item xs={4}>
                                  <TextField
                                    name="itemDailyPrice"
                                    required={values.itemForRent ? true : false}
                                    error={
                                      (touched.itemDailyPrice &&
                                        Boolean(errors.itemDailyPrice)) ||
                                      Boolean(errors.itemRent)
                                    }
                                    helperText={
                                      (touched.itemDailyPrice &&
                                        errors.itemDailyPrice) ||
                                      errors.itemRent
                                    }
                                    id="dailyPrice"
                                    label="Daily Price"
                                    defaultValue=""
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.itemDailyPrice}
                                    sx={{ width: "100%" }}
                                  />
                                </Grid>
                                {/* {!isThirdPartyEntity() && ( */}
                                <Grid item xs={4}>
                                  <TextField
                                    name="itemWeeklyPrice"
                                    error={
                                      (touched.itemWeeklyPrice &&
                                        Boolean(errors.itemWeeklyPrice)) ||
                                      Boolean(errors.itemRent)
                                    }
                                    helperText={
                                      (touched.itemWeeklyPrice &&
                                        errors.itemWeeklyPrice) ||
                                      Boolean(errors.itemRent)
                                    }
                                    id="itemWeeklyPrice"
                                    placeholder={
                                      isMobile ? "3x" : "Usually 3x daily price"
                                    }
                                    label="Weekly Price"
                                    defaultValue=""
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.itemWeeklyPrice}
                                    sx={{ width: "100%" }}
                                  />
                                </Grid>
                                {/* )} */}
                                <Grid item xs={4}>
                                  <TextField
                                    name="itemMonthlyPrice"
                                    error={
                                      (touched.itemMonthlyPrice &&
                                        Boolean(errors.itemMonthlyPrice)) ||
                                      Boolean(errors.itemRent)
                                    }
                                    placeholder={
                                      isMobile
                                        ? "2.5x"
                                        : "Usually 2.5x weekly price"
                                    }
                                    helperText={
                                      (touched.itemMonthlyPrice &&
                                        errors.itemMonthlyPrice) ||
                                      Boolean(errors.itemRent)
                                    }
                                    id="itemMonthlyPrice"
                                    label="Monthly Price"
                                    defaultValue=""
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.itemMonthlyPrice}
                                    sx={{ width: "100%" }}
                                  />
                                </Grid>
                              </Grid>
                              {!isThirdPartyEntity() && (
                                <>
                                  <Grid
                                    container
                                    rowSpacing={isMobile ? 1 : 4}
                                    columnSpacing={isMobile ? 1 : 4}
                                    // marginTop={"0.5rem"}
                                    // marginBottom={"1rem"}
                                  >
                                    <Grid item xs={4} margin={"1.5rem 0"}>
                                      <TextField
                                        name="itemCleaningPrice"
                                        error={
                                          touched.itemCleaningPrice &&
                                          Boolean(errors.itemCleaningPrice)
                                        }
                                        helperText={
                                          touched.itemCleaningPrice &&
                                          errors.itemCleaningPrice
                                        }
                                        id="itemCleaningPrice"
                                        label="Cleaning Fee"
                                        defaultValue=""
                                        InputProps={{
                                          endAdornment: !isMobile && (
                                            <InputAdornment position="start">
                                              <DarkToolTip
                                                arrow
                                                className="gd-tooltip"
                                                title="This is the cost to wash down the equipment to be rent ready condition"
                                                placement="bottom"
                                              >
                                                <InfoOutlined
                                                  fontSize="12px"
                                                  sx={{ cursor: "pointer" }}
                                                />
                                              </DarkToolTip>
                                            </InputAdornment>
                                          ),
                                        }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.itemCleaningPrice}
                                        sx={{ width: "100%" }}
                                      />
                                    </Grid>
                                    <Grid item xs={4} margin={"1.5rem 0"}>
                                      <TextField
                                        name="itemFuelPrice"
                                        error={
                                          touched.itemFuelPrice &&
                                          Boolean(errors.itemFuelPrice)
                                        }
                                        helperText={
                                          touched.itemFuelPrice &&
                                          errors.itemFuelPrice
                                        }
                                        id="itemFuelPrice"
                                        label="Fuel Fee"
                                        defaultValue=""
                                        InputProps={{
                                          endAdornment: !isMobile && (
                                            <InputAdornment
                                              position="start"
                                              sx={{ cursor: "pointer" }}
                                            >
                                              <DarkToolTip
                                                arrow
                                                className="gd-tooltip"
                                                title="This is for a full tank of fuel, where the renter will be able to bring back the machine at any fill level"
                                                placement="bottom"
                                              >
                                                <InfoOutlined
                                                  fontSize="12px"
                                                  sx={{ cursor: "pointer" }}
                                                />
                                              </DarkToolTip>
                                            </InputAdornment>
                                          ),
                                        }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.itemFuelPrice}
                                        sx={{ width: "100%" }}
                                      />
                                    </Grid>
                                    <Grid item xs={4} margin={"1.5rem 0"}>
                                      <TextField
                                        name="itemMaintenancePrice"
                                        error={
                                          touched.itemMaintenancePrice &&
                                          Boolean(errors.itemMaintenancePrice)
                                        }
                                        helperText={
                                          touched.itemMaintenancePrice &&
                                          errors.itemMaintenancePrice
                                        }
                                        id="itemMaintenancePrice"
                                        label="Maintenance Fee"
                                        defaultValue=""
                                        InputProps={{
                                          endAdornment: !isMobile && (
                                            <InputAdornment position="start">
                                              <DarkToolTip
                                                arrow
                                                className="gd-tooltip"
                                                title="This is the cost at every 250 hour increments for general maintenance (oil and filter changes). Based on that expense and the rental period Dizel will calculate the total cost for the duration of the rental period."
                                                placement="bottom"
                                              >
                                                <InfoOutlined
                                                  fontSize="12px"
                                                  sx={{ cursor: "pointer" }}
                                                />
                                              </DarkToolTip>
                                            </InputAdornment>
                                          ),
                                        }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.itemMaintenancePrice}
                                        sx={{ width: "100%" }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    xs={12}
                                    marginTop={0}
                                    marginBottom={"1rem"}
                                  >
                                    <Mautocomplete
                                      onOpen={handleBlur}
                                      disablePortal
                                      fullWidth
                                      id="environmental-fee-combo-box"
                                      options={environmentalFeeOptions}
                                      onChange={(e, value) => {
                                        setFieldValue(
                                          "itemEnvironmentalFee",
                                          value?.value
                                        );
                                        setSelectedEnviromentalFee(value);
                                      }}
                                      value={selectedEnviromentalFee}
                                      sx={{ width: "100%", marginTop: 0 }}
                                      renderOption={(props, option) => (
                                        <li {...props} key={option.value}>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              width: "100%",
                                            }}
                                          >
                                            <span>{option.label}</span>
                                            {option.value === "2" && (
                                              <span
                                                style={{
                                                  fontSize: "0.85rem",
                                                  color: "var(--grey-color)",
                                                }}
                                              >
                                                (Recommended)
                                              </span>
                                            )}
                                          </div>
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Environmental Fee"
                                          name="itemEnvironmentalFee"
                                          error={Boolean(
                                            touched.itemEnvironmentalFee &&
                                              errors.itemEnvironmentalFee
                                          )}
                                          helperText={
                                            touched.itemEnvironmentalFee &&
                                            errors.itemEnvironmentalFee
                                          }
                                          required
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <>
                                                {/* Custom tooltip adornment */}
                                                <DarkToolTip
                                                  arrow
                                                  className="gd-tooltip"
                                                  title="Environmental fees are additional charges imposed by equipment rental companies to cover costs associated with environmental compliance and waste management. These fees are designed to offset expenses related to the proper handling, disposal, and recycling of materials generated during the use of rented equipment."
                                                  placement="bottom"
                                                >
                                                  <InfoOutlined
                                                    fontSize="small"
                                                    sx={{
                                                      cursor: "pointer",
                                                      marginRight: "8px",
                                                      color: "rgb(0 0 0 / 54%)",
                                                    }}
                                                  />
                                                </DarkToolTip>

                                                {/* Retain existing endAdornment */}
                                                {params.InputProps.endAdornment}
                                              </>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </>
                              )}
                            </>
                          )}

                          {!isThirdPartyEntity() && (
                            <Grid
                              item
                              xs={12}
                              sx={{ marginTop: { xs: "1rem", md: 0 } }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e, val) => {
                                      setFieldValue(
                                        "chargesIncurredBySeller",
                                        val
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    checked={values.chargesIncurredBySeller}
                                    name="chargesIncurredBySeller"
                                  />
                                }
                                label="I as the Asset Owner will incur total platform fees of 18%."
                              />
                            </Grid>
                          )}

                          {!isThirdPartyEntity() && (
                            <Grid
                              container
                              spacing={isMobile ? 1 : 4}
                              sx={{ marginTop: { xs: "1rem", md: "-1rem" } }}
                            >
                              <Grid item xs={6}>
                                <TextField
                                  name="itemTransportationPrice"
                                  error={
                                    touched.itemTransportationPrice &&
                                    Boolean(errors.itemTransportationPrice)
                                  }
                                  helperText={
                                    touched.itemTransportationPrice &&
                                    Boolean(errors.itemTransportationPrice)
                                      ? errors.itemTransportationPrice
                                      : ""
                                  }
                                  id="itemTransportationPrice"
                                  label={
                                    isMobile
                                      ? "Transporation Fee (25)"
                                      : "Transportation Fees (upto 25 miles)"
                                  }
                                  defaultValue=""
                                  InputProps={{
                                    endAdornment: !isMobile && (
                                      <InputAdornment position="start">
                                        <DarkToolTip
                                          arrow
                                          className="gd-tooltip"
                                          title="If the Equipment Owner doesn’t pick up the equipment on the scheduled date, Dizel and the Renter are not responsible for keeping it safe, maintaining it, or protecting it in any way. Any problems, costs, or damages that happen after the pick-up date—like theft, damage, loss, extra use, or anything else—are not their responsibility. This rule follows the laws of the rental agreement’s location."
                                          placement="bottom"
                                        >
                                          <InfoOutlined
                                            fontSize="12px"
                                            sx={{ cursor: "pointer" }}
                                          />
                                        </DarkToolTip>
                                      </InputAdornment>
                                    ),
                                  }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.itemTransportationPrice}
                                  sx={{ width: "100%" }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  name="itemTransportationPrice2"
                                  error={
                                    touched.itemTransportationPrice2 &&
                                    Boolean(errors.itemTransportationPrice2)
                                  }
                                  helperText={
                                    touched.itemTransportationPrice2 &&
                                    Boolean(errors.itemTransportationPrice2)
                                      ? errors.itemTransportationPrice2
                                      : ""
                                  }
                                  id="itemTransportationPrice2"
                                  label={
                                    isMobile
                                      ? "Transporation Fee (>25)"
                                      : "Transportation Fees (after 25 miles)"
                                  }
                                  defaultValue=""
                                  InputProps={{
                                    endAdornment: !isMobile && (
                                      <InputAdornment position="start">
                                        <DarkToolTip
                                          arrow
                                          className="gd-tooltip"
                                          title="If the Equipment Owner doesn’t pick up the equipment on the scheduled date, Dizel and the Renter are not responsible for keeping it safe, maintaining it, or protecting it in any way. Any problems, costs, or damages that happen after the pick-up date—like theft, damage, loss, extra use, or anything else—are not their responsibility. This rule follows the laws of the rental agreement’s location."
                                          placement="bottom"
                                        >
                                          <InfoOutlined
                                            fontSize={"14px"}
                                            sx={{ cursor: "pointer" }}
                                          />
                                        </DarkToolTip>
                                      </InputAdornment>
                                    ),
                                  }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.itemTransportationPrice2}
                                  sx={{ width: "100%" }}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                        {/* <Grid
                          sx={{
                            width: "3px",
                            borderRight: "1px solid var(--grey-color)",
                            height: "100%",
                          }}
                        >
                          &nbsp;
                        </Grid> */}
                        {/* LIVE PREVIEW */}
                        <Grid
                          xs={12}
                          md={4}
                          sx={{
                            paddingLeft: { xs: 0, md: "1rem" },
                            marginTop: { xs: "2rem", md: 0 },
                          }}
                        >
                          <Paper
                            data-aos="fade-in"
                            elevation={0}
                            style={{
                              padding: "1rem",
                              paddingBottom: "1rem",
                              background: "var(--black-color)",
                              color: "var(--white-color)",
                              // minHeight: "100vh",
                              // border: "1px solid #ddd",
                              borderRadius: "10px",
                            }}
                          >
                            <Typography
                              variant="h4"
                              fontWeight={700}
                              margin={"1rem"}
                              textAlign={
                                values.itemForRent || values.itemToSell
                                  ? "center"
                                  : "center"
                              }
                              color="var(--grey-color)"
                            >
                              Preview{" "}
                              {values.itemToSell && !values.itemForRent
                                ? "to Buyers"
                                : "to Renters"}
                            </Typography>
                            {(values.itemForRent || values.itemToSell) && (
                              <Typography
                                data-aos="fade-in"
                                data-aos-duration="500"
                                sx={{
                                  fontWeight: 700,
                                  fontSize: 14,
                                  letterSpacing: 1.2,
                                  color: "var(--white-color)",
                                  margin: "0 1rem",
                                  borderBottomColor: "var(--grey-color)",
                                  textAlign: "center",
                                }}
                              >
                                Equipment Type :{" "}
                                {values.itemForRent && values.itemToSell
                                  ? "Rent & Buy"
                                  : values.itemToSell
                                  ? "Buy"
                                  : values.itemForRent
                                  ? "Rent"
                                  : ""}
                              </Typography>
                            )}

                            {values.itemToSell &&
                              !values.itemForRent &&
                              (values.itemAmount ||
                                values.itemTransportationPrice) && (
                                <OnlySell
                                  data-aos="fade-in"
                                  data-aos-duration="500"
                                  values={values}
                                  calculateTotalForPreviewForBoth={
                                    calculateTotalForPreviewForBoth
                                  }
                                />
                              )}

                            {!values.itemToSell &&
                              values.itemForRent &&
                              (values.itemDailyPrice ||
                                values.itemWeeklyPrice ||
                                values.itemMonthlyPrice ||
                                values.itemCleaningPrice ||
                                values.itemFuelPrice ||
                                values.itemMaintenancePrice ||
                                values.itemTransportationPrice) && (
                                <OnlyRent
                                  values={values}
                                  calculateTotalForPreviewForBoth={
                                    calculateTotalForPreviewForBoth
                                  }
                                />
                              )}

                            {values.itemForRent && values.itemToSell && (
                              <>
                                {(values.itemAmount ||
                                  values.itemTransportationPrice) && (
                                  <>
                                    <Typography
                                      data-aos="fade-in"
                                      data-aos-duration="500"
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: "12px",
                                        textTransform: "uppercase",
                                        letterSpacing: 1.5,
                                        color: "var(--grey-color)",
                                        margin: "1rem",
                                        marginBottom: 0,
                                        borderBottomColor: "var(--grey-color)",
                                      }}
                                    >
                                      Buy Estimate
                                    </Typography>
                                    <OnlySell
                                      values={values}
                                      calculateTotalForPreviewForBoth={
                                        calculateTotalForPreviewForBoth
                                      }
                                    />
                                  </>
                                )}
                                {(values.itemDailyPrice ||
                                  values.itemWeeklyPrice ||
                                  values.itemMonthlyPrice ||
                                  values.itemCleaningPrice ||
                                  values.itemFuelPrice ||
                                  values.itemMaintenancePrice ||
                                  values.itemTransportationPrice) && (
                                  <>
                                    <br />
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: "12px",
                                        textTransform: "uppercase",
                                        letterSpacing: 1.5,
                                        color: "var(--grey-color)",
                                        margin: "1rem",
                                        marginBottom: 0,
                                      }}
                                    >
                                      Rent Estimate
                                    </Typography>

                                    <OnlyRent
                                      values={values}
                                      calculateTotalForPreviewForBoth={
                                        calculateTotalForPreviewForBoth
                                      }
                                    />
                                  </>
                                )}
                              </>
                            )}

                            {/* {values.itemToSell && values.itemForRent} */}
                          </Paper>

                          {/* <Paper
                elevation={3}
                style={{
                  marginTop: "2rem",
                  padding: "1rem 2rem",
                  paddingBottom: "6.5rem",
                  borderRadius: "10px",
                }}
              >
                <h2>Payment Information</h2>
                <form>
                  <TextField label="Full Name" fullWidth margin="normal" />
                  <TextField label="Email" fullWidth margin="normal" />
                  <TextField
                    label="Address"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                  />
                  <TextField
                    label="Credit Card Number"
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Expiration Date"
                    fullWidth
                    margin="normal"
                  />
                  <TextField label="CVV" fullWidth margin="normal" />
                  <Button variant="contained" color="primary" fullWidth>
                    Submit
                  </Button>
                </form>
              </Paper> */}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {activeStep + 1 === 3 && (
                    <>
                      <Typography
                        variant="h3"
                        fontWeight={700}
                        className="mont-title"
                        marginBottom={2}
                      >
                        Location
                      </Typography>
                      <Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                // handleChange(e);
                                console.log("YELLOYELLO", e.target.value);
                                if (autopopulate) {
                                  setAutopopulate(false);
                                  depopulateLocation();
                                } else {
                                  setAutopopulate(true);
                                  populateLocation();
                                }

                                // setFieldValue("itemAvailability", "");
                                // else
                                // setFieldValue("itemAvailability", "always");
                              }}
                              // onBlur={handleBlur}
                              checked={autopopulate}
                              name="autopopulate"
                            />
                          }
                          label="Auto populate address from Account"
                        />
                      </Box>
                      <Grid container spacing={isMobile ? 1 : 4}>
                        <Grid item xs={12}>
                          {!isThirdPartyEntity() ? (
                            <GooglePlacesAutocomplete
                              selectProps={{
                                ref: selectRef,
                                value: itemLocTemp,
                                onChange: (val) => {
                                  setAutopopulate(false);
                                  console.log(val);
                                  if (val) {
                                    geocodeByAddress(val.label)
                                      .then((results) => {
                                        console.log(results);
                                        let zipCode =
                                          results[0].address_components.find(
                                            (zp) =>
                                              zp.types.includes("postal_code")
                                          );
                                        if (zipCode) {
                                          console.log(
                                            "zipcode",
                                            zipCode.long_name
                                          );
                                          setFieldValue(
                                            "itemZipCode",
                                            zipCode.long_name
                                          );
                                        } else {
                                          setFieldValue("itemZipCode", "");
                                        }
                                        //previous extra
                                        return getLatLng(results[0]);
                                        //
                                      })
                                      .then(({ lat, lng }) => {
                                        console.log(
                                          "Successfully got latitude and longitude",
                                          { lat, lng }
                                        );
                                        setLangLong({ lng, lat });
                                      });

                                    if (val.value.terms.length < 4) {
                                      toast.info(
                                        "Please Enter Complete Address",
                                        toastOption
                                      );
                                      return;
                                    }

                                    setItemLocTemp(val);
                                    setInputValue(
                                      val ? val.value.description : ""
                                    );

                                    // setFieldValue("itemLocation", val.label);

                                    const terms = val.value.terms;

                                    // Extract city and state based on typical indexing in terms array
                                    const streetExtracted =
                                      terms.length === 4
                                        ? terms
                                            .slice(0, 1)
                                            .map((term) => term.value)
                                        : terms
                                            .slice(0, 2)
                                            .map((term) => term.value);
                                    const cityExtracted =
                                      terms.length === 4
                                        ? terms[1].value
                                        : terms[2].value; // Third item
                                    const stateExtracted =
                                      terms.length === 4
                                        ? terms[2].value
                                        : terms[3].value; // Fourth item

                                    setFieldValue(
                                      "itemLocation",
                                      streetExtracted.join(", ")
                                    );
                                    setFieldValue("itemCity", cityExtracted);
                                    console.log("itemCity", cityExtracted);

                                    setFieldValue("itemState", stateExtracted);
                                    console.log("itemState", stateExtracted);
                                  }
                                },
                                styles: {
                                  input: (provided) => ({
                                    ...provided,
                                    padding: 10,
                                    border: "none",
                                    fontSize: isMobile && 12,
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    zIndex: 999,
                                    fontSize: isMobile && 12,
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: isMobile && 12,
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                  }),
                                  clearIndicator: (base) => ({
                                    ...base,
                                    display: "none", // Hides the clear indicator
                                  }),
                                },
                                inputValue: inputValue,
                                onFocus: onFocus,
                                isClearable: "true",
                                onInputChange: onInputChange,
                                controlShouldRenderValue: false,
                                components: { SingleValue, Input },
                                placeholder: "Enter Location",
                              }}
                              apiKey="AIzaSyAw_j2KY4CDtksQcd6JexS3J1xt4BdbnGQ"
                              apiOptions={{ region: "US" }}
                            />
                          ) : (
                            <GooglePlacesAutocomplete
                              ref={selectRef}
                              selectProps={{
                                styles: {
                                  input: (provided) => ({
                                    ...provided,
                                    padding: 10,
                                    border: "none",
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    zIndex: 999,
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                  }),
                                  clearIndicator: (base) => ({
                                    ...base,
                                    display: "none", // Hides the clear indicator
                                  }),
                                },
                                // onFocus: onFocus,
                                isClearable: true,
                                onInputChange: onInputChange,
                                controlShouldRenderValue: false,
                                components: { SingleValue, Input },
                                placeholder: "Search for a city and state",
                                inputValue: inputValue,
                                onChange: (value) => {
                                  if (value) {
                                    if (value.value.terms.length < 4) {
                                      toast.info(
                                        "Please Enter Complete Address",
                                        toastOption
                                      );
                                      return;
                                    }

                                    setAutopopulate(false);
                                    const terms = value.value.terms;

                                    // Extract city and state based on typical indexing in terms array
                                    const streetExtracted =
                                      terms.length === 4
                                        ? terms
                                            .slice(0, 1)
                                            .map((term) => term.value)
                                        : terms
                                            .slice(0, 2)
                                            .map((term) => term.value);
                                    const cityExtracted =
                                      terms.length === 4
                                        ? terms[1].value
                                        : terms[2].value; // Third item
                                    const stateExtracted =
                                      terms.length === 4
                                        ? terms[2].value
                                        : terms[3].value; // Fourth item

                                    setFieldValue(
                                      "itemLocation",
                                      streetExtracted.join(", ")
                                    );
                                    setFieldValue("itemCity", cityExtracted);
                                    console.log("itemCity", cityExtracted);

                                    setFieldValue("itemState", stateExtracted);
                                    console.log("itemState", stateExtracted);
                                  }

                                  // You can combine them or use them as needed
                                },
                              }}
                              apiKey="AIzaSyAw_j2KY4CDtksQcd6JexS3J1xt4BdbnGQ"
                              autocompletionRequest={{
                                types: ["(regions)"], // Includes both cities and states
                                componentRestrictions: { country: "us" }, // Restrict to the US
                              }}
                              fields={["address_components", "geometry"]}
                            />
                          )}
                          {errors.itemLocation && touched.itemLocation && (
                            <FormHelperText style={{ color: "red" }}>
                              {errors.itemLocation.label}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                      {!isThirdPartyEntity() && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "2rem",
                            }}
                          >
                            <Typography
                              variant="h3"
                              fontWeight={700}
                              className="mont-title"
                              marginBottom={1}
                            >
                              Rent Period
                            </Typography>
                          </Box>

                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    handleChange(e);
                                    if (e.target.value === true)
                                      setFieldValue("itemAvailability", "");
                                    else
                                      setFieldValue(
                                        "itemAvailability",
                                        "always"
                                      );
                                  }}
                                  onBlur={handleBlur}
                                  checked={values.itemToHold}
                                  name="itemToHold"
                                />
                              }
                              label="Hold (choose availability later)"
                            />
                          </Box>
                          {!values.itemToHold && (
                            <FormControl component={"fieldset"}>
                              {/* <FormLabel
                      component="legend"
                      id="demo-row-radio-buttons-group-label"
                    >
                      Availability
                    </FormLabel> */}
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="itemAvailability"
                                value={values.itemAvailability}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (
                                    itemLeaseFields &&
                                    itemLeaseFields.length > 0 &&
                                    e.target.value === "always"
                                  ) {
                                    setItemLeaseFields([
                                      {
                                        initVal: { from: "", to: "" },
                                      },
                                    ]);
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value={"always"}
                                  control={<Radio />}
                                  label="Always Available"
                                />
                                <FormControlLabel
                                  value={"except"}
                                  control={<Radio />}
                                  label="Always Available, except:"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                          {values.itemAvailability === "except" && (
                            <>
                              {/* {Array.from({ length: leaseCounts }, (_, index) => ( */}
                              {itemLeaseFields.map((field, index) => (
                                <Box
                                  key={index}
                                  margin={"1rem auto"}
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <DateRangePicker
                                    style={{
                                      width: index > 0 ? "96%" : "100%",
                                    }}
                                    size="large"
                                    placeholder="Select Date Range : From ~ To"
                                    disabledDate={beforeToday()}
                                    // onOk={(newValue) => {
                                    //   setValue(newValue);
                                    //   setFieldValue("itemLease", [
                                    //     ...values.itemLease,
                                    //     {
                                    //       from: newValue[0],
                                    //       to: newValue[1],
                                    //     },
                                    //   ]);
                                    // }}
                                    onOk={(newValue) =>
                                      handleDateRangeSelectt(index, newValue)
                                    }
                                    value={field.initVal}
                                    // value={value}
                                  />
                                  {index > 0 && (
                                    <span
                                      style={{
                                        marginTop: "5px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Clear
                                        // onClick={() => {
                                        //   const updatedLease = [...values.itemLease];
                                        //   updatedLease.splice(index, 1);
                                        //   setFieldValue("itemLease", updatedLease);
                                        //   console.log("valuessss", values.itemLease);
                                        // }}
                                        onClick={() => handleRemoveField(index)}
                                      />
                                    </span>
                                  )}
                                </Box>
                              ))}
                              <Box sx={{ position: "relative" }}>
                                <Button
                                  variant="outlined"
                                  // sx={{ position: "absolute", right: 0, bottom: 0 }}
                                  // onClick={() => {setLeaseCounts((prev) => prev + 1); setValue([...value, { initVal: [null, null] }]);}}
                                  onClick={handleAddField}
                                >
                                  Add More
                                </Button>
                              </Box>
                            </>
                          )}

                          {/* {
                    values.itemForRent && (
                      <>
                        <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "2rem",
                        }}
                      >
                        <Typography
                          variant="h3"
                          fontWeight={700}
                          className="mont-title"
                          marginBottom={2}
                        >
                          Cancellation Policy
                        </Typography>
                      </Box>
                      
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          // variant="h3"
                          // fontWeight={700}
                          // className="mont-title"
                          marginBottom={1}
                        >
                          Default 72 Hours From Booking Date
                        </Typography>
                      </Box>
                      <Typography
                        variant="h3"
                        fontWeight={"700"}
                        // textAlign={"center"}
                        color={"var(--dark-grey-color)"}
                        my={3}
                      >
                        OR
                      </Typography>
                      <Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                handleChange(e);
                                if (e.target.value === true)
                                  setFieldValue("cancellationDate", "3");
                                else setFieldValue("cancellationDate", "3");
                              }}
                              onBlur={handleBlur}
                              checked={values.cancellation}
                              name="cancellation"
                            />
                          }
                          label="Custom Cancellation Policy"
                        />
                      </Box>
                      </>
                    )
                  } */}

                          {values.itemForRent && (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginTop: "2rem",
                                }}
                              >
                                <Typography
                                  variant="h3"
                                  fontWeight={700}
                                  className="mont-title"
                                  marginBottom={2}
                                >
                                  Cancellation Policy
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <FormControl component={"fieldset"}>
                                  {/* <FormLabel
                          component="legend"
                          id="demo-row-radio-buttons-group-label"
                        >
                          Availability
                        </FormLabel> */}
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="cancellation"
                                    value={values.cancellation}
                                    onChange={(e) => {
                                      const newValue =
                                        e.target.value === "true";
                                      handleChange({
                                        target: {
                                          name: "cancellation",
                                          value: newValue,
                                        },
                                      });
                                    }}
                                  >
                                    <FormControlLabel
                                      value="false"
                                      control={<Radio />}
                                      label="Default (72 hours from booking date)"
                                    />
                                    <FormControlLabel
                                      value="true"
                                      control={<Radio />}
                                      label="Specific (choose cancellation period)"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Box>
                            </>
                          )}

                          {values.cancellation && (
                            <Box
                              margin={"1rem auto"}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <TextField
                                name="cancellationDate"
                                error={
                                  touched.cancellationDate &&
                                  Boolean(errors.cancellationDate)
                                }
                                helperText={
                                  touched.cancellationDate &&
                                  errors.cancellationDate
                                }
                                required
                                id="cancellationDate"
                                label="Choose cancellation period (days before rental start)"
                                defaultValue=""
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FaStopwatch />
                                    </InputAdornment>
                                  ),
                                }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.cancellationDate}
                                sx={{ width: "100%" }}
                              />
                            </Box>
                          )}
                        </>
                      )}
                      <Box
                        margin={"2rem auto"}
                        display={"flex"}
                        justifyContent={"center"}
                        color={"secondary"}
                      >
                        {/* <Button variant="outlined" >Save as Draft</Button> */}
                        {itemStatus === "loading" ? (
                          <LoadingButton
                            loading
                            loadingPosition="end"
                            endIcon={<Save />}
                            variant="outlined"
                            disabled={true}
                          >
                            <span>Please Wait</span>
                          </LoadingButton>
                        ) : (
                          <Button
                            variant="contained"
                            color="secondary"
                            sx={{ marginLeft: "1rem" }}
                            type="submit"
                            disabled={itemStatus === "loading" ? true : false}
                          >
                            ADD TO INVENTORY
                          </Button>
                        )}
                      </Box>
                    </>
                  )}

                  {activeStep + 1 !== 3 && (
                    <Stack direction={"row"} justifyContent={"center"}>
                      <Button
                        // type="submit"
                        variant="contained"
                        onClick={() => handleNext(values)}
                        disabled={
                          !steps.includes("Information")
                            ? activeStep === 2
                            : activeStep === 3
                        }
                        sx={{
                          mt: 4,
                          padding: ".5rem 4rem",
                          textAlign: "center",
                          background: "var(--secondary-color)",
                          "&: hover": {
                            background: "var(--grey-color)",
                            color: "var(--black-color)",
                          },
                        }}
                      >
                        Next
                      </Button>
                    </Stack>
                  )}
                </form>
              </Container>
            </>
          );
        }}
      />
    </>
  );
};

const OnlySell = ({
  values,
  calculateTotalForPreview,
  calculateTotalForPreviewForBoth,
}) => {
  return (
    <>
      <TableContainer
        data-aos="fade-up"
        data-aos-duration="500"
        sx={{ margin: "1rem 0" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 700,
                  fontSize: "12px",
                  textTransform: "uppercase",
                  letterSpacing: 1.5,
                  color: "var(--grey-color)",

                  borderBottomColor: "var(--grey-color)",
                }}
              >
                Description
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: 700,
                  fontSize: "12px",
                  textTransform: "uppercase",
                  letterSpacing: 1.5,
                  color: "var(--grey-color)",

                  borderBottomColor: "var(--grey-color)",
                }}
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow></TableRow>
            {values.itemAmount && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Amount
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemAmount).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            )}
            {values.itemTransportationPrice && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Transportation Fees{" "}
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemTransportationPrice).toLocaleString(
                    "en-US",
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )}
                </TableCell>
              </TableRow>
            )}

            {/* <TableRow>
              <TableCell
                sx={{
                  color: "var(--white-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                }}
              >
                Platform Fees
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={"var(--grey-color)"}
                  marginLeft={".3rem"}
                >
                  (4%)
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  color: "var(--white-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                }}
                align="right"
              >
                {`$${parseFloat(
                  values.itemAmount
                    ? Number(((values.itemAmount * 4) / 100).toFixed(2))
                    : 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
              </TableCell>
            </TableRow> */}

            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "700",
                  textTransform: "uppercase",
                  letterSpacing: 1.5,
                  color: "var(--white-color)",
                  border: "none",
                  borderTop: "1px solid var(--grey-color)",
                  borderBottom: "none",
                }}
                align="left"
              >
                Total{" "}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "700",
                  border: "none",
                  borderTop: "1px solid var(--grey-color)",
                  borderBottom: "none",
                  color: "var(--white-color)",
                  position: "relative",
                }}
                align="right"
              >
                $
                {parseFloat(
                  calculateTotalForPreviewForBoth("buy")
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  color: "#ff4b4b",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
              >
                Dizel
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={"#ff4b4b"}
                  marginLeft={".3rem"}
                >
                  (4%)
                </Typography>
                &nbsp;
                <LightToolTip
                  arrow
                  className="gd-tooltip"
                  placement="bottom"
                  title={"Any promos will be discounted at checkout"}
                >
                  <InfoOutlined
                    sx={{
                      fontSize: 14,
                      position: "relative",
                      top: "3px",
                      color: "var(--grey-color)",
                      cursor: "pointer",
                    }}
                  />
                </LightToolTip>
              </TableCell>
              <TableCell
                sx={{
                  color: "#ff4b4b",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
                align="right"
              >
                $
                {`${parseFloat(
                  values.itemAmount
                    ? Math.round(
                        Number(
                          (
                            ((parseInt(values.itemAmount) +
                              parseInt(values.itemTransportationPrice)) *
                              4) /
                            100
                          ).toFixed(2)
                        )
                      )
                    : 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} `}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  color: "var(--secondary-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
              >
                Earned By You
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={"var(--secondary-color)"}
                  marginLeft={".3rem"}
                >
                  (96%)
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  color: "var(--secondary-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
                align="right"
              >
                $
                {`${parseFloat(
                  parseFloat(calculateTotalForPreviewForBoth("buy")) -
                    parseFloat(
                      values.itemAmount
                        ? Number(((values.itemAmount * 4) / 100).toFixed(2))
                        : 0
                    )
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const OnlyRent = ({
  values,
  calculateTotalForPreview,
  calculateTotalForPreviewForBoth,
}) => {
  return (
    <>
      <TableContainer
        data-aos="fade-up"
        data-aos-duration="500"
        sx={{ margin: "1rem 0" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 700,
                  fontSize: "12px",
                  textTransform: "uppercase",
                  letterSpacing: 1.5,
                  color: "var(--grey-color)",

                  borderBottomColor: "var(--grey-color)",
                }}
              >
                Description
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: 700,
                  fontSize: "12px",
                  textTransform: "uppercase",
                  letterSpacing: 1.5,
                  color: "var(--grey-color)",

                  borderBottomColor: "var(--grey-color)",
                }}
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow></TableRow>
            {values.itemWeeklyPrice ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Weekly Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemWeeklyPrice).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            ) : values.itemDailyPrice ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Weekly Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemDailyPrice * 7).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            ) : values.itemMonthlyPrice ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Weekly Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemMonthlyPrice / 4).toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  )}
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
            {/* {values.itemDailyPrice &&
              !values.itemWeeklyPrice &&
              !values.itemMonthlyPrice && } */}

            {/* {values.itemMonthlyPrice &&
              !values.itemDailyPrice &&
              !values.itemWeeklyPrice && } */}
            {values.itemCleaningPrice && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Cleaning Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemCleaningPrice).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            )}

            {values.itemFuelPrice && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Fuel Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemFuelPrice).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            )}

            {values.itemMaintenancePrice && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Maintenance Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemMaintenancePrice).toLocaleString(
                    "en-US",
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )}
                </TableCell>
              </TableRow>
            )}
            {values.itemEnvironmentalFee && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Environmental Fee
                  <Typography
                    variant="caption"
                    fontWeight={700}
                    color={"var(--grey-color)"}
                    marginLeft={".3rem"}
                  >
                    ({values.itemEnvironmentalFee}%)
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {((calculateTotalForPreviewForBoth("rent") * parseInt(values.itemEnvironmentalFee)) / 100).toFixed(2)}{" "}
                </TableCell>
              </TableRow>
            )}
            {values.itemTransportationPrice && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                >
                  Transportation Fees
                  <Typography
                    variant="caption"
                    fontWeight={700}
                    color={"var(--grey-color)"}
                    marginLeft={".3rem"}
                  >
                    (2x){" "}
                    <LightToolTip
                      arrow
                      className="gd-tooltip"
                      placement="bottom"
                      title={"This is the cost for both delivery and pickup"}
                    >
                      <InfoOutlined
                        sx={{
                          fontSize: 14,
                          position: "relative",
                          top: "4px",
                          cursor: "pointer",
                        }}
                      />
                    </LightToolTip>
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--white-color)",
                    border: "none",
                    borderBottomColor: "var(--grey-color)",
                  }}
                  align="right"
                >
                  $
                  {parseInt(values.itemTransportationPrice * 2).toLocaleString(
                    "en-US",
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )}{" "}
                </TableCell>
              </TableRow>
            )}

            {/* <TableRow>
              <TableCell
                sx={{
                  color: "var(--white-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                }}
              >
                Platform Fees
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={"var(--grey-color)"}
                  marginLeft={".3rem"}
                >
                  {values.chargesIncurredBySeller ? "(18%)" : "(6.5%)"}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  color: "var(--white-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                }}
                align="right"
              >
                $
                {`${parseFloat(
                  Number(
                    (
                      ((
                        calculateTotalForPreviewForBoth("rent") -
                        parseInt(values.itemTransportationPrice * 2)
                      ).toFixed(2) *
                        (values.chargesIncurredBySeller ? 18 : 6.5)) /
                      100
                    ).toFixed(2)
                  )
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} `}
              </TableCell>
            </TableRow> */}

            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "700",
                  textTransform: "uppercase",
                  letterSpacing: 1.5,
                  color: "var(--white-color)",
                  border: "none",
                  borderTop: "1px solid var(--grey-color)",
                  borderBottom: "none",
                }}
                align="left"
              >
                Total{" "}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "700",
                  border: "none",
                  borderTop: "1px solid var(--grey-color)",
                  borderBottom: "none",
                  color: "var(--white-color)",
                  position: "relative",
                }}
                align="right"
              >
                $
                {parseFloat(
                  calculateTotalForPreviewForBoth("rent")
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
                {/* ${calculateTotalForPreview(values)} */}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  color: "#ff4b4b",
                  border: "none",
                  borderBottomColor: "#ff4b4b",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
              >
                Dizel
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={"#ff4b4b"}
                  marginLeft={".3rem"}
                >
                  {values.chargesIncurredBySeller ? "(18%)" : "(6.5%)"}
                </Typography>
                &nbsp;
                <LightToolTip
                  arrow
                  className="gd-tooltip"
                  placement="bottom"
                  title={"Any promos will be discounted at checkout"}
                >
                  <InfoOutlined
                    sx={{
                      fontSize: 14,
                      position: "relative",
                      top: "3px",
                      color: "var(--grey-color)",
                      cursor: "pointer",
                    }}
                  />
                </LightToolTip>
              </TableCell>
              <TableCell
                sx={{
                  color: "#ff4b4b",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
                align="right"
              >
                $
                {`${parseFloat(
                  Number(
                    (
                      (calculateTotalForPreviewForBoth("rent") *
                        //   -
                        //   parseInt(values.itemTransportationPrice * 2)
                        // ).toFixed(2) *
                        (values.chargesIncurredBySeller ? 18 : 6.5)) /
                      100
                    ).toFixed(2)
                  )
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} `}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  color: "var(--secondary-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
              >
                Earned By You
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={"var(--secondary-color)"}
                  marginLeft={".3rem"}
                >
                  {values.chargesIncurredBySeller ? "(82%)" : "(93.5%)"}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  color: "var(--secondary-color)",
                  border: "none",
                  borderBottomColor: "var(--grey-color)",
                  textDecoration: values.chargesIncurredBySeller
                    ? "none"
                    : "none",
                  paddingY: "0px",
                }}
                align="right"
              >
                $
                {`${parseFloat(
                  parseFloat(calculateTotalForPreviewForBoth("rent")) -
                    parseFloat(
                      Number(
                        (
                          ((
                            calculateTotalForPreviewForBoth("rent") -
                            parseInt(values.itemTransportationPrice * 2)
                          ).toFixed(2) *
                            (values.chargesIncurredBySeller ? 18 : 6.5)) /
                          100
                        ).toFixed(2)
                      )
                    )
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} `}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AddNewItemStepper;
