import { Box, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  FaFacebook,
  FaLinkedin,
  FaWhatsapp,
  FaXTwitter,
} from "react-icons/fa6";
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
// import slides from "./slides";

const Gallery = ({ product }) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [slides, setSlides] = useState([]);

  let slideData = [];
  useEffect(() => {
    console.log("k2l ", product.itemImages);
    if (product.itemImages && product.itemImages.length > 0) {
      product.itemImages.map((img) => slideData.push({ src: img }));
    }
    if (slideData.length > 0) {
      setSlides(slideData);
      console.log("k2l slide data ", slideData);
    }
  }, []);
  console.log("jk", slides);
  const toggleOpen = (state) => () => setOpen(state);
  const zoomRef = React.useRef(null);
  const updateIndex = ({ index: current }) => setIndex(current);

  const currentUrl = "https://godizel.com" + window.location.pathname;

  const shareDetails = {
    title: product.itemName, // Product title
    description: product.itemDescription, // Product description
    image: product.itemImages[0], // Product image URL
  };
  console.log("shareDetails", shareDetails);

  return (
    <>
      {/* <section className="prod-gallery-con"> */}

<Box sx={{width: {xs: "100%"}}}>
      <Lightbox
        index={index}
        slides={slides}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        plugins={[Inline]}
        // zoom={{ ref: zoomRef }}
        on={{
          view: updateIndex,
          click: toggleOpen(true),
        }}
        carousel={{
          padding: 0,
          spacing: 0,
          imageFit: "contain",
          width: "100%",
          backgroundColor: "#fff",
          background: "#fff",
          margin: 0,
        }}
        inline={{
          style: {
            width: "100%",
            // maxWidth: "450px",
            aspectRatio: "3 / 2",
            margin: "0 auto",
            background: "#fff",
            backgroundColor: "#fff",
            // border: "1px solid var(--grey-color)",
            // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            // borderRadius: "10px",
            border: "none",
          },
        }}
      />
      <Lightbox
        open={open}
        close={toggleOpen(false)}
        index={index}
        slides={slides}
        on={{ view: updateIndex }}
        animation={{ fade: 0.3 }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
      />

<Stack
        sx={{minWidth: {xs: "100%", md: "400px"}}}
        marginTop={"1rem"}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={2}
      >
        <Box><Typography variant="h5" fontWeight={700}>Share Equipment: </Typography></Box>
        <Box>
          <FaFacebook
            className="social-share-btn"
            style={{ margin: "0 .5rem" }}
            onClick={() => {
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  currentUrl
                )}&t=${shareDetails.description}&hashtag=GoDizel`
              );
            }}
            size={25}
            round
          />

          <FaLinkedin
            className="social-share-btn"
            style={{ margin: "0 .5rem" }}
            onClick={() => {
              const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                currentUrl
              )}&text=${encodeURIComponent(shareDetails.title + "on @Dizel")}`;
              window.open(linkedInUrl, "_blank");
            }}
            size={25}
            round
          />

          <FaWhatsapp
            className="social-share-btn"
            style={{ margin: "0 .5rem" }}
            onClick={() => {
              const waurl = `https://wa.me/?text=${encodeURIComponent(
                `Product Name: ${shareDetails.title}\nProduct Description: ${shareDetails.description}\n\n${currentUrl}`
              )}`;
              window.open(waurl, "_blank");
            }}
            size={25}
            round
          />

          <FaXTwitter
            size={25}
            className="social-share-btn"
            style={{ margin: "0 .5rem" }}
            round
            onClick={() => {
              window.open(
                `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  currentUrl
                )}&text=${encodeURIComponent(
                  shareDetails.title + "on @GoDizel"
                )}`
              );
            }}
          />
        </Box>
      </Stack>

      </Box>

      {/* </section> */}
    </>
  );
};

export default Gallery;
